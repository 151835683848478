import styled from 'styled-components';
import { theme } from 'Styles';

const ActionLinkedin = styled.a`
  color: #fff;
  cursor: pointer;
  // white-space: nowrap;
  align-items: center;
  padding: 0;
  margin: 0;
  span {
    display: block;
  }
`;

export default ActionLinkedin;
