import styled from 'styled-components';
import theme from 'Theme';
const { colors, fontSize } = theme;

const FormGroup = styled.div`
  margin-bottom: 1rem;
  margin-right: 1rem;
  @media (min-width: ${theme.breakpoints.s}) {
  }

  @media (min-width: ${theme.breakpoints.m}) {
    margin-bottom: 0rem;
  }

  @media (min-width: ${theme.breakpoints.l}) {
  }
  button {
    height: 30px;
    padding: 4px 8px;
    border-radius: ${theme.borderRadius.elements};
    & + button {
      margin-left: 4px;
    }
  }
  & + .form-group {
    margin-top: 1rem;
  }
`;

export default FormGroup;
