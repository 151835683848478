import styled from 'styled-components';
import theme from 'Theme';
const { colors, fontSize } = theme;

const SectionHeading = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  @media (min-width: ${theme.breakpoints.s}) {
    flex-direction: row;
  }
  @media (min-width: ${theme.breakpoints.m}) {
    align-items: center;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${theme.layout.base};
  color: ${theme.colors.black.base};
  font-weight: bold;
  padding-bottom: 1rem;
`;

export default SectionHeading;
