import React from 'react';
import { Link } from 'gatsby';
import { Form, Field } from 'react-final-form';
import isEmail from 'validator/lib/isEmail';
import styled, { css } from 'styled-components';
import { theme, mixins, StyledForm, Button } from 'Styles';
import Config from 'Data';
const { colors, fontSize } = theme;
import toaster from 'toasted-notes';
import { HeaderImage } from 'Components';
import {
  Input,
  TextArea,
  StyledSelect,
  Heading,
  Spacer,
} from 'Components/FormElements';

const Content = styled.div`
  color: #fff;
  font-size: ${fontSize.smallish};

  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const windowGlobal = typeof window !== 'undefined' && window;

function postData(url = ``, data = {}) {
  return fetch(url, {
    method: 'POST',
    mode: 'no-cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  })
    .then(response => {
      response
        .json()
        .then(data => {
          console.log('data.retURL: ', data.retURL);
          if (data.retURL) {
            windowGlobal.location = data.retURL;
          } else {
            toaster.notify('Thank You! Please check your Email.', {
              position: 'top',
              duration: 2000,
            });
          }
        })
        .catch(function(e) {
          console.error('handled the error');
        });
    })
    .catch(error => console.error('Error:', error));
}

const onSubmit = async values => {
  console.log(JSON.stringify(values, 0, 2));
  postData(`/.netlify/functions/referee`, values).catch(error =>
    console.error(error)
  );
};

export default props => {
  const {
    page,
    type,
    audience,
    leadTemplate,
    notifyTemplate,
    title,
    subtitle,
    banner,
    isOpen,
    closeModal,
    onAfterOpen,
    onRequestClose,
    onChangeInput,
  } = props;

  const formHiddenData = {
    formName: 'FormSubscribe',
    origin: windowGlobal ? windowGlobal.location.href : '',
    retURL: '',
    country: windowGlobal ? windowGlobal.country : '',
    page: page || null,
    type: 'secureCISO',
    audience: audience || null,
    lead: leadTemplate || null,
    notify: notifyTemplate || null,
    // owner: windowGlobal ? windowGlobal.o || null,
  };

  if (windowGlobal) {
    var pairs = windowGlobal.location.search.slice(1).split('&');
    var result = {};
    pairs.forEach(function(pair) {
      pair = pair.split('=');
      result[pair[0]] = decodeURIComponent(pair[1] || '');
    });
    formHiddenData.rep = result.rep;
    console.log('Params: ', result);
  }

  const handleChange = () => {
    // console.log('formHiddenData.city: ');
    // console.log('formHiddenData.city: ', formHiddenData.city);
    // formHiddenData.city = city;
    // this.setState({ formHiddenData });
  };
  const required = value => (value ? undefined : 'Required');

  return (
    <Content>
      <Form
        initialValues={formHiddenData}
        onSubmit={onSubmit}
        validate={values => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (!isEmail(values.email)) {
            errors.email = 'Invalid email';
          }
          return errors;
        }}
        render={({
          form,
          handleSubmit,
          reset,
          submitting,
          pristine,
          values,
        }) => (
          <>
            <StyledForm onSubmit={handleSubmit} className="form">
              <Field name="formName" type="hidden" component="input" />
              <Field name="origin" type="hidden" component="input" />
              <Field name="retURL" type="hidden" component="input" />
              <Field name="country" type="hidden" component="input" />
              <Field name="page" type="hidden" component="input" />
              <Field name="marketing_code" type="hidden" component="input" />
              <Field name="incentive" type="hidden" component="input" />
              <Field name="lead" type="hidden" component="input" />
              <Field name="notify" type="hidden" component="input" />

              <Field name="formName" type="hidden" component="input" />
              <Field name="retURL" type="hidden" component="input" />

              <Input
                name="email"
                label="Email (Corporate Only) *"
                placeholder="Your email"
                className="firsthalfWidthElement"
                type="email"
              />
              <div
                className="lasthalfWidthElement"
                style={{ alignSelf: 'flex-end' }}
              >
                <div className="buttonsWrapper">
                  <div className="buttons">
                    <Button
                      type="submit"
                      className="btn btn-primary"
                      disabled={submitting || pristine}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </StyledForm>
            {/* {(() => {
              switch (process.env.NODE_ENV) {
                case 'development':
                  return <pre>{JSON.stringify(values, 0, 2)}</pre>;
                default:
                  return '';
              }
            })()} */}
          </>
        )}
      />
    </Content>
  );
};
