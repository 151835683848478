import styled from 'styled-components';
import { theme, mixins } from 'Styles';
const { colors, fontSize } = theme;

const Content = styled.div`
  width: 100%;
  padding: 1rem 0;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

export default Content;
