import styled from 'styled-components';
import { theme, mixins } from 'Styles';
const FlatSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    flex-direction: ${props => (props.layout == 'ltr' ? `row` : `row-reverse`)};
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  justify-content: space-between;
  margin: 0 auto;
  padding: 4rem 0;
  max-width: ${theme.layout.base};
  // ${mixins.hairLineUnder};
`;
export default FlatSection;
