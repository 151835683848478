import React from 'react';
import { Form, Field } from 'react-final-form';
import styled, { css } from 'styled-components';
import { theme, mixins } from 'Styles';
import Select from 'react-select';

const StyledSelectElement = styled(Select)`
  width: 100%;
  color: ${theme.colors.black.base};
  font-size: ${theme.fontSize.smallish};
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  display: block;
  border-radius: ${theme.borderRadius.elements};
  padding: 0;
  background: ${theme.colors.white.base};
  border-width: 1px;
  border-color: ${theme.colors.white.base};
  border-style: solid;
  min-height: 38px;

  &:hover,
  &:focus {
    color: ${theme.colors.black.base};
    background: ${theme.colors.white.base};
    border-color: ${theme.colors.primary.base};
    outline: 0;
  }
  & + button,
  & + a {
    margin: 1rem 0 0 0;
  }
  @media (min-width: ${theme.breakpoints.s}) {
    & + button,
    & + a {
      margin: 1rem 0 0 0;
    }
  }
  @media (min-width: ${theme.breakpoints.m}) {
    display: inline-block;
    text-align: left;
    & + button,
    & + a {
      margin: 0 0 0 8px;
    }
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  & + .error-message {
    color: ${theme.colors.primary.base};
  }
`;

export class StyledSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChange(e) {}

  // handleChange = selectedOption => {
  //   this.setState({
  //     selectedOption: selectedOption,
  //   });
  // console.log(`${this.props.name} :`, selectedOption);
  //   // console.log(`this.state :`, this.state);
  // };

  render() {
    return (
      <Field name={this.props.name}>
        {({ input, meta }) => (
          <div className={this.props.className}>
            {this.props.label && (
              <label htmlFor={`_${this.props.name}_`}>{this.props.label}</label>
            )}
            <StyledSelectElement
              {...input}
              id={`_${this.props.name}_`}
              type={this.props.type}
              placeholder={this.props.placeholder}
              className={`${
                meta.error && meta.touched ? 'form-error' : 'form-valid'
              }`}
              value={this.props.selectedOption}
              onBlur={this.props.onBlur}
              options={this.props.options}
              styles={{
                // option: (provided, state) => ({
                //   ...provided,
                //   borderBottom: '1px solid #ccc',
                //   // color: state.isSelected ? 'red' : 'blue',
                //   padding: '6px 1rem',
                // }),
                menu: () => ({
                  boxShadow: '0 15px 25px rgba(0,0,0,0.2)',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  position: 'absolute',
                  zIndex: 3,
                  background: '#fff',
                  width: '100%',
                }),
                // control: () => ({
                //   // none of react-select's styles are passed to <Control />
                //   // width: 200,
                //   // background: '#000',
                //   borderRadius: '22px',
                //   border: '1px solid #ccc',
                // }),
                // singleValue: (provided, state) => {
                //   const opacity = state.isDisabled ? 0.5 : 1;
                //   const transition = 'opacity 300ms';

                //   return { ...provided, opacity, transition };
                // },
              }}
              isSearchable={true}
              defaultMenuIsOpen={this.props.defaultMenuIsOpen}
              // getOptionValue={option => option['value']}
              isMulti={this.props.isMulti}
              theme={styling => ({
                ...styling,
                borderRadius: theme.borderRadius.elements,
                // colors: {
                //   ...theme.colors,
                //   primary25: 'rgba(0,0,0,0.2)',
                //   primary: 'black',
                // },
              })}
            />
            {meta.error && meta.touched && (
              <span className="error-message">{meta.error}</span>
            )}
          </div>
        )}
      </Field>
    );
  }
}

export default StyledSelect;
