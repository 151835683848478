import styled from 'styled-components';
import { theme, mixins } from 'Styles';
const { colors, fontSize } = theme;

const Text = styled.div`
  display: block;
  margin-top: 1rem;
  margin-bottom: 2rem;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    margin: ${props => (props.layout == 'rtl' ? `0 0 0 2rem` : `0 2rem 0 0`)};
    flex-direction: row;
    margin-top: 0rem;
    align-self: center;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

export default Text;
