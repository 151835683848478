import styled from 'styled-components';
import { theme, mixins } from 'Styles';

const FeaturedEvent = styled.section`
  border-radius: ${theme.borderRadius.ui};
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: -3rem auto 0;
  flex-direction: column;
  @media (min-width: ${theme.breakpoints.s}) {
    margin: -1rem auto 0;
  }

  @media (min-width: ${theme.breakpoints.m}) {
  }

  @media (min-width: ${theme.breakpoints.l}) {
    margin: -80px auto 3rem;
  }
  max-width: ${theme.layout.base};

  .event-list-link {
    box-shadow: ${theme.shadow.feature.small.default};
    min-height: 160px;
    .event-list-featured {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export default FeaturedEvent;
