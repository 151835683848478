import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme, mixins, ActionButton } from 'Styles';
import YouTube from '@u-wave/react-youtube';
import Vimeo from '@u-wave/react-vimeo';

const Wrapper = styled.header`
  background: none;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  ${mixins.backgroundBefore};
`;

const Content = styled.div`
  color: ${theme.colors.white.base};
  max-width: ${theme.layout.base};
  width: 100%;
  max-width: ${theme.layout.base};
  height: 100%;
  position: relative;
  margin: 0 auto 8rem;
  @media (max-width: ${theme.breakpoints.s}) {
    margin: 0 2rem 5rem;
  }
  @media (max-width: ${theme.breakpoints.m}) {
    margin: 0 2rem 5rem;
  }
  @media (max-width: ${theme.breakpoints.l}) {
    margin: 0 2rem 5rem;
  }
  z-index: 1;
`;

const Title = styled.h1`
  color: ${theme.colors.white.base};
  font-size: ${theme.fontSize.h2};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
`;

const Subtitle = styled.p`
  max-width: 650px;
  font-weight: normal;
  font-size: ${theme.fontSize.large};
  font-weight: bold;
  color: ${theme.colors.white.light};
`;

const HeroFull = ({
  children,
  title,
  cta_url,
  cta_action1,
  cta_action2,
  image,
  youtubeId,
  vimeoId,
}) => (
  <Wrapper>
    {image && !vimeoId && !youtubeId && (
      <Img
        alt={title}
        title={title}
        fluid={image.childImageSharp.fluid || {} || [] || ''}
      />
    )}

    {!image && vimeoId && !youtubeId && (
      <div className="video-background">
        <div className="video-foreground">
          <Vimeo
            video={vimeoId}
            width={640}
            height={480}
            autoplay
            volume={0}
            loop={true}
            background={true}
            controls={false}
            className="video-iframe"
          />
        </div>
      </div>
    )}

    {!image && !vimeoId && youtubeId && (
      <div className="video-background">
        <div className="video-foreground">
          <YouTube
            video={youtubeId}
            width={640}
            height={480}
            autoplay
            volume={0}
            loop={1}
            background={true}
            controls={false}
            className="video-iframe"
            showRelatedVideos={false}
          />
        </div>
      </div>
    )}

    <Content>
      <Title>{title}</Title>
      {children && <Subtitle>{children}</Subtitle>}
      {cta_action1 && (
        <ActionButton onClick={cta_action1}>Attend secureCISO</ActionButton>
      )}
      {cta_action2 && (
        <ActionButton onClick={cta_action2}>
          Sponsor secureCISO Events
        </ActionButton>
      )}
      {cta_url && (
        <ActionHrefAlt href={cta_url}>Sponsor secureCISO Events</ActionHrefAlt>
      )}
    </Content>
  </Wrapper>
);

export default HeroFull;

HeroFull.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

HeroFull.defaultProps = {
  children: false,
  image: false,
  title: false,
};
