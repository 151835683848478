import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import YouTube from '@u-wave/react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import { BackgroundImage, BackgroundVideo } from 'Components';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  Title,
  Subtitle,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
} from 'Styles';

const Wrapper = styled.div`
  color: ${props =>
    props.image
      ? props.dark
        ? theme.colors.white.base
        : theme.colors.black.base
      : theme.colors.black.base};
  min-height: ${props => (props.big ? '300px;' : '100px;')};
  background: none;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  .gatsby-image-wrapper {
    position: initial !important;
  }
  // ${props => (props.dark ? mixins.backgroundBefore : '')};
  ${props => (props.image ? mixins.backgroundBefore : '')};
`;

const Content = styled.div`
  // color: inherit;
  width: 100%;
  // position: relative;
  // z-index: 3;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  // align-items: center;
  // font-size: ${theme.fontSize.large};
  // padding: 2rem 1rem;
  margin: 0 auto;
  max-width: ${theme.layout.base};
  // @media (min-width: ${theme.breakpoints.s}) {
  //   padding: 4rem 2rem 0;
  // }
  // @media (min-width: ${theme.breakpoints.m}) {
  // }
  // @media (min-width: ${theme.breakpoints.l}) {
  // }

  width: 100%;
  display: grid;
  padding: 2rem 1rem;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  @media (min-width: ${theme.breakpoints.s}) {
    padding: 4rem 2rem 0;
  }
  @media (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
  @media (min-width: ${theme.breakpoints.l}) {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  align-self: center;
  justify-content: center;
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 2rem;
  border-radius: ${theme.borderRadius.ui};
  box-shadow: ${theme.shadow.feature.small.default};
`;

const VideoBlock = styled.div`
  .video-iframe {
    width: 100%;
    height: auto;
    min-height: 300px;
    margin: 0;
  }
`;

const SectionCard = ({
  big,
  dark,
  layout,
  image,
  title,
  subtitle,
  copy,
  cta_action,
  cta_action_text,
  cta_url,
  cta_text,
  cta_url_1,
  cta_text_1,
  children,
  vimeoId,
  youtubeId,
}) => (
  <Wrapper layout={layout} dark={dark} big={big}>
    <Content layout={layout}>
      <TextBlock>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Subtitle>{copy}</Subtitle>
        {cta_text && cta_url && (
          <ActionButtonAlt href={cta_url}>{cta_text}</ActionButtonAlt>
        )}
        {cta_text_1 && cta_url_1 && (
          <ActionButtonAlt href={cta_url_1}>{cta_text_1}</ActionButtonAlt>
        )}
      </TextBlock>
      {vimeoId && !youtubeId && (
        <VideoBlock>
          <Vimeo
            video={vimeoId}
            width={640}
            height={480}
            autoplay
            volume={0}
            loop={true}
            background={true}
            controls={false}
            className="video-iframe"
          />
        </VideoBlock>
      )}
      {!vimeoId && youtubeId && (
        <VideoBlock>
          <YouTube
            video={youtubeId}
            width={640}
            height={480}
            autoplay
            volume={0}
            loop={1}
            background={true}
            controls={false}
            className="video-iframe"
            showRelatedVideos={false}
          />
        </VideoBlock>
      )}
      {/* {image && <BackgroundImage title={title} image={image} />} */}
    </Content>
  </Wrapper>
);

export default SectionCard;
