import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import theme from '../../config/theme';
import { GlobalStyle, theme, mixins } from 'Styles';

const Wrapper = styled.article`
  margin-bottom: 2rem;
  position: relative;
  z-index: 100;
  border-radius: ${theme.borderRadius.default};
  box-shadow: ${theme.shadow.feature.small.default};
  transition: ${theme.transitions.boom.transition};
  height: 17rem;
  flex-basis: calc(99.9% * 1 / 3 - 2.5rem);
  max-width: calc(99.9% * 1 / 3 - 2.5rem);
  width: calc(99.9% * 1 / 3 - 2.5rem);

  &:hover {
    box-shadow: ${theme.shadow.feature.small.hover};
    transform: scale(1.02);
  }

  @media (max-width: 1000px) {
    flex-basis: calc(99.9% * 1 / 2 - 1rem);
    max-width: calc(99.9% * 1 / 2 - 1rem);
    width: calc(99.9% * 1 / 2 - 1rem);
    height: 18rem;
  }

  @media (max-width: 700px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    height: 15rem;
  }
`;

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  z-index: 3;
  border-radius: ${theme.borderRadius.default};
  ${mixins.backgroundBefore};
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  overflow: hidden;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  object-fit: cover;
  border-radius: ${theme.borderRadius.default};
  img {
    border-radius: ${theme.borderRadius.default};
  }
  > div {
    position: static !important;
  }
  > div > div {
    position: static !important;
  }
`;

const Info = styled.div`
  color: ${theme.colors.white.light};
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.h2`
  color: ${theme.colors.white.base};
  font-size: ${theme.fontSize.h5};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
  margin-bottom: 0rem;
`;

const Type = styled.p`
  font-size: ${theme.fontSize.large};
  font-weight: bold;
  color: ${theme.colors.white.light};
`;

const Description = styled.p`
  margin-bottom: 0rem;
  font-size: ${theme.fontSize.small};
  font-weight: bold;
  color: ${theme.colors.white.light};
`;

const Date = styled.p`
  margin-bottom: 6px;
  font-size: ${theme.fontSize.small};
  font-weight: bold;
  color: ${theme.colors.white.light};
`;

const PostList = ({ cover, path, date, title, type, excerpt }) => (
  <Wrapper>
    {cover && (
      <Image>
        <Img alt={title} title={title} fluid={cover} />
      </Image>
    )}
    <StyledLink to={path}>
      <Info>
        <Type>{type}</Type>
        <div>
          <Date>{date && date}</Date>
          <Title>{title}</Title>
          <Description>{excerpt}</Description>
        </div>
      </Info>
    </StyledLink>
  </Wrapper>
);

export default PostList;

PostList.propTypes = {
  cover: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  title: PropTypes.string.isRequired,
};
