import React from 'react';
import moment from 'moment';
import './styles.scss';

class DateTag extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { date } = this.props;
    return (
      <div className="date-tag">
        <div className="month-tag">{moment(date).format('MMM')}</div>
        <div className="day-tag">{moment(date).format('D')}</div>
      </div>
    );
  }
}

export default DateTag;
