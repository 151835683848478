import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  Title,
  Subtitle,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
} from 'Styles';

const TextBlock = styled.div`
  text-align: left;
  width: 100%;
`;

const Wrapper = styled.section`
  color: ${props =>
    props.dark ? theme.colors.black.base : theme.colors.white.base};
  min-height: ${props => (props.big ? '300px;' : '100px;')};
  background: none;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 4rem 1rem;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    padding: 4rem 2rem;
    margin: 0 auto;
  }
  @media (min-width: ${theme.breakpoints.l}) {
    margin: 0 auto;
  }
  .gatsby-image-wrapper {
    position: initial !important;
  }
  ${props => (props.dark ? '' : mixins.backgroundBefore)};
`;

const Content = styled.div`
  color: inherit;
  max-width: ${theme.layout.base};
  width: 100%;
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  justify-content: space-between;
  margin: 0 auto;
  align-items: flex-start;
  font-size: ${theme.fontSize.large};
`;

const SectionSponsors = ({
  big,
  dark,
  event,
  image,
  title,
  subtitle,
  cta_action,
  cta_action_text,
  cta_url,
  cta_text,
  cta_url_1,
  cta_text_1,
  children,
}) => (
  <Wrapper dark={dark} big={big}>
    {image && (
      <Img
        alt={title}
        title={title}
        objectFit="contain"
        objectPosition="50% 50%"
        fluid={image || {} || [] || ''}
      />
    )}
    <Content>
      <Fade left>
        <>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <div>
            {cta_text && cta_url && (
              <ActionButton href={cta_url}>{cta_text}</ActionButton>
            )}
            {cta_text_1 && cta_url_1 && (
              <ActionButton href={cta_url_1}>{cta_text_1}</ActionButton>
            )}
            {cta_action && cta_action_text && (
              <ActionButton onClick={cta_action}>
                {cta_action_text}
              </ActionButton>
            )}
          </div>
          {children && children}
        </>
      </Fade>
    </Content>
  </Wrapper>
);

export default SectionSponsors;
