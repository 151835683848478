import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Chart from 'chart.js';

import { defaults, Doughnut, Bar } from 'react-chartjs-2';
defaults.global.animation = true;

import Fade from 'react-reveal/Fade';
import { useCountUp } from 'react-countup';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  Title,
  Subtitle,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
} from 'Styles';

const Wrapper = styled.div`
  color: ${props =>
    props.dark ? theme.colors.black.base : theme.colors.white.base};
  min-height: ${props => (props.big ? '300px;' : '100px;')};
  background: none;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4rem 1rem;
  @media (min-width: ${theme.breakpoints.s}) {
    padding: 4rem 2rem;
  }
  @media (min-width: ${theme.breakpoints.m}) {
    padding: 4rem 4rem;
    margin: 3rem auto 0;
  }
  @media (min-width: ${theme.breakpoints.l}) {
    padding: 4rem 4rem;
    margin: 3rem auto 0;
  }
  .gatsby-image-wrapper {
    position: initial !important;
  }
  ${props => (props.dark ? '' : mixins.backgroundBefore)};
`;

const ChartsGrid = styled.div`
  margin: 1rem auto;
  display: flex;
  text-align: center;
  // grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  @media (min-width: ${theme.breakpoints.s}) {
    // grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  }
  @media (min-width: ${theme.breakpoints.m}) {
    // grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  }
  @media (min-width: ${theme.breakpoints.l}) {
    // grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  }
  grid-gap: 16px;
`;

const Content = styled.div`
  color: inherit;
  max-width: ${theme.layout.base};
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  justify-content: space-between;
  margin: 0 auto;
  align-items: flex-start;
  font-size: ${theme.fontSize.large};
`;

let myLineChart;
Chart.defaults.global.legend.display = false;

class SectionChart extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    data: managerData,
    average: nationalAverageData,
    labels: yearLabels,
  };

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    const {
      big,
      dark,
      image,
      title,
      data,
      subtitle,
      cta_action,
      cta_action_text,
      cta_url,
      cta_text,
      cta_url_1,
      cta_text_1,
      children,
    } = this.props;

    const SimpleHook = () => {
      const { countUp, start, pauseResume, reset, update } = useCountUp({
        start: 0,
        end: 9999999,
        duration: 3,
        delay: 0,
        separator: ' ',
        decimals: 0,
        decimal: ',',
        prefix: '',
        suffix: ' AAAA',
        onReset: () => console.log('Resetted!'),
        onUpdate: () => console.log('Updated!'),
        onPauseResume: () => console.log('Paused or resumed!'),
        onStart: ({ pauseResume }) => console.log(pauseResume),
        onEnd: ({ pauseResume }) => console.log(pauseResume),
      });
      return (
        <>
          <button className="btn btn-primary" onClick={start}>
            Start
          </button>
          <button onClick={reset}>Reset</button>
          <h3 onScroll={this.start}>{countUp}</h3>
        </>
      );
    };

    return (
      <Wrapper dark={dark} big={big}>
        {image && (
          <Img
            alt={title}
            title={title}
            objectFit="contain"
            objectPosition="50% 50%"
            fluid={image.childImageSharp.fluid || {} || [] || ''}
          />
        )}
        <Content>
          <ChartsGrid>
            <Fade bottom onReveal={() => console.log('REVEALED!!')}>
              <>
                <Bar data={data} options={{ maintainAspectRatio: true }} />
                <SimpleHook onClick={() => update(2000)} />
              </>
            </Fade>
            <Fade bottom delay={500}>
              <>
                <Doughnut data={data} />
                <Subtitle>Lorem ipsum dolor sit amet consectetur.</Subtitle>
              </>
            </Fade>
            <Fade bottom delay={1000}>
              <>
                <Doughnut data={data} />
                <Subtitle>Lorem ipsum dolor sit amet consectetur.</Subtitle>
              </>
            </Fade>
            <Fade bottom delay={1500}>
              <>
                <Doughnut data={data} />
                <Subtitle>Lorem ipsum dolor sit amet consectetur.</Subtitle>
              </>
            </Fade>
          </ChartsGrid>
        </Content>
      </Wrapper>
    );
  }
}

export default SectionChart;

const managerData = [
  3910,
  3706,
  2850,
  4005,
  3750,
  2912,
  3200,
  3645,
  4205,
  3211,
  3354,
  3904,
];

const nationalAverageData = [
  4010,
  3600,
  2900,
  3550,
  3800,
  2900,
  3000,
  3500,
  4000,
  3700,
  3550,
  3800,
];

const managerQuarterData = [
  500,
  390,
  670,
  600,
  780,
  440,
  600,
  680,
  550,
  475,
  700,
  795,
];

const nationalAverageQuarterData = [
  600,
  400,
  600,
  550,
  700,
  500,
  600,
  700,
  500,
  550,
  600,
  700,
];

//---Labels---//

const yearLabels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const quarterLabels = [
  'Week 1',
  'Week 2',
  'Week 3',
  'Week 4',
  'Week 5',
  'Week 6',
  'Week 7',
  'Week 8',
  'Week 9',
  'Week 10',
  'Week 11',
  'Week 12',
];

//---Labels---//

//---More Complex Dataset---//

const districtManagerData = [
  { sum: 13100, newarkBranch: 4500, austinBranch: 3700, denverBranch: 4900 },
  { sum: 12500, newarkBranch: 3900, austinBranch: 3700, denverBranch: 4900 },
  { sum: 12050, newarkBranch: 3450, austinBranch: 3700, denverBranch: 4900 },
  { sum: 13200, newarkBranch: 4600, austinBranch: 3700, denverBranch: 4900 },
  { sum: 13035, newarkBranch: 4435, austinBranch: 3700, denverBranch: 4900 },
  { sum: 12700, newarkBranch: 4100, austinBranch: 3700, denverBranch: 4900 },
  { sum: 13720, newarkBranch: 5120, austinBranch: 3700, denverBranch: 4900 },
  { sum: 13550, newarkBranch: 4700, austinBranch: 3950, denverBranch: 4900 },
  { sum: 12700, newarkBranch: 4100, austinBranch: 3700, denverBranch: 4900 },
  { sum: 12800, newarkBranch: 4200, austinBranch: 3700, denverBranch: 4900 },
  { sum: 11800, newarkBranch: 3950, austinBranch: 3650, denverBranch: 4200 },
  { sum: 12745, newarkBranch: 3620, austinBranch: 3780, denverBranch: 5345 },
];
