import React from 'react';
import { Form, Field } from 'react-final-form';
export class Heading extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={this.props.className}>
        <h4 className="form-group-header">{this.props.title}</h4>
      </div>
    );
  }
}

export default Heading;
