// import React from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

// const Wrapper = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: 18px;
//   height: 18px;
//   background-color: #000;
//   border: 2px solid #fff;
//   border-radius: 100%;
//   user-select: none;
//   transform: translate(-50%, -50%);
//   cursor: ${props => (props.onClick ? 'pointer' : 'default')};
//   &:hover {
//     z-index: 1;
//   }
// `;

// const GoogleMapMarker = props => (
//   <Wrapper
//     alt={props.text}
//     {...(props.onClick ? { onClick: props.onClick } : {})}
//   />
// );

// GoogleMapMarker.defaultProps = {
//   onClick: null,
// };

// GoogleMapMarker.propTypes = {
//   onClick: PropTypes.func,
//   text: PropTypes.string.isRequired,
// };

// export default GoogleMapMarker;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  &:hover {
    z-index: 1;
  }
`;

const markerStyle = {
  border: '1px solid white',
  borderRadius: '50%',
  height: 10,
  width: 10,
  // backgroundColor: props.show ? 'red' : 'blue',
  backgroundColor: 'blue',
  cursor: 'pointer',
  zIndex: 10,
};

const GoogleMapMarker = props => (
  <>
    <div style={markerStyle} />
    {props.show && <InfoWindow place={props.place} />}
  </>
  // <Wrapper
  //   alt={props.text}
  //   {...(props.onClick ? { onClick: props.onClick } : {})}
  // />
);

GoogleMapMarker.defaultProps = {
  onClick: null,
};

GoogleMapMarker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default GoogleMapMarker;
