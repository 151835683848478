import styled from 'styled-components';
import { theme, mixins } from 'Styles';
const FirstSection = styled.section`
  background: #fff;
  border-radius: ${theme.borderRadius.ui};
  box-shadow: ${theme.shadow.feature.small.default};
  border: 1px solid #ddd;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem auto 0;
  padding: 1rem;

  @media (min-width: ${theme.breakpoints.s}) {
    // padding: 2rem;
    // margin: -1rem auto 0;
  }

  @media (min-width: ${theme.breakpoints.m}) {
    // margin: -1rem auto 0;
  }

  @media (min-width: ${theme.breakpoints.l}) {

  }

  max-width: ${theme.layout.base};
  // ${mixins.hairLineUnder};
`;
export default FirstSection;
