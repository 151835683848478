import React from 'react';

import List from './List';
import Config from 'Data';
import moment from 'moment';
import Fade from 'react-reveal/Fade';

// import './styles.scss';

class EventPlaceholder extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      featured,
      countdown,
      photo,
      banner,
      category,
      date,
      eventCountry,
      eventState,
      eventCity,
      eventType,
      eventName,
      rating,
      event,
      eventURL,
      eventStart,
      eventEnd,
      eventStartDate,
      eventEndDate,
      action,
      share,
      className,
    } = this.props;

    return (
      <List className={`event-list ${className}`}>
        <a href={eventURL} target="_blank" className="">
          <div className="event-list-contents">
            <div className="event-list-featured">
              <div className="event-list-info">
                <Fade bottom delay={500}>
                  <div className="event-card-city">{'//TODO (Empty Date)'}</div>
                </Fade>
              </div>
              {countdown && (
                <Countdown
                  className="event-list-countdown"
                  timeTillDate={moment(eventStartDate)}
                  timeFormat="ddd, DD MMM YYYY HH:mm"
                />
              )}
            </div>
          </div>
        </a>
      </List>
    );
  }
}

export default EventPlaceholder;
