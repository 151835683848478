import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme, mixins, ActionButton } from 'Styles';

const Wrapper = styled.header`
  background: none;
  min-height: 70vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  ${mixins.backgroundBefore};
`;

const Content = styled.div`
  color: ${theme.colors.white.base};
  max-width: ${theme.layout.base};
  width: 100%;
  max-width: ${theme.layout.base};
  height: 100%;
  position: relative;
  margin: 0 auto 8rem;
  @media (max-width: ${theme.breakpoints.s}) {
    margin: 0 2rem 5rem;
  }
  @media (max-width: ${theme.breakpoints.m}) {
    margin: 0 2rem 5rem;
  }
  @media (max-width: ${theme.breakpoints.l}) {
    margin: 0 2rem 5rem;
  }
  z-index: 1;
`;

const Title = styled.h1`
  color: ${theme.colors.white.base};
  font-size: ${theme.fontSize.h2};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
`;

const Subtitle = styled.p`
  max-width: 650px;
  font-weight: normal;
  font-size: ${theme.fontSize.large};
  font-weight: bold;
  color: ${theme.colors.white.light};
`;

const HeroBig = ({ children, title, cta_text, cta_url, image }) => (
  <Wrapper>
    {image && (
      <Img
        alt={title}
        title={title}
        fluid={image.childImageSharp.fluid || {} || [] || ''}
      />
    )}
    <Content>
      <Title>{title}</Title>
      {children && <Subtitle>{children}</Subtitle>}
      {cta_text && <ActionButton href={cta_url}>{cta_text}</ActionButton>}
    </Content>
  </Wrapper>
);

export default HeroBig;

HeroBig.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

HeroBig.defaultProps = {
  children: false,
  image: false,
  title: false,
};
