import styled from 'styled-components';
import { theme, mixins } from 'Styles';
const ActionSection = styled.div`
  width: 100%;
  display: block;
  position: relative;
  z-index: 2;
  @media (max-width: ${theme.breakpoints.m}) {
  }
  margin: 0 auto 2rem;
  padding: 0;
  h2 {
    margin: 0;
  }
`;
export default ActionSection;
