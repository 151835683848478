import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  Title,
  Subtitle,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
} from 'Styles';

const TextBlock = styled.div`
  text-align: left;
  width: 100%;
`;

const Wrapper = styled.div`
  color: ${props =>
    props.image
      ? props.dark
        ? theme.colors.white.base
        : theme.colors.black.base
      : theme.colors.black.base};
  min-height: ${props => (props.big ? '300px;' : '100px;')};
  background: none;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  .gatsby-image-wrapper {
    position: initial !important;
  }
  // ${props => (props.dark ? mixins.backgroundBefore : '')};
  ${props => (props.image ? mixins.backgroundBefore : '')};
`;

const Content = styled.div`
  color: inherit;
  width: 100%;
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: ${theme.fontSize.large};
  padding: 2rem 1rem;
  margin: 0 auto;
  max-width: ${theme.layout.base};
  @media (min-width: ${theme.breakpoints.s}) {
    padding: 4rem 2rem 0;
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const SectionFeatures = ({
  big,
  dark,
  image,
  title,
  subtitle,
  cta_action,
  cta_action_text,
  cta_url,
  cta_text,
  cta_url_1,
  cta_text_1,
  children,
}) => (
  <Wrapper dark={dark} big={big} image={image}>
    {image && (
      <Img
        alt={title}
        title={title}
        objectFit="contain"
        objectPosition="50% 50%"
        fluid={image.childImageSharp.fluid || {} || [] || ''}
      />
    )}
    <Content>
      {/* <Fade> */}
      <>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        {children && children}
        <div>
          {children && <br />}
          {cta_text && cta_url && (
            <ActionButtonAlt href={cta_url}>{cta_text}</ActionButtonAlt>
          )}
          {cta_text_1 && cta_url_1 && (
            <ActionButtonAlt href={cta_url_1}>{cta_text_1}</ActionButtonAlt>
          )}
          {cta_action && cta_action_text && (
            <ActionButtonAlt onClick={cta_action}>
              {cta_action_text}
            </ActionButtonAlt>
          )}
        </div>
      </>
      {/* </Fade> */}
    </Content>
  </Wrapper>
);

export default SectionFeatures;
