import styled from 'styled-components';
import { theme, mixins } from 'Styles';
const { colors, fontSize } = theme;

const FlatText = styled.div`
  display: block;
  margin-top: 2rem;
  background: #fff;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
  margin: 0;
  padding: 2rem 1rem 3rem;
  border-radius: ${theme.borderRadius.ui};
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    padding: 2rem;
    border-radius: ${theme.borderRadius.ui} 0 0 ${theme.borderRadius.ui};
    width: 100%;
    flex-direction: row;
    align-self: center;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

export default FlatText;
