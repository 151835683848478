import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { BackgroundImage, BackgroundVideo, Team } from 'Components';
import LinkedInIcon from 'Static/icons/linkedin.svg';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
  BrandedColor,
  Speakers,
  ActionLinkedin,
} from 'Styles';

const Wrapper = styled.section`
  background: #fff;
  border-radius: ${theme.borderRadius.ui};
  // box-shadow: ${theme.shadow.feature.small.default};
  // border: 1px solid #ddd;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1rem;
  @media (min-width: ${theme.breakpoints.s}) {
  }

  @media (min-width: ${theme.breakpoints.m}) {
  }

  @media (min-width: ${theme.breakpoints.l}) {
    margin: 0 auto;
    padding: 0;
  }

  max-width: ${theme.layout.base};
`;

const Content = styled.div`
  display: block;
  margin: 0 auto;
  text-align: left;
  width: 100%;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const GalleryGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(200px, 240px);
  background-color: #fff;
  color: #444;
  margin: 0 auto 3rem;
  .box {
    &.a {
      grid-column: 1 / 3;
      grid-row: 1;
    }
    &.b {
      grid-column: 1 / 3;
      grid-row: 3;
    }
    &.c {
      grid-column: 1;
      grid-row: 2;
    }
    &.d {
      grid-column: 2;
      grid-row: 2;
    }
  }
  @media (min-width: ${theme.breakpoints.s}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: minmax(200px, 240px);
    .box {
      &.a {
        grid-column: 1 / 4;
        grid-row: 1;
      }
      &.b {
        grid-column: 3;
        grid-row: 2;
      }
      &.c {
        grid-column: 1;
        grid-row: 2;
      }
      &.d {
        grid-column: 2;
        grid-row: 2;
      }
    }
  }
  @media (min-width: ${theme.breakpoints.m}) {
    .box {
      &.a {
        grid-column: 1 / 3;
        grid-row: 1;
      }
      &.b {
        grid-column: 3;
        grid-row: 1 / 3;
      }
      &.c {
        grid-column: 1;
        grid-row: 2;
      }
      &.d {
        grid-column: 2;
        grid-row: 2;
      }
    }
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const GalleryImage = styled(BackgroundImage)`
  &:before {
    background-image: none;
  }
`;

const Title = styled.h2`
  color: inherit;
  font-weight: bold;
  max-width: ${theme.layout.base};
  margin-left: auto;
  margin-right: auto;
  text-align: left;
`;
const Subtitle = styled.p`
  color: inherit;
  max-width: ${theme.layout.base};
  margin: 0 auto;
  & + a {
    margin-top: 1rem;
  }
  text-align: left;
`;

const SectionGallery = ({ image1, image2, image3, image4, children }) => (
  <>
    <Wrapper>
      <Content>
        <GalleryGrid>
          <GalleryImage className="box a" image={image1} title={'Gallery'} />
          <GalleryImage className="box b" image={image2} title={'Gallery'} />
          <GalleryImage className="box c" image={image3} title={'Gallery'} />
          <GalleryImage className="box d" image={image4} title={'Gallery'} />
        </GalleryGrid>
      </Content>
    </Wrapper>
  </>
);

export default SectionGallery;
