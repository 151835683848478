const { GA_TRACKING_ID } = process.env;

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'secureCISO', // Navigation and Site Title
  titleAlt: 'secureCISO by CXOsync', // Title for JSONLD
  logo: 'static/logo/logo.png', // Used for SEO
  favicon: 'static/logo/favicon.png',
  banner: 'static/logo/logo.png',
  // JSONLD / Manifest
  author: 'CXOsync', // Author for schemaORGJSONLD
  themeColor: '#3e7bf2',
  backgroundColor: '#d3e0ff',
  twitter: '@secureciso', // Twitter Username

  slogan: 'A CXOsync Event',
  brand: 'secureCISO',
  audience: 'CISO',
  brandBusiness: 'secureCISO Events',
  siteTitle: 'secureCISO Events',
  description:
    'secureCISO Events by CXOsync | Thought leading collaboration for the global CIO and IT leader',
  siteLanguage: 'en', // Language Tag on <html> element
  siteKeywords: '',
  url: 'https://bluhookah.com', // Domain of your site. No trailing slash!
  siteUrl: 'https://bluhookah.com',
  adminEmail: 'abuirshaid@me.com',
  noreplayEmail: 'no-replay@bluhookah.com',
  founder: 'Nepal Patel',
  foundingDate: '2019',
  googleVerification: '',

  brands: [
    {
      name: 'ciosynergy',
      displayName: 'CIOsynergy',
      url: 'https://ciosynergy.com/',
    },
    {
      name: 'cxodinners',
      displayName: 'CXOdinners',
      url: 'https://www.cxodinners.com/',
    },
    {
      name: 'secureciso',
      displayName: 'secureCISO',
      url: 'https://www.secureciso.com/',
    },
    {
      name: 'cxosports',
      displayName: 'CXOsports',
      url: 'https://info.ciosynergy.com/ciosports/',
    },
    {
      name: 'eventpopulation',
      displayName: 'Event Population',
      url: 'https://www.eventpopulation.com/',
    },
  ],

  contact: {
    name: 'CXOsync',
    legalName: 'CXOsync LLC',
    location: 'Schaumburg, IL',
    email: 'info@cxosync.com',
    phone: '+1 (847) 278-2213',
    address: {
      street: '1900 E Golf Rd',
      city: 'Schuamburg',
      state: 'IL',
      country: 'United States',
      zipCode: '60173',
    },
  },

  twitterHandle: 'secureCISO',
  social: {
    facebook: '@',
    twitter: '@secureciso',
    instagram: '',
    linkedin: '',
  },
  socialMedia: [
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/company/cxo-sync/',
    },
    {
      name: 'Instagram',
      url: '',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/secureciso',
    },
  ],

  nav: [
    // {
    //   name: 'Home',
    //   url: '/',
    // },
    {
      name: 'Events',
      url: '/events',
    },
    // {
    //   name: 'Blog',
    //   url: '/',
    // },
    // {
    //   name: 'Media',
    //   url: '/media',
    // },
    // {
    //   name: 'Sponsors',
    //   url: '/sponsors',
    // },
    {
      name: 'Testimonials',
      url: '/testimonials',
    },
    {
      name: 'Partner',
      url: '/partner',
    },
    {
      name: 'About',
      url: '/about',
    },
  ],

  onePageNav: [
    // {
    //   name: 'Speakers',
    //   url: '#speakers',
    // },
    // {
    //   name: 'Schedule',
    //   url: '#schedule',
    // },
    // {
    //   name: 'Venue',
    //   url: '#venue',
    // },
    // {
    //   name: 'Sponsors',
    //   url: '#sponsors',
    // },
    // {
    //   name: 'Help?',
    //   url: '#faq',
    // },
  ],

  brandsColors: {
    ciosynergy: '#149cea',
    dinners: '#9254FF',
    secureciso: '#ff0000',
    cxosports: '#00ff00',
    eventpopulation: '#ff0000',
    panels: '#149cea',
    cfo: '#47b946',
  },

  headerHeight: 80,
  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',
  googleAnalyticsID: GA_TRACKING_ID,
};
