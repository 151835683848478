import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { BackgroundImage, BackgroundVideo, Team } from 'Components';
import LinkedInIcon from 'Static/icons/linkedin.svg';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
  BrandedColor,
  Speakers,
  ActionLinkedin,
} from 'Styles';

const Wrapper = styled.section`
  border-radius: ${theme.borderRadius.ui};
  // box-shadow: ${theme.shadow.feature.small.default};
  // border: 1px solid #ddd;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1rem;
  @media (min-width: ${theme.breakpoints.s}) {
  }

  @media (min-width: ${theme.breakpoints.m}) {
  }

  @media (min-width: ${theme.breakpoints.l}) {
    margin: 0 auto;
    padding: 0;
  }

  max-width: ${theme.layout.base};
`;

const Content = styled.div`
  display: block;
  margin: 0 auto;
  text-align: left;
  width: 100%;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const Grid = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${theme.layout.base};
`;

const TeamGrid = styled.div`
  text-align: left;
  margin: 1rem auto 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  @media (min-width: ${theme.breakpoints.s}) {
    grid-template-columns: repeat(auto-fill, minmax(28%, 1fr));
  }
  @media (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
  @media (min-width: ${theme.breakpoints.l}) {
    // grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
  gap: 1rem 12px;
`;

const Title = styled.h2`
  color: inherit;
  font-weight: bold;
  max-width: ${theme.layout.base};
  margin-left: auto;
  margin-right: auto;
  text-align: left;
`;
const Subtitle = styled.p`
  color: inherit;
  max-width: ${theme.layout.base};
  margin: 0 auto;
  & + a {
    margin-top: 1rem;
  }
  text-align: left;
`;

const SectionTeam = ({
  moderator,
  keynote,
  big,
  title,
  speakers,
  layout,
  image,
  subtitle,
  name,
  cta_action,
  cta_action_text,
  cta_url,
  cta_text,
  cta_url_1,
  cta_text_1,
  children,
}) => (
  <>
    <Wrapper>
      <Content>
        <Title>
          <BrandedColor>{title}</BrandedColor>
        </Title>
        {/* <Subtitle>{subtitle}</Subtitle> */}
        <TeamGrid>
          {moderator.linkedin_link == keynote.linkedin_link ? (
            <div>
              <h5>Moderator & Keynote</h5>
              <Speakers.Card>
                <Speakers.Speaker>
                  <>
                    <Speakers.Avatar
                      background={keynote.profile_image}
                      alt={`${keynote.name} - ${keynote.position} at ${
                        keynote.company_name
                      }`}
                      title={`${keynote.name} - ${keynote.position} at ${
                        keynote.company_name
                      }`}
                    >
                      <Speakers.Name>
                        <ActionLinkedin
                          href={keynote.linkedin_link}
                          alt={`${keynote.name} - ${keynote.position} at ${
                            keynote.company_name
                          } on LinkedIn`}
                          target="_blank"
                        >
                          <span>
                            <img
                              src={LinkedInIcon}
                              alt=""
                              style={{
                                width: '20px',
                                height: '20px',
                                margin: '0',
                              }}
                            />
                          </span>{' '}
                          {`${keynote.name}`}
                        </ActionLinkedin>
                      </Speakers.Name>
                    </Speakers.Avatar>

                    <Speakers.Details>
                      <Speakers.Title>{`${keynote.position}`}</Speakers.Title>
                      <Speakers.Company>{`${
                        keynote.company_name
                      }`}</Speakers.Company>
                    </Speakers.Details>
                  </>
                </Speakers.Speaker>
              </Speakers.Card>
            </div>
          ) : (
            <>
              <div>
                <h5>Moderator</h5>
                <Speakers.Card>
                  <Speakers.Speaker>
                    <>
                      <Speakers.Avatar
                        background={moderator.profile_image}
                        alt={`${moderator.name} - ${moderator.position} at ${
                          moderator.company_name
                        }`}
                        title={`${moderator.name} - ${moderator.position} at ${
                          moderator.company_name
                        }`}
                      >
                        <Speakers.Name>
                          <ActionLinkedin
                            href={moderator.linkedin_link}
                            alt={`${moderator.name} - ${
                              moderator.position
                            } at ${moderator.company_name} on LinkedIn`}
                            target="_blank"
                          >
                            <span>
                              <img
                                src={LinkedInIcon}
                                alt=""
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  margin: '0',
                                }}
                              />
                            </span>{' '}
                            {`${moderator.name}`}
                          </ActionLinkedin>
                        </Speakers.Name>
                      </Speakers.Avatar>

                      <Speakers.Details>
                        <Speakers.Title>{`${
                          moderator.position
                        }`}</Speakers.Title>
                        <Speakers.Company>{`${
                          moderator.company_name
                        }`}</Speakers.Company>
                      </Speakers.Details>
                    </>
                  </Speakers.Speaker>
                </Speakers.Card>
              </div>
              <div>
                <h5>Keynote</h5>
                <Speakers.Card>
                  <Speakers.Speaker>
                    <>
                      <Speakers.Avatar
                        background={keynote.profile_image}
                        alt={`${keynote.name} - ${keynote.position} at ${
                          keynote.company_name
                        }`}
                        title={`${keynote.name} - ${keynote.position} at ${
                          keynote.company_name
                        }`}
                      >
                        <Speakers.Name>
                          <ActionLinkedin
                            href={keynote.linkedin_link}
                            alt={`${keynote.name} - ${keynote.position} at ${
                              keynote.company_name
                            } on LinkedIn`}
                            target="_blank"
                          >
                            <span>
                              <img
                                src={LinkedInIcon}
                                alt=""
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  margin: '0',
                                }}
                              />
                            </span>{' '}
                            {`${keynote.name}`}
                          </ActionLinkedin>
                        </Speakers.Name>
                      </Speakers.Avatar>

                      <Speakers.Details>
                        <Speakers.Title>{`${keynote.position}`}</Speakers.Title>
                        <Speakers.Company>{`${
                          keynote.company_name
                        }`}</Speakers.Company>
                      </Speakers.Details>
                    </>
                  </Speakers.Speaker>
                </Speakers.Card>
              </div>
            </>
          )}
        </TeamGrid>
      </Content>
    </Wrapper>
  </>
);

export default SectionTeam;
