import styled from 'styled-components';
import theme from 'Theme';
const { colors, fontSize } = theme;

const StyledLink = styled.a`
  position: relative;
  color: inherit;
  font-size: ${theme.fontSize.small};
`;

export default StyledLink;
