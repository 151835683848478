import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import {
  FormSubscribe,
  AudiencesModal,
  FormAttend,
  FormContactUs,
} from 'Components';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FeaturedSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  Title,
  Subtitle,
  BackgroundSection,
  SliderContent,
  StyledLink,
  EventsGrid,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
} from 'Styles';

const MODAL_AUDIENCES = 'modal_audiences';
const MODAL_ATTEND = 'modal_attend';
const MODAL_SPEAK = 'modal_speak';
const MODAL_SPONSOR = 'modal_sponsor';
const MODAL_HOSTDINNER = 'modal_hostdinner';

import { IconLinkedin, IconInstagram, IconTwitter } from './icons';
const { colors, fontSize } = theme;
import Config from 'Data';
import { Link } from 'gatsby';

// const Wrapper = styled.footer`
//   background-color: ${colors.background.dark};
//   color: ${colors.white.base};
//   text-align: center;
//   height: auto;
//   margin-top: ${theme.headerHeight};
//   font-size: ${fontSize.small};
// `;

const Wrapper = styled.div`
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const StyledFullWrapper = styled(FullWrapper)`
  margin: 0;
  padding: 1rem;
  @media (min-width: ${theme.breakpoints.s}) {
    padding: 0 1rem;
    margin: 0 auto;
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const Content = styled.section`
  line-height: initial;
  width: 100%;
  max-width: ${theme.layout.base};
  margin: 0 auto;
  ${mixins.flexBetween};
  flex-direction: column;
  & + section {
    border-top: 1px solid ${theme.colors.primary.base};
  }
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    flex-direction: row;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const SocialContainer = styled.div`
  ${media.desktop`order: 1;`};
`;

const SocialItemList = styled.ul`
  ${mixins.flexBetween};
  margin: 0;
  li {
    margin: 0;
  }
`;

const FooterUpperBlock = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-self: flex-start;
  text-align: left;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    padding: ${theme.padding};
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const FooterBottomBlock = styled.div`
  margin: 0;
  padding: 0 ${theme.padding};
  display: flex;
  flex-direction: column;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    flex-direction: row;
    padding: ${theme.padding};
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const FooterColumn = styled.div`
  margin: 0;
  // padding: ${theme.padding};
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  width: 100%;
  a {
    display:block;
    padding: 4px 0
  }
  svg {
    width: 18px;
    height: 18px;
  }
`;

const FooterHeading = styled.h4`
  padding: ${theme.padding} 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${theme.padding};
  color: inherit;
  font-weight: bold;
  border-bottom: 1px solid ${theme.colors.primary.base};
`;

const SocialLink = styled.a`
  margin: 0;
  padding: ${theme.padding};
  svg {
    width: 18px;
    height: 18px;
  }
`;

const Copy = styled.div`
  ${media.desktop`order: 2;`};
  padding: ${theme.padding};
  ${media.tablet`padding: ${theme.padding} 0`};
`;

const LinkExternal = styled.a``;

class FooterSimple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentModal: null,
      currentAudience: null,
    };
  }

  componentWillMount() {
    this.setState({
      currentModal: null,
      currentAudience: null,
    });
  }

  toggleModal = (key, currentAudience) => e => {
    e.preventDefault();
    if (this.state.currentModal) {
      this.handleModalCloseRequest();
      return;
    }

    this.setState({
      ...this.state,
      currentModal: key,
      currentAudience,
    });
  };

  handleModalCloseRequest = () => {
    // opportunity to validate something and keep the modal open even if it
    // requested to be closed
    this.setState({
      ...this.state,
      currentModal: null,
    });
  };

  handleOnAfterOpenModal = () => {
    // when ready, we can access the available refs.
  };

  render() {
    const { currentModal } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query FooterSimpleQuery {
            index: pagesJson {
              page {
                footer {
                  title
                  subtitle
                  video
                  heroImage {
                    childImageSharp {
                      fluid(maxWidth: 2880, quality: 90) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                      }
                    }
                  }
                }
              }
            }
            content: footerJson {
              engage {
                name
                displayName
                copy
                banner {
                  childImageSharp {
                    fluid(maxWidth: 2880, quality: 90) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
              }
              audiences {
                name
                displayName
                copy
                banner {
                  childImageSharp {
                    fluid(maxWidth: 2880, quality: 90) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
              }
            }
            banners: bannersJson {
              banner {
                attend {
                  childImageSharp {
                    fluid(maxWidth: 2880, quality: 90) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
                sponsor {
                  childImageSharp {
                    fluid(maxWidth: 2880, quality: 90) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
                sharing {
                  childImageSharp {
                    fluid(maxWidth: 2880, quality: 90) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <FooterSection id="footer">
            <Wrapper>
              <StyledFullWrapper>
                {/* <Content>
                  <FooterUpperBlock>
                    <FooterColumn>
                      <FooterHeading>Join our mailing list!</FooterHeading>
                      <FormSubscribe
                        dark={true}
                        layout="rtl"
                        subtitle="Get updates and news about upcoming events delivered to your inbox!"
                        page={`pageName`}
                        audience={`audience`}
                        type={`secureciso`}
                        leadTemplate={`cxo-pages-lead-thank-you`}
                        notifyTemplate={`cxo-pages-new-lead`}
                      />
                    </FooterColumn>
                  </FooterUpperBlock>
                </Content> */}
                <Content>
                  <FooterBottomBlock>
                    <Text>
                      © {new Date().getFullYear()} {Config.contact.legalName}.
                      All rights reserved.
                    </Text>
                    <Link to={'/privacy'}>Privacy policy</Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Link to={'/terms'}>Terms of use</Link>
                  </FooterBottomBlock>
                  <FooterBottomBlock>
                    <SocialContainer>
                      <SocialItemList>
                        {Config.socialMedia &&
                          Config.socialMedia.map(
                            ({ name, url }, i) =>
                              url !== '' && (
                                <li key={i}>
                                  <SocialLink
                                    href={url}
                                    target="_blank"
                                    rel="nofollow noopener noreferrer"
                                    aria-label={name}
                                  >
                                    {name === 'Linkedin' ? (
                                      <IconLinkedin />
                                    ) : name === 'Instagram' ? (
                                      <IconInstagram />
                                    ) : name === 'Twitter' ? (
                                      <IconTwitter />
                                    ) : (
                                      ''
                                    )}
                                  </SocialLink>
                                </li>
                              )
                          )}
                      </SocialItemList>
                    </SocialContainer>
                  </FooterBottomBlock>
                </Content>
              </StyledFullWrapper>
            </Wrapper>

            <AudiencesModal
              currentAudience={this.state.currentAudience}
              // banner={banner.attend}
              isOpen={currentModal == MODAL_AUDIENCES}
              onAfterOpen={this.handleOnAfterOpenModal}
              onRequestClose={this.handleModalCloseRequest}
              closeModal={this.toggleModal(MODAL_AUDIENCES, null)}
            />
            <FormAttend
              title="Attend secureCISO"
              subtitle="Interested in attending secureCISO events? (InfoSec leaders only)"
              isOpen={currentModal == MODAL_ATTEND}
              onAfterOpen={this.handleOnAfterOpenModal}
              onRequestClose={this.handleModalCloseRequest}
              closeModal={this.toggleModal(MODAL_ATTEND)}
            />
          </FooterSection>
        )}
      />
    );
  }
}

export default FooterSimple;
