import React from 'react';
import { Form, Field } from 'react-final-form';
import styled, { css } from 'styled-components';
import { theme, mixins } from 'Styles';

const StyledInput = styled.input`
  width: 100%;
  color: ${theme.colors.black.base};
  font-size: ${theme.fontSize.smallish};
  cursor: pointer;
  white-space: nowrap;
  display: block;
  border-radius: ${theme.borderRadius.elements};
  padding: 6px 18px;
  background: ${theme.colors.white.base};
  border-width: 1px;
  border-color: ${theme.colors.white.grey};
  border-style: solid;
  min-height: 38px;

  &:hover,
  &:focus {
    color: ${theme.colors.black.base};
    background: ${theme.colors.white.base};
    border-color: ${theme.colors.primary.base};
    outline: 0;
  }
  &.form-error {
    border: 1px solid #b00;
  }
  & + button,
  & + a {
    margin: 1rem 0 0 0;
  }
  @media (min-width: ${theme.breakpoints.s}) {
    & + button,
    & + a {
      margin: 1rem 0 0 0;
    }
  }
  @media (min-width: ${theme.breakpoints.m}) {
    display: inline-block;
    text-align: left;
    & + button,
    & + a {
      margin: 0 0 0 8px;
    }
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  & + .error-message {
    color: ${theme.colors.primary.base};
  }
`;

export class Input extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Field name={this.props.name}>
        {({ input, meta }) => (
          <div className={`${this.props.className}`}>
            {this.props.label && (
              <label htmlFor={`_${this.props.name}_`}>
                {this.props.label}
                {this.props.required ? (
                  <span style={{ color: 'red' }}>&nbsp;*</span>
                ) : (
                  ''
                )}
              </label>
            )}
            <StyledInput
              {...input}
              type={this.props.type}
              placeholder={`${
                meta.error && meta.touched ? meta.error : this.props.placeholder
              }`}
              className={`${
                meta.error && meta.touched ? 'form-error' : 'form-valid'
              }`}
              value={this.props.value}
              // onChange={this.props.onChange}
              // onBlur={this.props.onBlur}
              required={this.props.required}
            />
            {/* {meta.error && meta.touched && (
              <span className="error-message">{meta.error}</span>
            )} */}
          </div>
        )}
      </Field>
    );
  }
}

export default Input;
