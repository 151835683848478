import React from 'react';
import Modal from 'react-modal';
import { Form, Field } from 'react-final-form';
import isEmail from 'validator/lib/isEmail';
import { compose, withProps } from 'recompose';
import styled, { css } from 'styled-components';
import { theme, mixins } from 'Styles';
import { graphql, StaticQuery } from 'gatsby';
import Config from 'Data';

import {
  Hero,
  HeroBig,
  PostList,
  HeroVideo,
  HeaderImage,
  BackgroundVideo,
  SectionBackground,
  EventCalendarCard,
  EventCalendarList,
} from 'Components';

import {
  Wizard,
  Input,
  TextArea,
  Heading,
  Spacer,
} from 'Components/FormElements';

const Container = styled.div`
  padding: 2rem;
`;

function postData(url = ``, data = {}) {
  return fetch(url, {
    method: 'POST',
    mode: 'no-cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  })
    .then(response => {
      // console.log(response);
      response
        .json()
        .then(data => {
          console.log(data.retURL);
          window.location = data.retURL;
        })
        .catch(function(e) {
          console.log('handled the error');
        });
    })
    .catch(error => console.error('Error:', error));
}

const API_ENDPOINT = '/.netlify/functions/referee';

const onSubmit = async values => {
  // JSON.stringify(values, 0, 2)
  postData(API_ENDPOINT, values)
    // .then(data => console.log(JSON.stringify(data))) // JSON-string from `response.json()` call
    // .then(data => console.log(JSON.stringify(data))) // JSON-string from `response.json()` call
    .catch(error => console.error(error));
};

const Error = ({ name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <span>{error}</span> : null
    }
  />
);

const required = value => (value ? undefined : 'Required');

export default class extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { props } = this;

    const {
      event,
      title,
      subtitle,
      banner,
      isOpen,
      closeModal,
      onAfterOpen,
      onRequestClose,
      onChangeInput,
    } = props;

    const formHiddenData = {
      formName: 'FormShareEvent',
      retURL: 'https://www.google.com/',
      referredBy: '',
      sharedEvent: event,
    };

    return (
      <StaticQuery
        query={graphql`
          query {
            allImageSharp {
              edges {
                node {
                  fluid(maxWidth: 2880, quality: 90) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                  }
                }
              }
            }
          }
        `}
        render={data => {
          // const cityBanner = data.allImageSharp.edges.find(
          //   edge =>
          //     edge.node.fluid.originalName ===
          //       `${props.event.city
          //         .toString()
          //         .replace(/\s+/g, '-')
          //         .toLowerCase()}.jpg` || null
          // );

          return (
            <Modal
              id="modalShareEvent"
              contentLabel="modalShareEvent"
              closeTimeoutMS={400}
              isOpen={isOpen}
              onAfterOpen={onAfterOpen}
              onRequestClose={onRequestClose}
            >
              <HeaderImage
                title={`${Config.brand} ${title}`}
                subtitle={subtitle}
                image={banner}
              />
              <button className="btn-close" onClick={closeModal}>
                X
              </button>

              <Container>
                <Wizard
                  initialValues={formHiddenData}
                  onSubmit={onSubmit}
                  validate={values => {
                    const errors = {};

                    if (!values.referrerName) {
                      errors.referrerName = 'Required';
                    }
                    if (!values.referrerEmail) {
                      errors.referrerEmail = 'Required';
                    } else if (!isEmail(values.referrerEmail)) {
                      errors.referrerEmail = 'Invalid email';
                    }

                    if (!values.firstName) {
                      errors.firstName = 'Required';
                    }
                    if (!values.lastName) {
                      errors.lastName = 'Required';
                    }
                    if (!values.email) {
                      errors.email = 'Required';
                    } else if (!isEmail(values.email)) {
                      errors.email = 'Invalid email';
                    }
                    return errors;
                  }}
                >
                  <Wizard.Page>
                    <Heading title="Share with:" />
                    <Input
                      name="firstName"
                      label="First Name"
                      placeholder="First Name"
                      type="text"
                    />
                    {/* <Error name="firstName" /> */}
                    <Input
                      name="lastName"
                      label="Last Name"
                      placeholder="Last Name"
                      type="text"
                    />
                    <Input
                      name="email"
                      label="Email"
                      placeholder="Email"
                      type="email"
                    />
                  </Wizard.Page>
                  <Wizard.Page>
                    <Heading title="Your Information:" />
                    <Input
                      name="referrerName"
                      label="Full Name"
                      placeholder="Full Name"
                      type="text"
                    />
                    <Input
                      name="referrerEmail"
                      label="Your Email"
                      placeholder="Your Email"
                      type="email"
                    />
                    <TextArea
                      name="message"
                      label="Notes"
                      placeholder="Notes"
                    />
                  </Wizard.Page>
                </Wizard>
              </Container>
            </Modal>
          );
        }}
      />
    );
  }
}
