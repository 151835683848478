import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import LinkedInIcon from 'Static/icons/linkedin.svg';

import { BackgroundImage, BackgroundVideo } from 'Components';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
  BrandedColor,
  Speakers,
  ActionLinkedin,
} from 'Styles';

const Wrapper = styled.div`
  color: ${props =>
    props.dark ? theme.colors.black.base : theme.colors.white.base};
  min-height: ${props => (props.big ? '400px;' : '100px;')};
  background: none;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    flex-direction: column;
    margin: 0;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  .gatsby-image-wrapper {
  }
`;

const Content = styled.div`
  display: block;
  margin: 0 auto;
  text-align: left;
  width: 100%;
  background-color: ${props =>
    props.dark ? theme.colors.black.base : theme.colors.white.base};
  color: ${props =>
    props.dark ? theme.colors.white.base : theme.colors.black.base};
  border-color: ${props =>
    props.dark ? theme.colors.white.base : theme.colors.black.base};
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const Grid = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${theme.layout.base};
`;

const Title = styled.h2`
  color: inherit;
  font-weight: bold;
  max-width: ${theme.layout.base};
  margin-left: auto;
  margin-right: auto;
  text-align: left;
`;
const Subtitle = styled.p`
  color: inherit;
  font-weight: bold;
  max-width: ${theme.layout.base};
  margin: 0 auto;
  & + a {
    margin-top: 1rem;
  }
  text-align: left;
`;

const Panel = ({
  big,
  dark,
  tag,
  panel,
  speakers,
  layout,
  image,
  subtitle,
  name,
  title,
  company,
  cta_action,
  cta_action_text,
  cta_url,
  cta_text,
  cta_url_1,
  cta_text_1,
  children,
}) => (
  <>
    <Wrapper>
      <Content>
        {/* <Zoom duration={600} delay={500}> */}
        <>
          <Title>
            The <BrandedColor>{tag}</BrandedColor>Panel
          </Title>
          <Subtitle>{subtitle}</Subtitle>
        </>
        {/* </Zoom> */}
      </Content>
      <Grid>
        <Speakers.Grid>
          {speakers.map((item, i) => (
            <div key={i}>
              {/* <Fade delay={i * 200}> */}
              <Speakers.Card>
                <Speakers.Speaker>
                  <>
                    <Speakers.Avatar
                      background={item.profile_image}
                      alt={`${item.name} - ${item.position} at ${
                        item.company_name
                      }`}
                      title={`${item.name} - ${item.position} at ${
                        item.company_name
                      }`}
                    >
                      <Speakers.Name>
                        {item.name && (
                          <ActionLinkedin
                            href={item.linkedin_link}
                            alt={`${item.name} - ${item.position} at ${
                              item.company_name
                            } on LinkedIn`}
                            target="_blank"
                          >
                            <span>
                              <img
                                src={LinkedInIcon}
                                alt=""
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  margin: '0',
                                }}
                              />
                            </span>{' '}
                            {`${item.name}`}
                          </ActionLinkedin>
                        )}
                      </Speakers.Name>
                    </Speakers.Avatar>

                    <Speakers.Details>
                      <Speakers.Title>{`${item.position}`}</Speakers.Title>
                      <Speakers.Company>{`${
                        item.company_name
                      }`}</Speakers.Company>
                    </Speakers.Details>
                  </>
                </Speakers.Speaker>
              </Speakers.Card>
              {/* </Fade> */}
            </div>
          ))}
        </Speakers.Grid>
      </Grid>
      <Content>
        {cta_action && cta_action_text && (
          <ActionButton onClick={cta_action}>{cta_action_text}</ActionButton>
        )}
        {cta_text && cta_url && (
          <ActionButton href={cta_url} target="_blank">
            {cta_text}
          </ActionButton>
        )}
        {cta_text_1 && cta_link_1 && (
          <ActionButton href={cta_link_1}>{cta_text_1}</ActionButton>
        )}
      </Content>
    </Wrapper>
  </>
);

export default Panel;
