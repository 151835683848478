import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme, mixins, media } from 'Styles';
const { colors, fontSize } = theme;

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  outline: 0;
  transition: ${theme.transition};
  transform: translateX(${props => (props.menuOpen ? 0 : 100)}vw);
  visibility: ${props => (props.menuOpen ? 'visible' : 'hidden')};
  display: none;
  ${media.desktop`display: block;`};
`;
const Sidebar = styled.div`
  ${mixins.flexCenter};
  flex-direction: column;
  // background-color: rgba(0, 0, 0, 0.9);
  width: 100vw;
  height: 100%;
  position: relative;
  right: 0;
  margin-left: auto;
  // font-family: .body};
  // ${media.thone`padding: 60px 0 25px;`};
  // ${media.phablet`padding: 60px 0 25px; width: 100vw; margin: 0; width: 100%;`};
  // ${media.tiny`padding: 10px; margin: 0; width: 100%;`};
  &:before {
    content: '';
    position: absolute;
    top: 72px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.95) 0%,
      rgba(0, 0, 0, 0.95) 100%
    );
  }
}
`;

const Nav = styled.nav`
  width: 100%;
  color: ${colors.white.base};
  z-index: 1;
`;

const NavLinks = styled(Nav)`
  ${mixins.flexBetween};
  flex-direction: column;
  text-align: center;
  color: black;
`;
const NavList = styled.ol`
  margin: 0;
  width: 100%;
`;
const NavListItem = styled.h1`
  margin: 0 auto 20px;
  position: relative;
  // border-bottom: 1px solid #333;
  color: ${colors.white.base};
`;
const NavLink = styled.a`
  ${mixins.link};
  padding: 12px 20px 12px;
`;

const Menu = ({ navLinks, menuOpen }) => {
  return (
    <MenuContainer
      menuOpen={menuOpen}
      aria-hidden={!menuOpen}
      tabIndex={menuOpen ? 1 : -1}
    >
      <Sidebar>
        <NavLinks>
          <NavList>
            {navLinks &&
              navLinks.map(({ url, name }, i) => (
                <li key={i}>
                  <NavListItem>
                    <NavLink href={url}>{name}</NavLink>
                  </NavListItem>
                </li>
              ))}
          </NavList>
        </NavLinks>
      </Sidebar>
    </MenuContainer>
  );
};

export default Menu;

Menu.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  navLinks: PropTypes.array.isRequired,
};

Menu.defaultProps = {
  menuOpen: false,
  navLinks: false,
};
