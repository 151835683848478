import styled from 'styled-components';
import { theme, mixins } from 'Styles';
const { colors, fontSize } = theme;

const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export default Container;
