import React from 'react';
import AddToCalendar from 'Components/react-add-to-calendar';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import Modal from 'Components/Modal';
import CalendarModal from 'Components/CalendarModal';
import Button from 'Components/Button';
import moment from 'moment';
const AddToCalendarModal = AddToCalendarHOC(Button, CalendarModal);
import './styles.scss';
import { css } from 'styled-components';

const componentStyles = css`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 0 0 30px;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const linkStyles = css`
  text-decoration: none;
  display: block;
  color: #e42d2d;
  font-size: 18px;
  text-align: center;
  padding: 6px;
`;

class EventListDescription extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      eventType,
      eventName,
      description,
      rating,
      eventURL,
      eventStart,
      eventEnd,
      eventStartDate,
      eventEndDate,
      event,
      eventCountry,
      eventState,
      eventCity,
      eventDate,
      share,
    } = this.props;

    let icon = { textOnly: 'none' };
    let items = [
      { outlook: 'Outlook' },
      { google: 'Google' },
      { apple: 'Apple Calendar' },
      { yahoo: 'Yahoo' },
    ];
    // moment(date).format('MMM')
    const startDatetime = moment(eventStartDate);
    const endDatetime = moment(eventEndDate);
    const duration = moment
      .duration(endDatetime.diff(startDatetime))
      .asHours()
      .toString();

    // 20190606T093000

    const eventData = {
      duration,
      startDatetime: startDatetime.format('YYYYMMDDTHHmmss'),
      endDatetime: endDatetime.format('YYYYMMDDTHHmmss'),
      title: `${eventName}, ${moment(eventStartDate).format('MMM Do Y')}`,
      description: `${description}`,
      location: `${eventCity}, ${eventState}, ${eventCountry}`,
      startTime: eventStart,
      endTime: eventEnd,
    };

    return (
      <>
        <div className="event-list-description-box">
          <div className="event-list-bottom">
            {share && (
              <button className="btn" onClick={share} event={event}>
                Share
              </button>
            )}
            {eventData && (
              <AddToCalendarModal
                className={'componentStyles'}
                buttonText="Add to Calendar"
                linkProps={{
                  className: 'btn',
                }}
                event={eventData}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default EventListDescription;
