import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme, mixins } from 'Styles';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 0;
  min-height: 160px;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    border-radius: ${theme.borderRadius.ui} ${theme.borderRadius.ui} 0 0;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  position: relative;
  overflow: hidden;
  align-items: flex-end;
  ${mixins.backgroundBefore};
`;

const Content = styled.div`
  color: ${theme.colors.white.base};
  font-weight: bold;
  z-index: 3;
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: ${theme.layout.base};
  padding: 0 2rem;
  align-items: flex-start;
`;

const Title = styled.h1`
  color: ${theme.colors.white.base};
  font-size: ${theme.fontSize.h3};
  font-weight: bold;
  max-width: ${theme.layout.base};
`;

const Subtitle = styled.p`
  max-width: 650px;
  font-weight: normal;
  font-size: ${theme.fontSize.small};
  font-weight: bold;
  color: ${theme.colors.white.light};
`;

const StyledImg = styled(Img)`
  position: initial !important;
  img {
    filter: blur(15px);
  }
`;

const HeaderImage = ({ title, subtitle, image, sponsor }) => (
  <Wrapper>
    {image && !sponsor && (
      <>
        <Content>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Content>
        <Img
          alt={title}
          title={title}
          fluid={image.childImageSharp.fluid || {} || [] || ''}
          style={{ position: 'initial !important' }}
        />
      </>
    )}
    {image && sponsor && (
      <>
        <Content style={{ alignItems: 'center', width: '100%' }}>
          <Title style={{ marginBottom: '2rem' }}>{title}</Title>
        </Content>
        <StyledImg
          alt={title}
          title={title}
          fluid={image.childImageSharp.fluid || {} || [] || ''}
        />
      </>
    )}
  </Wrapper>
);

export default HeaderImage;

HeaderImage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  image: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

HeaderImage.defaultProps = {
  children: false,
  image: false,
  title: false,
};
