import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BackgroundImage, BackgroundVideo } from 'Components';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  Title,
  Subtitle,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
  Wrapper,
} from 'Styles';

// const Wrapper = styled.div`
//   color: ${props =>
//     props.dark ? theme.colors.black.base : theme.colors.white.base};
//   min-height: ${props => (props.big ? '400px;' : '100px;')};
//   background: none;
//   position: relative;
//   overflow: hidden;
//   display: flex;
//   flex-direction: column-reverse;

//   align-items: center;
//   margin: 0 auto;
//   @media (min-width: ${theme.breakpoints.s}) {
//   }
//   @media (min-width: ${theme.breakpoints.m}) {
//     flex-direction: row;
//     flex-direction: ${props => (props.layout == 'ltr' ? `row` : `row-reverse`)};
//     margin: 0 auto 0;
//   }
//   @media (min-width: ${theme.breakpoints.l}) {
//   }
//   .gatsby-image-wrapper {
//     position: initial !important;
//   }
// `;

const Content = styled.div`
  width: 100%;
  padding: 3rem 0;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const Grid = styled.div`
  padding: 2rem 0 0;
  margin: 0 auto;
  width: 100%;
  max-width: ${theme.layout.base};
`;

const OfficesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
`;

const OfficeTitle = styled.h4`
  color: ${theme.colors.black.base};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
`;

const OfficeLocation = styled.div`
  display: flex;
  color: ${theme.colors.black.base};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
  margin: 0;
  img {
    height: 16px;
    width: auto;
    margin: 0 6px 0 0;
  }
`;

const OfficeType = styled.h5`
  color: ${theme.colors.black.base};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
  margin: 0;
`;

const OfficeAddress = styled.h5`
  color: ${theme.colors.black.base};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
  margin: 0;
`;

const Offices = ({
  big,
  dark,
  layout,
  image,
  title,
  subtitle,
  copy,
  cta_action,
  cta_action_text,
  cta_url,
  cta_text,
  cta_url_1,
  cta_text_1,
  children,
  data,
}) => (
  <Wrapper layout={layout} dark={dark} big={big}>
    <FullWrapper>
      <Content>
        <Title>Our Offices</Title>
        <hr />
        <Grid>
          <OfficesGrid>
            {data.map((office, i) => (
              <div key={i}>
                <OfficeLocation>
                  <img src={office.flag} width="16" />
                  {office.location}
                </OfficeLocation>
                <hr />
                <OfficeTitle>{office.type}</OfficeTitle>
                {/* <OfficeType>{office.type}</OfficeType> */}
                <OfficeAddress>{office.address}</OfficeAddress>
                <br />
              </div>
            ))}
          </OfficesGrid>
        </Grid>
      </Content>
    </FullWrapper>
  </Wrapper>
);

export default Offices;
