import React from 'react';
import Modal from 'react-modal';
import { Form, Field } from 'react-final-form';
import isEmail from 'validator/lib/isEmail';
import { compose, withProps } from 'recompose';
import styled, { css } from 'styled-components';
import { theme, mixins, StyledForm } from 'Styles';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import {
  Hero,
  HeroBig,
  PostList,
  HeroVideo,
  HeaderImage,
  BackgroundVideo,
  SectionBackground,
  EventCalendarCard,
  EventCalendarList,
} from 'Components';

import {
  Wizard,
  Input,
  TextArea,
  Heading,
  Spacer,
} from 'Components/FormElements';

const Container = styled.div`
  padding: 2rem;
`;

const Logo = styled.div`
  margin-top: -3rem;
  background: #fff;
  border-radius: ${theme.borderRadius.ui};
  box-shadow: ${theme.shadow.feature.small.default};
  border: 1px solid #ddd;
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 1rem;
  margin-bottom: 2rem;
  @media (min-width: ${theme.breakpoints.s}) {
    align-items: center;
  }
`;

function postData(url = ``, data = {}) {
  return fetch(url, {
    method: 'POST',
    mode: 'no-cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  })
    .then(response => {
      // console.log(response);
      response
        .json()
        .then(data => {
          console.log(data.retURL);
          if (data.retURL) {
            window.location = data.retURL;
          } else {
            alert(data.msg);
          }
        })
        .catch(function(e) {
          console.log('handled the error');
        });
    })
    .catch(error => console.error('Error:', error));
}

const API_ENDPOINT = '/.netlify/functions/referee';

const onSubmit = async values => {
  // JSON.stringify(values, 0, 2)
  postData(API_ENDPOINT, values)
    // .then(data => console.log(JSON.stringify(data))) // JSON-string from `response.json()` call
    // .then(data => console.log(JSON.stringify(data))) // JSON-string from `response.json()` call
    .catch(error => console.error(error));
};

const Error = ({ name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <span>{error}</span> : null
    }
  />
);

const required = value => (value ? undefined : 'Required');

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sponsorData: {
        name: '',
        website: '',
      },
    };

    // const {
    //   sponsor,
    //   title,
    //   subtitle,
    //   banner,
    //   isOpen,
    //   closeModal,
    //   onAfterOpen,
    //   onRequestClose,
    //   onChangeInput,
    // } = props;
    // this.sponsorData = {
    //   sponsorName: sponsor.name,
    //   sponsorWebsite: sponsor.website,
    // };
  }

  // componentDidMount() {
  //   this.setState({
  //     sponsorData: {
  //       name: 'AAAAAA',
  //       website: this.state.sponsorData.website,
  //       // sponsorWebsite: 'bb',
  //     },
  //   });
  // }

  render() {
    const props = this.props;

    const {
      sponsor,
      title,
      subtitle,
      banner,
      isOpen,
      closeModal,
      onAfterOpen,
      onRequestClose,
      onChangeInput,
    } = props;

    // const { name: sponsorName } = sponsor;

    // sponsorData = {
    //   website: sponsor.website,
    //   name: sponsor.name,
    // };
    let formHiddenData;
    if (sponsor) {
      formHiddenData = {
        formName: 'FormContactSponsor',
        retURL: '',
        sponsor,
      };
    }

    return (
      <Modal
        id="modalContactSponsor"
        contentLabel="modalContactSponsor"
        closeTimeoutMS={400}
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onRequestClose={onRequestClose}
        // data={sponsor}
      >
        <HeaderImage
          title={sponsor ? sponsor.displayName : ''}
          subtitle={sponsor ? sponsor.website : ''}
          image={sponsor ? sponsor.logo : ''}
          sponsor={true}
        />
        <button className="btn-close" onClick={closeModal}>
          X
        </button>

        <Container>
          {sponsor && (
            <>
              <Logo>
                <Img
                  alt={sponsor ? sponsor.displayName : ''}
                  title={sponsor ? sponsor.displayName : ''}
                  fixed={sponsor ? sponsor.logo.childImageSharp.fixed : ''}
                />
              </Logo>
              <Heading title="Lorem ipsum" />
              <p>
                <small>
                  Trend Micro Incorporated, a global leader in security software
                  and solutions, strives to make the world safe for exchanging
                  digital information. Our innovative security solutions for
                  consumers, businesses and governments provide layered content
                  security to protect information on mobile devices, endpoints,
                  gateways, servers and the cloud. All solutions are powered by
                  cloud-based global threat intelligence, the Trend Micro™ Smart
                  Protection Network™, and are supported by over 1,200 threat
                  experts around the globe. TODO
                </small>
              </p>
              <p>
                <small>
                  Learn more at{' '}
                  <a href={sponsor ? sponsor.website : ''} target="_blank">
                    {sponsor ? sponsor.website : ''}
                  </a>
                </small>
              </p>
              <hr />
            </>
          )}
          <Wizard
            initialValues={formHiddenData}
            onSubmit={onSubmit}
            validate={values => {
              const errors = {};

              if (!values.message) {
                errors.message = 'Required';
              }
              if (!values.firstName) {
                errors.firstName = 'Required';
              }
              if (!values.lastName) {
                errors.lastName = 'Required';
              }
              if (!values.email) {
                errors.email = 'Required';
              } else if (!isEmail(values.email)) {
                errors.email = 'Invalid email';
              }
              return errors;
            }}
          >
            {/* <Wizard.Page>
              <Heading title={`Got a question or inquiry?`} />
              <TextArea name="message" label="Message" placeholder="Message" />
            </Wizard.Page> */}
            <Wizard.Page>
              <Heading title="Get in touch:" />
              <StyledForm>
                <Input
                  name="firstName"
                  label="First Name"
                  placeholder="First Name"
                  type="text"
                  className="firstNameElement"
                />
                <Input
                  name="lastName"
                  label="Last Name"
                  placeholder="Last Name"
                  type="text"
                  className="lastNameElement"
                />
                <Input
                  name="email"
                  label="Email"
                  placeholder="Email"
                  type="email"
                  className="emailElement"
                />
                <TextArea
                  name="message"
                  label="Message"
                  placeholder="Message"
                  type="text"
                  className="messageElement"
                />
              </StyledForm>
            </Wizard.Page>
          </Wizard>
        </Container>
      </Modal>
    );
  }
}
