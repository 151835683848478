import styled from 'styled-components';
import { theme, mixins } from 'Styles';

const ActionButton = styled.a`
  color: ${theme.colors.white.base};
  font-size: ${theme.fontSize.smallish};
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  display: block;
  border-radius: ${theme.borderRadius.elements};
  padding: 6px 18px;
  background: ${theme.colors.primary.base};
  border-width: 1px;
  border-color: ${theme.colors.primary.base};
  border-style: solid;
  &:hover,
  &:focus {
    color: ${theme.colors.white.base};
    background: ${theme.colors.primary.dark};
    border-color: ${theme.colors.primary.base};
    outline: 0;
  }
  & + button,
  & + a {
    margin: 1rem 0 0 0;
  }
  @media (min-width: ${theme.breakpoints.s}) {
    & + button,
    & + a {
      margin: 1rem 0 0 0;
    }
  }
  @media (min-width: ${theme.breakpoints.m}) {
    display: inline-block;
    text-align: left;
    & + button,
    & + a {
      margin: 0 0 0 8px;
    }
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

export default ActionButton;
