import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'Styles';

const Wrapper = styled.section`
  // text-align: ${props => (props.center ? 'center' : '')};
  margin: auto;
  padding: 4rem 1rem;
  // width: 100%;
  max-width: ${theme.layout.base};

  // max-width: ${theme.layout[props.type]};
  height: 100%;
  flex: 1;

  // @media (max-width: ${theme.breakpoints.m}) {
  //   width: 90%;
  // }

  // @media (max-width: ${theme.breakpoints.s}) {
  //   width: 95%;
  // }
`;

const Container = ({ children, type, className, center }) => (
  <Wrapper className={className} type={type} center={center}>
    {children}
  </Wrapper>
);

export default Container;

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  center: PropTypes.object,
};
