import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { BackgroundVideo } from 'Components';
import BackgroundImage from 'gatsby-background-image';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  Title,
  Subtitle,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
} from 'Styles';

const TextBlock = styled.div`
  text-align: left;
  width: 100%;
`;

const BackgroundSection = styled.section`
  width: 100%;
  color: ${props =>
    props.dark ? theme.colors.black.base : theme.colors.white.base};
  // min-height: ${props => (props.big ? '300px;' : '100px;')};
  // background-image: url('${props => props.image.src}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  padding: 2rem 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(200px, 240px);
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    padding: 4rem 2rem;
    grid-template-columns: 400px 1fr;
    grid-template-rows: minmax(200px, 240px);
  }
  @media (min-width: ${theme.breakpoints.l}) {
    padding: 4rem 2rem;
  }
  > div {
    z-index: 10;
  }
  // margin: 0 auto 3rem;
  ${props => (props.dark ? '' : mixins.backgroundBefore)};
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  border-radius: ${theme.borderRadius.ui};
  z-index: 1;
`;

const Content = styled.div`
  color: inherit;
  max-width: ${theme.layout.base};
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: ${theme.fontSize.large};
  margin: 0;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    flex-direction: row;
    margin-left: 2rem;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const SectionVenue = ({
  big,
  dark,
  event,
  image,
  venue,
  title,
  subtitle,
  cta_action,
  cta_action_text,
  cta_url,
  cta_text,
  cta_url_1,
  cta_text_1,
  children,
}) => (
  <BackgroundSection image={image} dark={dark} big={big}>
    <StyledBackgroundImage
      Tag="section"
      fluid={venue}
      backgroundColor={`rgba(0,0,0,0.2)`}
    />

    {/* {venue && <BackgroundImage title={title} image={venue} />} */}
    <Content>
      <Fade right>
        <>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <div>
            {cta_text && cta_url && (
              <ActionButton href={cta_url}>{cta_text}</ActionButton>
            )}
            {cta_text_1 && cta_url_1 && (
              <ActionButton href={cta_url_1}>{cta_text_1}</ActionButton>
            )}
            {cta_action && cta_action_text && (
              <ActionButton onClick={cta_action}>
                {cta_action_text}
              </ActionButton>
            )}
          </div>
          {children && children}
        </>
      </Fade>
    </Content>
  </BackgroundSection>
);

export default SectionVenue;
