import styled from 'styled-components';
import theme from 'Theme';
const { colors, fontSize } = theme;

const SliderContent = styled.div`
  max-width: ${theme.layout.base};
  margin: 0 auto;
`;

export default SliderContent;
