import { css } from 'styled-components';
import { theme } from 'Styles';
import media from './media';
import mixins from './mixins';

const AddToCalendarStyles = css`
  .react-add-to-calendar {
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    position: initial;
    display: inline-block;
    .react-add-to-calendar__wrapper {
      position: relative;
      zoom: 1;
      cursor: pointer;
      .react-add-to-calendar__button {
        padding: 10px;
        background-color: #f9f9f9;
        border: 1px solid #aab9d4;
        border-radius: 3px;
        color: #000;
      }
      .react-add-to-calendar__button--light {
        background-color: #fff;
      }
    }
    .react-add-to-calendar__dropdown {
      position: absolute;
      z-index: 999;
      top: auto;
      bottom: 44px;
      max-width: 100%;
      right: 8px;
      left: 8px;
      @media (min-width: ${theme.breakpoints.s}) {
        top: 30px;
        bottom: auto;
        right: 1rem;
        left: auto;
        max-width: 135px;
      }
      @media (min-width: ${theme.breakpoints.m}) {
      }
      @media (min-width: ${theme.breakpoints.l}) {
      }

      padding: 5px 0 5px 8px;
      box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
      border: 1px solid #a8a8a8;
      background-color: #fff;
      text-align: left;
      margin-top: 2px;

      border-radius: ${theme.borderRadius.ui};
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
      border: 1px solid #2c3e50;
      background-color: #fff;
      text-align: left;
      font-size: 10px;
      padding: 4px;
      ul {
        list-style: none;
        margin: 0;
        li {
          &:hover {
            background: #ddd;
            border-radius: ${theme.borderRadius.ui};
          }
          button,
          a {
            padding: 8px 8px;
            display: block;
            background: none;
            width: 100%;
          }
        }
      }
    }
  }
`;

export default AddToCalendarStyles;
