import React from 'react';
import styled, { css } from 'styled-components';

const buttonClassName = css`
  border: 1px solid #e5e5e5;
  color: #e42d2d;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 3px;
  width: 300px;
  height: 50px;
  cursor: pointer;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }
`;

export default function Button({ children, onClick }) {
  return (
    <button className={'btn'} onClick={onClick} style={{ width: '100%' }}>
      {children}
    </button>
  );
}
