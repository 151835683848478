import React from 'react';
import { Form, Field } from 'react-final-form';
export class TextArea extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Field name={this.props.name}>
        {({ input, meta }) => (
          <div className={this.props.className}>
            <label htmlFor={`_${this.props.name}_`}>{this.props.label}</label>
            <textarea
              {...input}
              id={`_${this.props.name}_`}
              type={this.props.type}
              name={this.props.name}
              placeholder={this.props.placeholder}
              className={`${
                meta.error && meta.touched ? 'form-error' : 'form-valid'
              }`}
              onBlur={this.props.onBlur}
            />
            {meta.error && meta.touched && <span>{meta.error}</span>}
          </div>
        )}
      </Field>
    );
  }
}

export default TextArea;
