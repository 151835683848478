import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
const googleMapKey = 'AIzaSyBSaiFWGy04MmoZJKj8U7TXExqgN9-W_00';

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  height: 500px;
`;

// InfoWindow component
const InfoWindow = props => {
  const { place } = props;
  const infoWindowStyle = {
    position: 'relative',
    bottom: 150,
    left: '-45px',
    width: 220,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 10,
    fontSize: 14,
    zIndex: 100,
  };

  return (
    <div style={infoWindowStyle}>
      <div style={{ fontSize: 16 }}>{place.name}</div>
      <div style={{ fontSize: 14 }}>
        <span style={{ color: 'grey' }}>{place.rating} </span>
        <span style={{ color: 'orange' }}>
          {String.fromCharCode(9733).repeat(Math.floor(place.rating))}
        </span>
        <span style={{ color: 'lightgrey' }}>
          {String.fromCharCode(9733).repeat(5 - Math.floor(place.rating))}
        </span>
      </div>
      <div style={{ fontSize: 14, color: 'grey' }}>{place.types[0]}</div>
      <div style={{ fontSize: 14, color: 'grey' }}>
        {'$'.repeat(place.price_level)}
      </div>
      <div style={{ fontSize: 14, color: 'green' }}>
        {place.opening_hours.open_now ? 'Open' : 'Closed'}
      </div>
    </div>
  );
};

// Marker component
const Marker = props => {
  const markerStyle = {
    border: '1px solid white',
    borderRadius: '50%',
    height: 10,
    width: 10,
    backgroundColor: 'blue',
    // backgroundColor: props.show ? 'red' : 'blue',
    cursor: 'pointer',
    zIndex: 10,
  };

  return (
    <>
      <div style={markerStyle} />
      {props.show && <InfoWindow place={props.place} />}
    </>
  );
};

class GoogleMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = { places: this.places };
  }

  // onChildClickCallback = key => {
  //   this.setState(state => {
  //     const index = state.places.findIndex(e => e.id === key);
  //     state.places[index].show = !state.places[index].show; // eslint-disable-line no-param-reassign
  //     return { places: state.places };
  //   });
  // };

  render() {
    const { children, ...props } = this.props;

    return (
      <Wrapper>
        <GoogleMapReact
          onChildClick={props.onChildClickCallback}
          bootstrapURLKeys={{
            key: googleMapKey,
          }}
          {...props}
        >
          {props.places.map((place, i) => (
            // <Marker
            //   key={place.id}
            //   lat={place.geometry.location.lat}
            //   lng={place.geometry.location.lng}
            //   show={place.show}
            //   place={place}
            // />
            <Marker
              key={place.id}
              lat={place.latitude}
              lng={place.longitude}
              show={false}
              place={place}
            />
          ))}
          {children}
        </GoogleMapReact>
      </Wrapper>
    );
  }
}

GoogleMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

GoogleMap.defaultProps = {
  children: null,
};

export default GoogleMap;
