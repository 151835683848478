import styled from 'styled-components';
import theme from 'Theme';
const { colors, fontSize } = theme;

const BrandedColor = styled.span`
  color: ${theme.colors.primary.base};
  font-weight: bold;
`;

export default BrandedColor;
