import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  Title,
  Subtitle,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
} from 'Styles';

import {
  FormAttend,
  BackgroundImage,
  BackgroundVideo,
  EventCalendarCard,
  EventCalendarList,
  Hero,
  HeroBig,
  HeroVideo,
  Logo,
  Modal,
  PostList,
  SectionBackground,
  SectionCallout,
  FormShareEvent,
  FormSubscribe,
} from 'Components';

import Config from 'Data';
import { Link } from 'gatsby';

const { colors, fontSize } = theme;

const Wrapper = styled.section`
  background: #fff;
  border-radius: ${theme.borderRadius.ui};
  box-shadow: ${theme.shadow.feature.small.default};
  border: 1px solid #ddd;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 2rem;
  flex-direction: column-reverse;

  @media (min-width: ${theme.breakpoints.s}) {
    padding: 2rem;
  }

  @media (min-width: ${theme.breakpoints.m}) {
    flex-direction: column;
    margin: 0 2rem 3rem;
  }

  @media (min-width: ${theme.breakpoints.l}) {
    margin: 0 auto 3rem;
  }

  max-width: ${theme.layout.base};
  // ${mixins.hairLineUnder};
`;

const Content = styled.div`
  display: block;
  padding: 2rem;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  background-color: ${props =>
    props.dark ? theme.colors.black.base : theme.colors.background.light};
  color: ${props =>
    props.dark ? theme.colors.white.base : theme.colors.black.base};
  border-color: ${props =>
    props.dark ? theme.colors.white.base : theme.colors.black.base};
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

class PageSection extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {}

  render() {
    const {
      title,
      copy,
      image,
      cta_text,
      cta_link,
      cta_text_1,
      cta_link_1,
    } = this.props;

    return (
      <Wrapper>
        <BackgroundImage title={title} image={image} />
        <Text layout="rtl">
          <Title>{title}</Title>
          <Subtitle>{copy}</Subtitle>
          {cta_text && cta_link && (
            <ActionButtonAlt href={cta_link}>{cta_text}</ActionButtonAlt>
          )}
          {cta_text_1 && cta_link_1 && (
            <ActionButtonAlt href={cta_link_1}>{cta_text_1}</ActionButtonAlt>
          )}
        </Text>
      </Wrapper>
    );
  }
}

export default PageSection;
