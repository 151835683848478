import styled from 'styled-components';
import { theme, mixins } from 'Styles';

const CalendarButton = styled.button`
  color: inherit;
  font-size: ${theme.fontSize.small};
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  // display: block;
  // width: 100%;
  border-radius: ${theme.borderRadius.elements};
  padding: 6px 18px;
  background: white;
  border-width: 1px;
  border-color: inherit;
  border-style: solid;
  &.active {
    color: #fff;
    background: #b1000c;
    border-color: #b1000c;
    outline: 0;
  }
  &:hover,
  &:focus {
    color: ${theme.colors.white.base};
    background: ${theme.colors.primary.base};
    border-color: ${theme.colors.primary.base};
    outline: 0;
  }
  & + button,
  & + a {
    margin: 0 0 8px 0;
    // margin-left: 0 !important;
  }
  @media (min-width: ${theme.breakpoints.s}) {
    display: inline-block;
    width: auto;
    & + button,
    & + a {
      margin: 0 0 8px 0;
    }
  }
  @media (min-width: ${theme.breakpoints.m}) {
    display: inline-block;
    text-align: left;
    & + button,
    & + a {
      margin: 0 0 0 8px;
    }
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

export default CalendarButton;
