import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Countdown from 'Components/Countdown';
import moment from 'moment';
import YouTube from '@u-wave/react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

import {
  GlobalStyle,
  theme,
  mixins,
  ActionButton,
  CenterSection,
  BrandedColor,
} from 'Styles';

// import { Countdown } from 'Componenets';

const Wrapper = styled.header`
  background: none;
  min-height: 100vh;
  align-items: flex-end;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    min-height: ${props => (props.height ? `${props.height}vh` : `70vh`)};
    align-items: center;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }

  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  ${mixins.backgroundBefore};
`;

const Content = styled.div`
  color: ${theme.colors.white.base};
  height: 100%;
  width: 100%;
  max-width: ${theme.layout.base};
  position: relative;
  z-index: 3;
  padding: 0 1rem;
  margin: 2rem 0;
  @media (min-width: ${theme.breakpoints.s}) {
    padding: 0 2rem;
  }
  @media (min-width: ${theme.breakpoints.m}) {
    padding: 0 2rem;
    margin: 5rem 0 5rem;
  }
  @media (min-width: ${theme.breakpoints.l}) {
    margin: 5rem auto ${props => (props.featured ? `5rem` : `5rem`)};
  }
`;

const Title = styled.h1`
  color: ${theme.colors.white.base};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: start;
  line-height: 20px;
`;

const Subtitle = styled.p`
  max-width: ${theme.breakpoints.s};
  font-weight: normal;
  font-size: ${theme.fontSize.h6};
  font-weight: bold;
  color: ${theme.colors.white.base};
`;

const OnePageHeroWrapper = styled.div`
  color: ${theme.colors.white.base};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: start;
  line-height: 20px;
`;

const OnePageHeroTitle = styled.div`
  color: ${theme.colors.white.base};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: start;
  line-height: 20px;
`;

const OnePageHeroContent = styled.div`
  color: ${theme.colors.white.base};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: start;
  line-height: 20px;
  p:last-child {
    margin: 0;
  }
`;

const Brand = styled.h1`
  color: inherit;
  font-size: 19px;
  font-weight: bold;
  width: 100%;
  max-width: 1140px;
  background: #b1000c;
  width: auto;
  padding: 10px;
  border-radius: 6px;
  color: #fff;
  margin: 0;
`;

const City = styled.h1`
  color: inherit;
  font-size: ${theme.fontSize.hero};
  font-weight: bold;
  max-width: ${theme.layout.base};
`;

const OnePageHero = ({
  children,
  brand,
  event_start,
  event_end,
  event_start_datetime,
  event_end_datetime,
  date,
  city,
  cta_text,
  cta_url,
  image,
  vimeoId,
  youtubeId,
}) => (
  <Wrapper className="onePageHeroWrapper">
    {vimeoId && !youtubeId && (
      <div className="video-background">
        <div className="video-foreground">
          <Vimeo
            video={vimeoId}
            width={640}
            height={480}
            autoplay
            volume={0}
            loop={true}
            background={true}
            controls={false}
            className="video-iframe"
          />
        </div>
      </div>
    )}

    {!vimeoId && youtubeId && (
      <div className="video-background">
        <div className="video-foreground">
          <YouTube
            video={youtubeId}
            width={640}
            height={480}
            autoplay
            volume={0}
            loop={1}
            background={true}
            controls={false}
            className="video-iframe"
            showRelatedVideos={false}
          />
        </div>
      </div>
    )}

    {image && <Img fluid={image || {} || [] || ''} />}

    <Content>
      <OnePageHeroTitle>
        <BrandedColor>
          <Brand>{brand}</Brand>
        </BrandedColor>
        <City>{city}</City>
      </OnePageHeroTitle>
      <p>{`
        ${moment(event_start_datetime).format('MMMM Do, YYYY')}
        `}</p>
      <p>
        {event_start} - {event_end}
      </p>

      {/* {moment(event_start).isSameOrAfter(new Date()) && ( */}
      <>{cta_text && <ActionButton href={cta_url}>{cta_text}</ActionButton>}</>
      {/* )} */}
      {/* {console.log(new Date())}
      {console.log(event_start)}
      {console.log(
        'isSameOrBefore: ',
        moment(event_start).isSameOrAfter(new Date())
      )} */}

      {moment(event_start).isSameOrAfter(new Date()) && (
        <>
          <br />
          <Fade bottom duration={600} delay={2000}>
            <>
              <Countdown
                className="event-list-countdown"
                timeTillDate={event_start}
                timeFormat="MM DD YYYY, h:mm a"
              />
            </>
          </Fade>
        </>
      )}

      {/* <p>{children && <Subtitle>{children}</Subtitle>}</p> */}
    </Content>
  </Wrapper>
);

export default OnePageHero;

OnePageHero.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

OnePageHero.defaultProps = {
  children: false,
  image: false,
  title: false,
};
