import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Fade from 'react-reveal/Fade';
import YouTube from '@u-wave/react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import {
  theme,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
} from 'Styles';

const Wrapper = styled.header`
  background: none;
  min-height: 100vh;
  align-items: flex-end;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    min-height: ${props => (props.height ? `${props.height}vh` : `50vh`)};
    align-items: center;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }

  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  ${mixins.backgroundBefore};
`;

const Content = styled.div`
  color: ${theme.colors.white.base};
  height: 100%;
  width: 100%;
  max-width: ${theme.layout.base};
  position: relative;
  z-index: 3;
  padding: 0 1rem;
  margin: 2rem 0;
  @media (min-width: ${theme.breakpoints.s}) {
    padding: 0 2rem;
  }
  @media (min-width: ${theme.breakpoints.m}) {
    padding: 0 2rem;
    margin: 5rem 0 5rem;
  }
  @media (min-width: ${theme.breakpoints.l}) {
    margin: 5rem auto ${props => (props.featured ? `5rem` : `5rem`)};
  }
`;

const Title = styled.h1`
  color: ${theme.colors.white.base};
  font-size: ${theme.fontSize.hero};
  text-align: center;
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
`;

const Subtitle = styled.p`
  max-width: ${theme.breakpoints.s};
  font-weight: normal;
  text-align: center;
  margin: 0 auto;
  font-size: ${theme.fontSize.h6};
  font-weight: bold;
  color: ${theme.colors.white.base};
`;

const HeroActions = styled.div`
  text-align: center;
  margin: 1rem auto 0;
`;

const StyledImg = styled(Img)`
  ${props =>
    props.animated
      ? `@keyframes zoomAnimation {
          50% {
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
            -o-transform: scale(1.2);
            transform: scale(1.2);
          }
        }
        @media (min-width: ${theme.breakpoints.l}) {
          img {
            animation: zoomAnimation 60s linear infinite;
          }
        }`
      : ``}
`;

const Hero = ({
  height,
  title,
  subtitle,
  children,
  cta_text1,
  cta_text2,
  cta_url,
  cta_action1,
  cta_action2,
  image,
  youtubeId,
  vimeoId,
  featured,
  animated,
}) => (
  <Wrapper height={height}>
    {vimeoId && !youtubeId && (
      <div className="video-background">
        <div className="video-foreground">
          <Vimeo
            video={vimeoId}
            width={640}
            height={480}
            autoplay
            volume={0}
            loop={true}
            background={true}
            controls={false}
            className="video-iframe"
          />
        </div>
      </div>
    )}

    {!vimeoId && youtubeId && (
      <div className="video-background">
        <div className="video-foreground">
          <YouTube
            video={youtubeId}
            width={640}
            height={480}
            autoplay
            volume={0}
            loop={1}
            background={true}
            controls={false}
            className="video-iframe"
            showRelatedVideos={false}
          />
        </div>
      </div>
    )}
    {image && (
      <StyledImg
        alt={title}
        title={title}
        fluid={image.childImageSharp.fluid || {} || [] || ''}
        animated={animated}
      />
    )}
    <Content featured={featured}>
      <Title>
        <Fade top delay={600}>
          {title}
        </Fade>
      </Title>

      <Fade top delay={400}>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Fade>
      {children && children}
      <HeroActions>
        {cta_action1 && (
          <ActionButtonAlt onClick={cta_action1}>{cta_text1}</ActionButtonAlt>
        )}
        {cta_action2 && (
          <ActionButtonAlt onClick={cta_action2}>{cta_text2}</ActionButtonAlt>
        )}
        {cta_url && cta_text && (
          <ActionButtonAlt href={cta_url} target="_blank">
            {cta_text2}
          </ActionButtonAlt>
        )}
      </HeroActions>
    </Content>
  </Wrapper>
);

export default Hero;

Hero.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

Hero.defaultProps = {
  children: false,
  image: false,
  title: false,
};
