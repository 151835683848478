import styled from 'styled-components';
import theme from 'Theme';
const { colors, fontSize } = theme;

const Subtitle = styled.p`
  color: inherit;
  font-weight: normal;
`;

export default Subtitle;
