import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import Modal from 'react-modal';
import { Form, Field } from 'react-final-form';
import isEmail from 'validator/lib/isEmail';
import styled, { css } from 'styled-components';
import { theme, mixins, StyledForm } from 'Styles';
import Config from 'Data';
const { colors, fontSize, fontFamily } = theme;
import toaster from 'toasted-notes';
import { HeaderImage } from 'Components';
import { companySize, revenueSize, industries, jobFunctions } from './data';
import {
  Input,
  TextArea,
  StyledSelect,
  QuerySelect,
  Heading,
  Spacer,
} from 'Components/FormElements';
import StepWizard from 'react-step-wizard';

const Container = styled.div`
  padding: 1rem;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    padding: 2rem;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const PrivacyLink = styled(Link)`
  font-size: ${fontSize.smallish};
  padding: 1rem 0;
`;

const windowGlobal = typeof window !== 'undefined' && window;

function postData(url = ``, data = {}) {
  return fetch(url, {
    method: 'POST',
    mode: 'no-cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  })
    .then(response => {
      response
        .json()
        .then(data => {
          console.log(data.retURL);
          if (data.retURL) {
            windowGlobal.location = data.retURL;
          } else {
            toaster.notify('Thank You! Please check your Email.', {
              position: 'top',
              duration: 2000,
            });
          }
        })
        .catch(function(e) {
          console.error('handled the error');
        });
    })
    .catch(error => console.error('Error:', error));
}

const onSubmit = async values => {
  console.log(JSON.stringify(values, 0, 2));
  postData('/.netlify/functions/referee', values).catch(error =>
    console.error(error)
  );
};

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateForm = (key, value) => {
    // console.log('updateFormupdateFormupdateForm');
    // const { form } = this.state;
    // form[key] = value;
    // this.setState({ form });
  };

  render() {
    const { props } = this;
    const {
      brand,
      title,
      subtitle,
      banner,
      isOpen,
      closeModal,
      onAfterOpen,
      onRequestClose,
      onChangeInput,
    } = props;

    let formData = {
      formName: 'FormAttend',
      origin: windowGlobal ? windowGlobal.location.href : '',
      retURL: '',
      country: windowGlobal ? windowGlobal.country : '',
      brand: Config.brand,
      template: 'cxo-pages-lead-thank-you',
      mailingList: 'attend_secureciso',
    };

    const setCookie = (cname, cvalue, exdays) => {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var expires = 'expires=' + d.toUTCString();
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    };

    const getCookie = cname => {
      var name = cname + '=';
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    };

    if (windowGlobal) {
      var pairs = windowGlobal.location.search.slice(1).split('&');
      var result = {};
      pairs.forEach(function(pair) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
      });
      var referrer = getCookie(`${Config.brand}_referrer`);
      if (!referrer && !result.rep) {
        setCookie(`${Config.brand}_referrer`, '', 365);
      } else if (!referrer && result.rep) {
        setCookie(`${Config.brand}_referrer`, result.rep, 365);
        formData.rep = result.rep;
      } else if (referrer && !result.rep) {
        formData.rep = referrer;
      } else {
        setCookie(`${Config.brand}_referrer`, result.rep, 365);
        formData.rep = result.rep;
      }
    }

    const handleChange = e => {
      formData[e.target.name] = e.target.value;
      this.setState(formData);
    };

    const required = value => (value ? undefined : 'Required');

    return (
      <Modal
        id="modalAttend"
        contentLabel="modalAttend"
        closeTimeoutMS={400}
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onRequestClose={onRequestClose}
      >
        <HeaderImage title={title} image={banner} />
        <button className="btn-close" onClick={closeModal}>
          X
        </button>

        <Container>
          <Form
            initialValues={formData}
            onSubmit={onSubmit}
            validate={values => {
              const errors = {};
              if (!values.firstName) {
                errors.firstName = 'Required';
              }
              if (!values.lastName) {
                errors.lastName = 'Required';
              }
              if (!values.email) {
                errors.email = 'Required';
              } else if (!isEmail(values.email)) {
                errors.email = 'Invalid email';
              }
              if (!values.company) {
                errors.company = 'Required';
              }
              return errors;
            }}
            render={({
              form,
              handleSubmit,
              reset,
              submitting,
              pristine,
              values,
            }) => (
              <>
                <StyledForm onSubmit={handleSubmit} className="form">
                  <Field name="formName" type="hidden" component="input" />
                  <Field name="origin" type="hidden" component="input" />
                  <Field name="retURL" type="hidden" component="input" />
                  <Field name="country" type="hidden" component="input" />
                  <Field
                    name="marketing_code"
                    type="hidden"
                    component="input"
                  />
                  <Field name="incentive" type="hidden" component="input" />
                  <Input
                    name="firstName"
                    label="First Name"
                    placeholder="First Name"
                    type="text"
                    className="firsthalfWidthElement"
                    required={true}
                  />
                  <Input
                    name="lastName"
                    label="Last Name"
                    placeholder="Last Name"
                    type="text"
                    className="lasthalfWidthElement"
                    required={true}
                  />
                  <Input
                    name="company"
                    label="Company"
                    placeholder="Company"
                    type="text"
                    className="firsthalfWidthElement"
                    required={true}
                  />
                  <Input
                    name="email"
                    label="Email (Corporate Only)"
                    placeholder="Your email"
                    className="lasthalfWidthElement"
                    type="email"
                    onBlur={handleChange}
                    required={true}
                  />
                  <TextArea
                    name="message"
                    label="Message"
                    placeholder="Message"
                    type="text"
                    className="messageElement"
                  />

                  <Spacer />

                  <div className="buttonsWrapper">
                    <button
                      type="button"
                      className="btn"
                      onClick={form.reset}
                      disabled={submitting || pristine}
                    >
                      Clear
                    </button>
                    <button
                      type="submit"
                      className="btn"
                      disabled={submitting || pristine}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="footerWrapper">
                    <PrivacyLink to={'/privacy'} title={`Privacy policy`}>
                      Privacy policy
                    </PrivacyLink>
                  </div>
                </StyledForm>

                {(() => {
                  switch (process.env.NODE_ENV) {
                    case 'development':
                      return <pre>{JSON.stringify(values, 0, 2)}</pre>;
                    default:
                      return '';
                  }
                })()}

                {(() => {
                  switch (process.env.NODE_ENV) {
                    case 'development':
                      return <pre>{JSON.stringify(this.state, 0, 2)}</pre>;
                    default:
                      return '';
                  }
                })()}
              </>
            )}
          />
        </Container>
      </Modal>
    );
  }
}
