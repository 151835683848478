import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { EventList } from 'Components';
import moment from 'moment';
import Config from 'Data';

export class EventCalendarList extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { full, featured, countdown, event } = this.props;
    let aCity = event.city;

    return (
      <StaticQuery
        query={graphql`
          query {
            allImageSharp {
              edges {
                node {
                  fluid(maxWidth: 2880, quality: 90) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                  }
                }
              }
            }
            allMarkdownRemark(
              filter: { fileAbsolutePath: { regex: "/content/city/" } }
              sort: { fields: [frontmatter___date], order: DESC }
            ) {
              totalCount
              edges {
                node {
                  id
                  frontmatter {
                    title
                    city
                    banner {
                      childImageSharp {
                        fluid(maxWidth: 2880, quality: 90) {
                          base64
                          aspectRatio
                          tracedSVG
                          src
                          srcSet
                          srcWebp
                          srcSetWebp
                          sizes
                          originalImg
                          originalName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const placeholderImage =
            data.allImageSharp.edges.find(
              edge => edge.node.fluid.originalName === `placeholder.jpg`
            ) || null;

          const cityBanner =
            data.allMarkdownRemark.edges.find(
              edge =>
                edge.node.frontmatter.banner.childImageSharp.fluid
                  .originalName ===
                `${event.city
                  .toString()
                  .replace(/\s+/g, '-')
                  .toLowerCase()}.jpg`
            ) || null;

          return (
            <EventList
              full={full}
              featured={featured}
              countdown={countdown}
              photo={
                cityBanner != null
                  ? cityBanner.node.frontmatter.banner.childImageSharp.fluid
                  : placeholderImage.node.fluid
              }
              banner={event.banner}
              category={event.category || '*missing*'}
              date={event.date || '*missing*'}
              eventStart={event.event_start || '*missing*'}
              eventEnd={event.event_end || '*missing*'}
              eventStartDate={event.event_start_datetime || '*missing*'}
              eventEndDate={event.event_end_datetime || '*missing*'}
              eventCountry={event.country || '*missing*'}
              eventState={event.state || '*missing*'}
              eventCity={event.city || '*missing*'}
              eventType={event.twitter_type || '*missing*'}
              eventName={event.title || '*missing*'}
              rating={3}
              // eventURL={
              //   `/${event.city
              //     .toString()
              //     .replace(/\s+/g, '-')
              //     .toLowerCase()}` || '*missing*'
              // }
              eventURL={
                `${event.city
                  .toString()
                  .replace(/\s+/g, '')
                  .toLowerCase()}` || '*missing*'
              }
              // eventURL={`${event.event_url}`}
              share={this.props.share || '*missing*'}
              className={this.props.className}
            />
          );
        }}
      />
    );
  }
}

export default EventCalendarList;
