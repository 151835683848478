import styled from 'styled-components';
import { theme, mixins } from 'Styles';

const FlatImage = styled.div`
  border-radius: ${theme.borderRadius.ui};
  max-height: 240px;
  margin-top: -3rem;
  padding: 1rem;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    padding: 2rem;
    width: 50%;
    padding: 0;
    margin: 0;
    max-height: 400px;
    img {
      margin: 0;
    }
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

export default FlatImage;
