import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

const SEO = ({ title, desc, banner, pathname, article, event }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        buildTime,
        siteMetadata: {
          defaultTitle,
          titleAlt,
          siteTitle,
          brand,
          author,
          siteLanguage,
          logo,
          siteUrl,
          pathPrefix,
          defaultDescription,
          defaultBanner,
          twitter,
        },
      },
    }) => {
      console.log('process.env.NODE_ENV:', process.env.NODE_ENV);
      const seo = {
        title: title || defaultTitle,
        description: desc || defaultDescription,
        image: `${siteUrl}${banner || defaultBanner}`,
        url: `${siteUrl}${pathname
          .toString()
          .replace(/\s+/g, '-')
          .toLowerCase() || '/'}`,
      };
      const realPrefix = pathPrefix === '/' ? '' : pathPrefix;
      let schemaOrgJSONLD = [
        {
          '@context': 'http://schema.org',
          '@type': 'Website',
          '@id': siteUrl,
          url: siteUrl,
          name: siteTitle,
          alternateName: titleAlt || '',
          description: seo.description,
          image: {
            '@type': 'ImageObject',
            url: seo.image,
          },
        },
      ];
      if (event) {
        schemaOrgJSONLD = [
          {
            '@context': 'http://schema.org',
            '@type': 'Website',
            '@id': seo.url,
            url: seo.url,
            name: seo.title,
            alternateName: titleAlt || '',
            headline: seo.title,
            image: {
              '@type': 'ImageObject',
              url: seo.image,
            },
            description: seo.description,
            author: {
              '@type': 'Person',
              name: author,
            },
            publisher: {
              '@type': 'Organization',
              name: author,
              logo: {
                '@type': 'ImageObject',
                url: siteUrl + realPrefix + logo,
              },
            },
            isPartOf: siteUrl,
            mainEntityOfPage: {
              '@type': 'Website',
              '@id': siteUrl,
            },
          },
        ];
      }
      return (
        <>
          <Helmet title={seo.title}>
            <html lang={siteLanguage} />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />

            {(() => {
              switch (process.env.NODE_ENV) {
                case 'development':
                  return <meta name="robots" content="index,follow" />;
                case 'production':
                  return <meta name="robots" content="noindex,nofollow" />;
                default:
                  return '';
              }
            })()}
            <meta name="apple-mobile-web-app-title" content={brand} />
            <meta name="application-name" content={brand} />
            <script type="application/ld+json">
              {JSON.stringify(schemaOrgJSONLD)}
            </script>

            {/* OpenGraph  */}
            <meta property="og:url" content={seo.url} />
            <meta property="og:type" content={article ? 'article' : null} />
            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content={seo.image} />

            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={twitter} />
            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:image" content={seo.image} />
          </Helmet>
        </>
      );
    }}
  />
);
//   }
// }

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  event: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  article: false,
  event: false,
};

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        defaultTitle: title
        titleAlt
        siteTitle
        brand
        author
        siteLanguage
        logo
        siteUrl: url
        pathPrefix
        defaultDescription: description
        defaultBanner: banner
        twitter
      }
    }
  }
`;
