import React from 'react';
import Modal from 'react-modal';
// import CaretRight from 'react-icons/lib/fa/caret-right';
// import CaretLeft from 'react-icons/lib/fa/caret-left';
// import Close from 'react-icons/lib/md/close';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme, mixins } from 'Styles';
import mousetrap from 'mousetrap';
import { push, StaticQuery, graphql } from 'gatsby';
// import findIndex from "lodash/findIndex"

let posts;

Modal.setAppElement(`#___gatsby`);

const GatsbyModal = ({ post, isOpen, children }) => (
  <Modal
    post={post}
    isOpen={isOpen}
    onRequestClose={() => push(`/`)}
    style={{
      overlay: {
        position: `fixed`,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: `rgba(0, 0, 0, 0.75)`,
      },
      content: {
        position: `absolute`,
        border: `none`,
        background: `none`,
        padding: 0,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        overflow: `auto`,
        WebkitOverflowScrolling: `touch`,
      },
    }}
    contentLabel="Modal"
  >
    <div
      // onClick={() => push(`/`)}
      css={{
        display: `flex`,
        position: `relative`,
        height: `100vh`,
      }}
    >
      <div
        css={{
          display: `flex`,
          alignItems: `center`,
          justifyItems: `center`,
          margin: `auto`,
          width: `100%`,
          position: `relative`,
        }}
      >
        <p
          data-testid="previous-post"
          css={{
            cursor: `pointer`,
            fontSize: `50px`,
            color: `rgba(255,255,255,0.7)`,
            userSelect: `none`,
          }}
          onClick={e => this.previous(e)}
        />
        {children}
        <div>{isOpen}</div>
        <p
          data-testid="next-post"
          css={{
            cursor: `pointer`,
            fontSize: `50px`,
            color: `rgba(255,255,255,0.7)`,
            userSelect: `none`,
          }}
          onClick={e => this.next(e)}
        />
      </div>
      <p
        data-testid="modal-close"
        onClick={() => push(`/events/`)}
        css={{
          cursor: `pointer`,
          color: `rgba(255,255,255,0.8)`,
          fontSize: `30px`,
          position: `absolute`,
          background: '#f00',
          width: '40px',
          height: '40px',
        }}
      />
    </div>
  </Modal>
);

export default GatsbyModal;

GatsbyModal.propTypes = {
  isOpen: PropTypes.bool,
  // location: PropTypes.object,
};

GatsbyModal.defaultProps = {
  isOpen: false,
  // location: false,
};

{
  /* <StaticQuery
      query={graphql`
        query {
          allPostsJson {
            edges {
              node {
                id
              }
            }
          }
        }
      `}
      render={data => {
        if (!posts) {
          posts = data.allPostsJson.edges.map(e => e.node);
        }
        return (

        );
      }}
    /> */
}

// componentDidMount() {
//   mousetrap.bind(`left`, () => this.previous())
//   mousetrap.bind(`right`, () => this.next())
//   mousetrap.bind(`space`, () => this.next())
// }

// componentWillUnmount() {
//   mousetrap.unbind(`left`)
//   mousetrap.unbind(`right`)
//   mousetrap.unbind(`space`)
// }

// findCurrentIndex() {
//   let index
//   index = findIndex(
//     posts,
//     post => post.id === this.props.location.pathname.split(`/`)[1]
//   )

//   return index
// }

// next(e) {
//   if (e) {
//     e.stopPropagation()
//   }
//   const currentIndex = this.findCurrentIndex()
//   if (currentIndex || currentIndex === 0) {
//     let nextPost
//     // Wrap around if at end.
//     if (currentIndex + 1 === posts.length) {
//       nextPost = posts[0]
//     } else {
//       nextPost = posts[currentIndex + 1]
//     }
//     push(`/${nextPost.id}/`)
//   }
// }

// previous(e) {
//   if (e) {
//     e.stopPropagation()
//   }
//   const currentIndex = this.findCurrentIndex()
//   if (currentIndex || currentIndex === 0) {
//     let previousPost
//     // Wrap around if at start.
//     if (currentIndex === 0) {
//       previousPost = posts.slice(-1)[0]
//     } else {
//       previousPost = posts[currentIndex - 1]
//     }
//     push(`/${previousPost.id}/`)
//   }
// }
