import styled from 'styled-components';
import { theme, mixins } from 'Styles';
const { colors, fontSize } = theme;

const FooterSection = styled.footer`
  font-size: ${fontSize.small};
  background-color: ${colors.background.dark};
  color: ${colors.white.base};
  text-align: center;
  height: auto;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 85px;
    padding-bottom: 0px;
  }
`;

export default FooterSection;
