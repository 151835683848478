import React from 'react';

import './styles.scss';

class CategoryTagList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { category } = this.props;
    return (
      <div>
        <span className={`category-tag-list ${category}`}>{category}</span>
      </div>
    );
  }
}

export default CategoryTagList;
