import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import { BackgroundImage, BackgroundVideo } from 'Components';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
  BrandedColor,
  Speakers,
} from 'Styles';

const Wrapper = styled.section`
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1rem;
  @media (min-width: ${theme.breakpoints.s}) {
  }

  @media (min-width: ${theme.breakpoints.m}) {
    flex-direction: column;
    margin: 0;
    padding: 2rem;
    width: 100%;
  }

  @media (min-width: ${theme.breakpoints.l}) {
    margin: 0 auto 0;
  }

  max-width: ${theme.layout.base};
  // ${mixins.hairLineUnder};
`;

const Content = styled.div`
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  background-color: ${props =>
    props.dark ? theme.colors.black.base : theme.colors.white.base};
  color: ${props =>
    props.dark ? theme.colors.white.base : theme.colors.black.base};
  border-color: ${props =>
    props.dark ? theme.colors.white.base : theme.colors.black.base};
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const Grid = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${theme.layout.base};
`;

const Title = styled.h2`
  color: inherit;
  font-weight: bold;
  max-width: ${theme.layout.base};
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;
const Subtitle = styled.p`
  color: inherit;
  font-weight: bold;
  max-width: ${theme.layout.base};
  margin: 0 auto;
  & + a {
    margin-top: 1rem;
  }
  text-align: center;
`;

const StyledAccordion = styled(Accordion)`
  padding-top: 1rem;
`;

const ScheduleButton = styled(AccordionItemButton)`
  position: relative;
  padding: 4px;
  outline: none;
  cursor: pointer;
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  margin: 0;
  :after {
    content: '►';
    position: absolute;
    right: 0;
    padding: 0 1rem;
  }
  &[aria-expanded='true'] {
    color: ${theme.colors.primary.base};
    background: #d1d1d1;
    border-radius: ${theme.borderRadius.ui};
    border-color: ${theme.colors.primary.base};
    outline: 0;
    border-bottom: 1px solid #000;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    :after {
      color: ${theme.colors.black.base};
      content: '▲';
    }
  }
`;

const AccordionTime = styled.div`
  color: inherit;
  border-right: 1px solid #aaa;
  margin: 0;
  margin-right: 8px;
  text-align: left;
  font-size: ${theme.fontSize.small};
`;

const AccordionFromTime = styled.p`
  color: inherit;
  max-width: ${theme.layout.base};
  margin: 0 auto;
  & + a {
    margin-top: 1rem;
  }
  text-align: center;
`;

const AccordionToTime = styled.p`
  color: inherit;
  max-width: ${theme.layout.base};
  margin: 0 auto;
  & + a {
    margin-top: 1rem;
  }
  text-align: center;
`;

const AccordionTitle = styled.p`
  color: inherit;
  font-weight: bold;
  text-align: left;
  margin: 0;
`;

const StyledAccordionItemPanel = styled(AccordionItemPanel)`
  color: inherit;
  font-weight: bold;
  text-align: left;
  margin: 0;
`;

const Agenda = ({
  big,
  dark,
  title,
  schedule,
  layout,
  image,
  subtitle,
  name,
  cta_action,
  cta_action_text,
  cta_url,
  cta_text,
  cta_url_1,
  cta_text_1,
  children,
}) => (
  <>
    <Wrapper>
      <Content>
        {/* <Zoom duration={600} delay={500}> */}
        <>
          <Title>
            The <BrandedColor>{title}</BrandedColor>
          </Title>
          <Subtitle>{subtitle}</Subtitle>
        </>
        {/* </Zoom> */}

        <StyledAccordion>
          {schedule.map((item, i) => (
            <div key={i}>
              {/* <Fade delay={i * 200}> */}
              <AccordionItem uuid={`${item.agenda_id}`}>
                <AccordionItemHeading>
                  <ScheduleButton>
                    <AccordionTime>
                      <AccordionFromTime>{`${
                        item.from_time
                      }`}</AccordionFromTime>
                      <AccordionToTime>{`${item.to_time}`}</AccordionToTime>
                    </AccordionTime>
                    <AccordionTitle>{`${item.title}`}</AccordionTitle>
                  </ScheduleButton>
                </AccordionItemHeading>
                <AccordionItemPanel>{`${item.description}`}</AccordionItemPanel>
              </AccordionItem>
              {/* </Fade> */}
            </div>
          ))}
        </StyledAccordion>
      </Content>
    </Wrapper>
  </>
);

export default Agenda;
