import styled from 'styled-components';
import theme from 'Theme';
const { colors, fontSize, fontFamily } = theme;

const Title = styled.h2`
  color: inherit;
  font-weight: bold;
  max-width: ${theme.layout.base};
`;

export default Title;
