import React from 'react';
import Modal from 'react-modal';
import { Form, Field } from 'react-final-form';
import isEmail from 'validator/lib/isEmail';
import { compose, withProps } from 'recompose';
import styled, { css } from 'styled-components';
import { theme, mixins } from 'Styles';

import {
  Hero,
  HeroBig,
  PostList,
  HeroVideo,
  HeaderImage,
  BackgroundVideo,
  SectionBackground,
  EventCalendarCard,
  EventCalendarList,
} from 'Components';

import { Input, Heading, Spacer } from 'Components/FormElements';

const Container = styled.div`
  padding: 2rem;
`;

function postData(url = ``, data = {}) {
  return fetch(url, {
    method: 'POST',
    mode: 'no-cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  })
    .then(response => {
      // console.log(response);
      response
        .json()
        .then(data => {
          console.log(data.retURL);
          window.location = data.retURL;
        })
        .catch(function(e) {
          console.log('handled the error');
        });
    })
    .catch(error => console.error('Error:', error));
}

const API_ENDPOINT = '/.netlify/functions/referee';

const formHiddenData = {
  formName: 'FormContactUs',
  retURL: 'https://www.google.com/',
  referredBy: '',
};

const onSubmit = async values => {
  // JSON.stringify(values, 0, 2)
  postData(API_ENDPOINT, values)
    // .then(data => console.log(JSON.stringify(data))) // JSON-string from `response.json()` call
    .catch(error => console.error(error));
};

export default props => {
  const {
    title,
    subtitle,
    banner,
    isOpen,
    closeModal,
    onAfterOpen,
    onRequestClose,
    onChangeInput,
  } = props;

  return (
    <Modal
      id="modalContactUs"
      contentLabel="modalContactUs"
      closeTimeoutMS={400}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
    >
      <HeaderImage title={title} subtitle={subtitle} image={banner} />
      <button className="btn-close" onClick={closeModal}>
        X
      </button>

      <Container>
        <Form
          initialValues={formHiddenData}
          onSubmit={onSubmit}
          validate={values => {
            const errors = {};
            if (!values.title) {
              errors.title = 'Required';
            }
            if (!values.firstName) {
              errors.firstName = 'Required';
            }
            if (!values.lastName) {
              errors.lastName = 'Required';
            }
            if (!values.email) {
              errors.email = 'Required';
            } else if (!isEmail(values.email)) {
              errors.email = 'Invalid email';
            }
            if (!values.company) {
              errors.company = 'Required';
            }
            return errors;
          }}
          render={({
            form,
            handleSubmit,
            reset,
            submitting,
            pristine,
            values,
          }) => (
            <>
              <form onSubmit={handleSubmit} className="form">
                <Field name="formName" type="hidden" component="input" />
                <Field name="retURL" type="hidden" component="input" />
                <Input
                  name="title"
                  label="Title"
                  placeholder="Title"
                  type="text"
                />
                <Input
                  name="firstName"
                  label="First Name"
                  placeholder="First Name"
                  type="text"
                />
                <Input
                  name="lastName"
                  label="Last Name"
                  placeholder="Last Name"
                  type="text"
                />
                <Input
                  name="email"
                  label="Email"
                  placeholder="Email"
                  type="email"
                />
                <Input
                  name="company"
                  label="Company"
                  placeholder="company"
                  type="text"
                />
                {/*
                  <Field name="events">
                    {({ input, meta }) => (
                      <div>
                        <label>Which event(s) interest you?</label>
                        <input
                          {...input}
                          type="text"
                          placeholder="CCC"
                          className={
                            meta.error && meta.touched && `${'form-error'}`
                          }
                        />
                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="private">
                    {({ input, meta }) => (
                      <div>
                        <label>Are private CISO dinners of interest?</label>
                        <input
                          {...input}
                          type="text"
                          placeholder="a targeted
                          audience of 25 leaders for an evening with your team?"
                          className={
                            meta.error && meta.touched && `${'form-error'}`
                          }
                        />
                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field> */}
                <div className="buttonsWrapper">
                  <div className="buttons">
                    <button type="submit" disabled={submitting || pristine}>
                      Submit
                    </button>
                    <button
                      type="button"
                      onClick={reset}
                      disabled={submitting || pristine}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </form>
              {(() => {
                switch (process.env.NODE_ENV) {
                  case 'development':
                    return <pre>{JSON.stringify(values, 0, 2)}</pre>;
                  default:
                    return '';
                }
              })()}
            </>
          )}
        />
      </Container>
    </Modal>
  );
};
