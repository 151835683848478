import styled from 'styled-components';
import { theme, mixins } from 'Styles';

export const EventCard = styled.div`
  border-radius: ${theme.borderRadius.ui};
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  @media (max-width: ${theme.breakpoints.m}) {
    flex-direction: column;
  }
`;

export const EventCardTitle = styled.h2`
  color: ${theme.colors.black.base};
  font-size: ${theme.fontSize.medium};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
`;

export const EventCardSubtitle = styled.p`
  color: ${theme.colors.black.base};
  font-size: ${theme.fontSize.small};
  font-weight: bold;
  margin-bottom: 0rem;
`;
