import styled from 'styled-components';
import media from './media';
import theme from 'Theme';
const { colors, fontSize } = theme;

const Heading = styled.h2`
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0 24px;
  width: 80%;
  line-height: 1;

  &:before {
    margin-right: 10px;
    font-weight: normal;
    color: ${colors.primary};
    font-size: ${fontSize.xlarge};
    position: relative;
    bottom: 4px;
  }
`;

export default Heading;
