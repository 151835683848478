import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme, mixins } from 'Styles';
import YouTube from '@u-wave/react-youtube';
import Vimeo from '@u-wave/react-vimeo';

const Wrapper = styled.section`
  width: 100%;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`;

const Content = styled.div`
  color: ${theme.colors.white.base};
  font-size: ${theme.fontSize.h1};
  font-weight: bold;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  max-width: ${theme.layout.base};
  padding: 0 2rem;
  margin-bottom: 3rem;
  align-items: center;
`;

const Title = styled.h1`
  color: ${theme.colors.white.base};
  font-size: ${theme.fontSize.h3};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
`;

const Subtitle = styled.p`
  max-width: 650px;
  font-weight: normal;
  font-size: ${theme.fontSize.large};
  font-weight: bold;
  color: ${theme.colors.white.light};
`;

const BackgroundVideo = ({ children, title, image, youtubeId, vimeoId }) => (
  <Wrapper>
    <div className="video-section-youtube-background">
      <div className="video-section-youtube-foreground">
        {youtubeId && (
          <YouTube
            video={youtubeId}
            width={640}
            height={320}
            autoplay={false}
            modestBranding={true}
            muted={true}
            loop={1}
            showInfo={false}
            background={false}
            controls={true}
            className="video-iframe"
            showRelatedVideos={false}
          />
        )}
        {vimeoId && (
          <Vimeo
            video={vimeoId}
            width={640}
            height={320}
            // autoplay
            volume={0}
            // loop={true}
            background={false}
            controls={false}
            className="video-iframe"
          />
        )}
      </div>
    </div>

    {/* {image && <Img fluid={image.childImageSharp.fluid || {} || [] || ''} />} */}
  </Wrapper>
);

export default BackgroundVideo;

BackgroundVideo.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

BackgroundVideo.defaultProps = {
  children: false,
  image: false,
  title: false,
};
