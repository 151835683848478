import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme, mixins } from 'Styles';

const Wrapper = styled.div`
  background: #ccc;
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;

  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    margin-bottom: 0;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  height: 100%;
  position: relative;
  overflow: hidden;
  .gatsby-image-wrapper {
    position: initial !important;
  }
  ${props => (props.dark ? mixins.backgroundBefore : '')};
`;

const Content = styled.div`
  color: ${theme.colors.white.base};
  font-size: ${theme.fontSize.h1};
  font-weight: bold;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  max-width: ${theme.layout.base};
  padding: 0 2rem;
  margin-bottom: 3rem;
  align-items: center;
`;

const Title = styled.h1`
  color: ${theme.colors.white.base};
  font-size: ${theme.fontSize.h3};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
`;

const Subtitle = styled.p`
  max-width: 650px;
  font-weight: normal;
  font-size: ${theme.fontSize.large};
  font-weight: bold;
  color: ${theme.colors.white.light};
`;

const BackgroundImage = ({ dark, children, title, image, className }) => (
  <Wrapper className={className} dark={dark}>
    {/* <div className="video-background">
      <div className="video-foreground">
        <YouTube
          videoId="VCXYLD8LzkI"
          opts={videoOptions}
          className="video-iframe"
        />
      </div>
    </div> */}

    {image && <Img alt={title} title={title} fluid={image || {} || [] || ''} />}
    {/* <Content>
      <Title>{title}</Title>
      {children && <Subtitle>{children}</Subtitle>}
    </Content> */}
  </Wrapper>
);

export default BackgroundImage;

BackgroundImage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

BackgroundImage.defaultProps = {
  children: false,
  image: false,
  title: false,
};
