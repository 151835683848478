import styled from 'styled-components';
import { theme, mixins } from 'Styles';
const { colors, fontSize } = theme;

export const Grid = styled.div`
  text-align: center;
  margin: 1rem auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  @media (min-width: ${theme.breakpoints.s}) {
    grid-template-columns: repeat(auto-fill, minmax(28%, 28%));
  }
  @media (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: repeat(auto-fill, minmax(140px, 140px));
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  gap: 1rem 12px;
  justify-items: stretch;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  position: relative;
  height: 100%;
`;

export const Avatar = styled.div`
  background-image: url("${props => props.background}");
  height: 160px;
  @media (min-width: ${theme.breakpoints.s}) {
    height: 160px;
  }
  @media (min-width: ${theme.breakpoints.m}) {
    height: 120px;
  }
  @media (min-width: ${theme.breakpoints.l}) {
    // height: 120px;
  }
  width: 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${theme.borderRadius.ui} ${theme.borderRadius.ui} 0 0;
  position: relative;
  ${mixins.backgroundAvatar};
`;

export const Name = styled.div`
  color: ${theme.colors.primary.base};
  font-size: ${theme.fontSize.small};
  font-weight: bold;
  position: absolute;
  bottom: 6px;
  left: 6px;
  right: 6px;
  color: white;
  z-index: 10;
`;

export const Title = styled.p`
  color: ${theme.colors.black.base};
  font-size: ${theme.fontSize.xsmall};
  margin: 0;
`;

export const Company = styled.p`
  color: ${theme.colors.black.base};
  font-size: ${theme.fontSize.smallish};
  font-weight: bold;
  margin: 0;
`;

export const Speaker = styled.div`
  width: 100%;
  text-align: left;
  // display: flex;
  // flex-direction: column;
  // align-items: end;
  // justify-content: space-between;
  background: #fff;
  border-radius: ${theme.borderRadius.ui};
  overflow: hidden;
  box-shadow: ${theme.shadow.feature.small.default};
  border: 1px solid #ddd;
  height: 100%;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

export const Details = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  padding: 8px;
  background: ${theme.colors.white.base};
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;
