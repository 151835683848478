import { css } from 'styled-components';
import { theme } from 'Styles';
import media from './media';

const mixins = {
  flexCenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  flexBetween: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  outline: css`
    outline: 1px solid red;
  `,

  // link: css`
  //   display: inline-block;
  //   text-decoration: none;
  //   text-decoration-skip-ink: auto;
  //   color: inherit;
  //   position: relative;
  //   transition: ${theme.transitions.default};
  //   cursor: pointer;
  //   &:hover,
  //   &:active,
  //   &:focus {
  //     color: ${theme.colors.primary.base};
  //     outline: 0;
  //   }
  // `,

  // inlineLink: css`
  //   display: inline-block;
  //   text-decoration: none;
  //   text-decoration-skip-ink: auto;
  //   position: relative;
  //   transition: ${theme.transitions.default};
  //   cursor: pointer;
  //   &:hover,
  //   &:focus,
  //   &:active {
  //     color: ${theme.colors.black.base};
  //     outline: 0;
  //     &:after {
  //       width: 100%;
  //     }
  //   }
  //   &:after {
  //     content: '';
  //     display: block;
  //     width: 0;
  //     height: 1px;
  //     position: relative;
  //     bottom: 0.37em;
  //     background-color: ${theme.colors.primary.base};
  //     transition: ${theme.transitions.default};
  //   }
  // `,

  closeButton: css`
    color: ${theme.colors.black.base};
    background-color: ${theme.colors.white.base};
    border: 1px solid ${theme.colors.white.base};
    border-radius: ${theme.borderRadius.elements};
    padding: 8px;
    font-size: ${theme.fontSize.smallish};
    font-weight: bold;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    transition: ${theme.transitions.default};
    &:hover,
    &:focus,
    &:active {
      color: ${theme.colors.black.light};
      background-color: ${theme.colors.white.light};
    }
    &:after {
      display: none !important;
    }
  `,

  smallButton: css`
    color: ${theme.colors.white.base};
    background-color: ${theme.colors.primary.base};
    border: 1px solid ${theme.colors.primary.base};
    border-radius: ${theme.borderRadius.elements};
    padding: 8px 2.5rem;
    font-size: ${theme.fontSize.smallish};
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    transition: ${theme.transitions.default};
    &:hover,
    &:focus,
    &:active {
      color: ${theme.colors.white.light};
      background-color: ${theme.colors.primary.base};
    }
    &:after {
      display: none !important;
    }
  `,

  bigButton: css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary.base};
    border: 1px solid ${theme.colors.primary.base};
    border-radius: ${theme.elementRadius};
    padding: 1rem 2.4rem;
    font-size: ${theme.fontSize.medium};
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    transition: ${theme.transitions.default};
    &:hover,
    &:focus,
    &:active {
      color: ${theme.colors.white};
      background-color: ${theme.colors.primary.base};
    }
    &:after {
      display: none !important;
    }
  `,

  sidePadding: css`
    padding-left: 150px;
    padding-right: 150px;
    ${media.desktop`padding-left: 100px;padding-right: 100px;`};
    ${media.tablet`padding-left: 50px;padding-right: 50px;`};
    ${media.phablet`padding-left: 25px;padding-right: 25px;`};
  `,

  verticalPadding: css`
    padding-top: 75px;
    padding-bottom: 75px;
    ${media.desktop`padding-top: 50px;padding-bottom: 50px;`};
    ${media.tablet`padding-top: 25px;padding-bottom: 25px;`};
    ${media.phablet`padding-top: 12px;padding-bottom: 12px;`};
  `,

  boxShadow: css`
    box-shadow: 0 8px 30px 0px rgba(0, 0, 0, 0.1);
    transition: 0.7s;
  `,

  boxShadowHover: css`
    box-shadow: 0 8px 50px 0px rgba(0, 0, 0, 0.2);
    transition: 0.7s;
  `,

  hairLineTop: css`
    border-top: thin solid #c7c7c7;
  `,
  hairLineUnder: css`
    border-bottom: thin solid #c7c7c7;
  `,

  backgroundDark: css`
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      z-index: 1;
      background: rgba(0, 0, 0, 0.6);
    }
  `,

  backgroundBefore: css`
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      background-image: ${theme.gradient.bottomToTop};
    }
  `,
  backgroundAfter: css`
    &::after {
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      z-index: 1;
      background-image: ${theme.gradient.bottomToTop};
    }
  `,
  backgroundAvatar: css`
    &::before {
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      z-index: 1;
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.1) 70%,
        rgba(0, 0, 0, 0.7) 100%
      );
      border-radius: ${theme.borderRadius.ui} ${theme.borderRadius.ui} 0 0;
    }
  `,
};

export default mixins;
