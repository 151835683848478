import React, { Component, useState } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Menu from './Menu';
import styled from 'styled-components';
import Config from 'Data';
import { theme, mixins, media, Nav } from 'Styles';
const { colors, fontSize, fonts } = theme;
import Headroom from 'react-headroom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { IconLogo } from './icons';
import { Logo, SocialShare } from 'Components';

const HeaderContainer = styled.header`
  ${mixins.flexBetween};
  padding: 0;
  position: relative;
  top: 0;
  background-color: ${colors.transparent};
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  width: 100%;
  margin: 0 auto;
`;

const StyledLink = styled(Link)`
  display: flex;
  font-weight: 700;
  align-items: center;
  &:hover {
    color: inherit;
  }
`;

const Navbar = styled(Nav)`
  ${mixins.flexBetween};
  color: ${colors.black.base};
  position: relative;
  max-width: ${theme.layout.base};
  margin: 0 auto;
  z-index: 12;
  min-height: ${theme.headerScrollHeight};
  padding: 0 1rem;
  @media (min-width: ${theme.breakpoints.s}) {
    padding: 0 2rem;
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const LogoLink = styled(Link)`
  color: ${colors.primary};
  // width: 42px;
  // height: 42px;
  font-weight: bold;
  font-size: ${fontSize.small};
  &:hover,
  &:focus {
    svg {
      fill: ${colors.primary};
    }
  }
  svg {
    fill: none;
    transition: ${theme.transition};
    user-select: none;
  }
`;
const Hamburger = styled.div`
  ${mixins.flexCenter};
  overflow: visible;
  margin: 0;
  padding: 8px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  border-radius: ${theme.borderRadius.ui};
  background-color: transparent;
  display: none;
  ${media.desktop`display: flex;`};
  background: rgba(0, 0, 0, 0.2);
`;
const HamburgerBox = styled.div`
  position: relative;
  display: inline-block;
  width: ${theme.hamburgerWidth}px;
  height: ${theme.hamburgerWidth}px;
`;
const HamburgerInner = styled.div`
  background-color: white;
  position: absolute;
  width: ${theme.hamburgerWidth}px;
  height: 2px;
  border-radius: ${theme.borderRadius};
  top: 50%;
  left: 0;
  right: 0;
  transition-duration: 0.22s;
  transition-property: transform;
  transition-delay: ${props => (props.menuOpen ? `0.12s` : `0s`)};
  transform: rotate(${props => (props.menuOpen ? `225deg` : `0deg`)});
  transition-timing-function: cubic-bezier(
    ${props =>
      props.menuOpen ? `0.215, 0.61, 0.355, 1` : `0.55, 0.055, 0.675, 0.19`}
  );
  &:before,
  &:after {
    content: '';
    display: block;
    background-color: inherit;
    position: absolute;
    left: auto;
    right: 0;
    width: ${theme.hamburgerWidth}px;
    height: 2px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: ${theme.borderRadius};
  }
  &:before {
    width: ${props => (props.menuOpen ? `100%` : `80%`)};
    top: ${props => (props.menuOpen ? `0` : `-10px`)};
    opacity: ${props => (props.menuOpen ? 0 : 1)};
    transition: ${props =>
      props.menuOpen ? theme.hamBeforeActive : theme.hamBefore};
  }
  &:after {
    width: ${props => (props.menuOpen ? `100%` : `80%`)};
    bottom: ${props => (props.menuOpen ? `0` : `-10px`)};
    transform: rotate(${props => (props.menuOpen ? `-90deg` : `0`)});
    transition: ${props =>
      props.menuOpen ? theme.hamAfterActive : theme.hamAfter};
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  ${media.desktop`display: none;`};
  // a:focus,
  // a:hover {
  //   color: ${colors.black.base};
  //   outline: 0;
  // }
`;
const NavList = styled.ol`
  margin: 0;
  display: flex;
  div {
    ${mixins.flexBetween};
  }
`;
const NavListItem = styled.li`
  margin: 0 10px;
  position: relative;
  text-transform: uppercase;
  font-size: ${fontSize.small};
`;

const NavLink = styled(AnchorLink)`
  ${mixins.link};
  padding: 12px 10px;
  font-weight: bold;
`;

const NavExternal = styled.a`
  padding: 12px 10px;
  font-weight: bold;
`;

const TextLogo = styled.span`
  font-size: ${fontSize.xxlarge};
`;

const ActionLink = styled.a`
  ${mixins.smallButton};
  margin-left: 10px;
  // font-size: ${fontSize.smallish};
  color: ${theme.colors.white.base};
`;

class OnePageHeader extends Component {
  static propTypes = {
    navLinks: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
  };

  state = {
    lastScrollTop: 0,
    scrollDirection: 'none',
    menuOpen: false,
    isMounted: false,
  };

  componentDidMount() {
    setTimeout(() => this.setState({ isMounted: true }), 100);

    console.log('Location: ', this.props.location);
    var pairs = this.props.location.search.slice(1).split('&');
    var result = {};
    pairs.forEach(function(pair) {
      pair = pair.split('=');
      result[pair[0]] = decodeURIComponent(pair[1] || '');
    });
    console.log('Params: ', result);
    fetch('/locale.json')
      .then(res => res.json())
      .then(data => console.log('GEOIP: ', data))
      // .then(data => this.setLocale(data))
      .catch(err =>
        console.error('Error fetching data from /locale.json endpoint')
      );
  }

  componentWillUnmount() {}

  toggleMenu = () => this.setState({ menuOpen: !this.state.menuOpen });

  handleMenuClick = e => {
    const target = e.target;
    const isLink = target.hasAttribute('href');
    const isContainer =
      target.classList && target.classList[0].includes('MenuContainer');

    if (isLink || isContainer) {
      this.toggleMenu();
    }
  };

  render() {
    const { scrollDirection, menuOpen, isMounted } = this.state;
    const { location, navLinks, type, sticky } = this.props;
    return (
      <>
        {sticky && (
          <Headroom
            calcHeightOnResize
            disableInlineStyles
            onPin={() => {
              // console.log('Headroom pinned');
              document.body.classList.toggle('social-share-closed');
            }}
            onUnpin={() => {
              // console.log('Headroom Unpinned');
              document.body.classList.toggle('social-share-closed');
            }}
            onUnfix={() => {
              // console.log('Headroom Unfix');
              // document.body.classList.toggle('social-share-unfix');
            }}
          >
            <HeaderContainer
              ref={el => (this.header = el)}
              scrollDirection={scrollDirection}
            >
              <Helmet>
                <body className={menuOpen ? 'menu-open' : ''} />
              </Helmet>

              <Navbar>
                <TransitionGroup>
                  {isMounted && (
                    <CSSTransition classNames="fade" timeout={3000}>
                      <StyledLink
                        to="/"
                        alt={`${Config.brand} Logo`}
                        aria-label="Home"
                      >
                        <TextLogo className="nav-logo">
                          <IconLogo />
                        </TextLogo>
                      </StyledLink>
                    </CSSTransition>
                  )}
                </TransitionGroup>

                {isMounted && (
                  <Hamburger
                    onClick={this.toggleMenu}
                    className={'hamburger-button'}
                  >
                    <HamburgerBox>
                      <HamburgerInner
                        className={'hamburger-inner'}
                        menuOpen={menuOpen}
                      />{' '}
                    </HamburgerBox>
                  </Hamburger>
                )}

                <NavLinks className={'nav-links'}>
                  <NavList>
                    <TransitionGroup>
                      {isMounted &&
                        navLinks &&
                        navLinks.map(({ url, name }, i) => (
                          <CSSTransition
                            key={i}
                            classNames="fadedown"
                            timeout={3000}
                          >
                            <NavListItem
                              key={i}
                              style={{ transitionDelay: `${i * 100}ms` }}
                            >
                              {url.toString().includes('http') ? (
                                <NavExternal href={url} target="_blank">
                                  {name}
                                </NavExternal>
                              ) : (
                                <NavLink offset={theme.headerHeight} href={url}>
                                  {name}
                                </NavLink>
                              )}
                            </NavListItem>
                          </CSSTransition>
                        ))}
                    </TransitionGroup>
                    {/* <TransitionGroup>
                      {isMounted && (
                        <CSSTransition classNames="fadedown" timeout={3000}>
                          <div style={{ transitionDelay: `600ms` }}>
                            <ActionLink
                              offset="80"
                              href="#"
                              target="_blank"
                              rel="nofollow noopener noreferrer"
                            >
                              Get in touch
                            </ActionLink>
                          </div>
                        </CSSTransition>
                      )}
                    </TransitionGroup> */}
                  </NavList>
                </NavLinks>
              </Navbar>

              {navLinks && (
                <Menu
                  type={type}
                  navLinks={navLinks}
                  menuOpen={menuOpen}
                  handleMenuClick={e => this.handleMenuClick(e)}
                />
              )}
            </HeaderContainer>
          </Headroom>
        )}

        {!sticky && (
          <HeaderContainer
            ref={el => (this.header = el)}
            scrollDirection={scrollDirection}
          >
            <Helmet>
              <body className={menuOpen ? 'menu-open' : ''} />
            </Helmet>

            <Navbar>
              {isMounted && (
                <StyledLink to="/" alt={`${Config.brand} Logo`}>
                  <TextLogo className="nav-logo">
                    <IconLogo />
                  </TextLogo>
                </StyledLink>
              )}

              {isMounted && (
                <Hamburger
                  onClick={this.toggleMenu}
                  className={'hamburger-button'}
                >
                  <HamburgerBox>
                    <HamburgerInner menuOpen={menuOpen} />
                  </HamburgerBox>
                </Hamburger>
              )}

              <NavLinks className={'nav-links'}>
                <NavList>
                  {isMounted &&
                    navLinks &&
                    navLinks.map(({ url, name }, i) => (
                      <NavListItem
                        key={i}
                        style={{ transitionDelay: `${i * 100}ms` }}
                      >
                        {url.toString().includes('http') ? (
                          <NavExternal href={url} target="_blank">
                            {name}
                          </NavExternal>
                        ) : (
                          <NavLink to={url}>{name}</NavLink>
                        )}
                      </NavListItem>
                    ))}
                </NavList>
              </NavLinks>
            </Navbar>

            {navLinks && (
              <Menu
                type={type}
                navLinks={navLinks}
                menuOpen={menuOpen}
                handleMenuClick={e => this.handleMenuClick(e)}
              />
            )}
          </HeaderContainer>
        )}
      </>
    );
  }
}

export default OnePageHeader;
