import React from 'react';
import Modal from 'react-modal';
import { Form, Field } from 'react-final-form';
import isEmail from 'validator/lib/isEmail';
import { compose, withProps } from 'recompose';
import styled, { css } from 'styled-components';
import { theme, mixins } from 'Styles';

import {
  Hero,
  HeroBig,
  PostList,
  HeroVideo,
  HeaderImage,
  BackgroundVideo,
  SectionBackground,
  EventCalendarCard,
  EventCalendarList,
} from 'Components';

import { Input, Heading, Spacer } from 'Components/FormElements';

const Container = styled.div`
  padding: 2rem;
`;

function postData(url = ``, data = {}) {
  return fetch(url, {
    method: 'POST',
    mode: 'no-cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  })
    .then(response => {
      // console.log(response);
      response
        .json()
        .then(data => {
          console.log(data.retURL);
          window.location = data.retURL;
        })
        .catch(function(e) {
          console.log('handled the error');
        });
    })
    .catch(error => console.error('Error:', error));
}

const API_ENDPOINT = '/.netlify/functions/referee';

const formHiddenData = {
  formName: 'AudiencesModal',
  retURL: 'https://www.google.com/',
  referredBy: '',
};

const onSubmit = async values => {
  // JSON.stringify(values, 0, 2)
  postData(API_ENDPOINT, values)
    // .then(data => console.log(JSON.stringify(data))) // JSON-string from `response.json()` call
    .catch(error => console.error(error));
};

export default props => {
  const {
    currentAudience,
    title,
    subtitle,
    banner,
    isOpen,
    closeModal,
    onAfterOpen,
    onRequestClose,
    onChangeInput,
  } = props;

  return (
    <Modal
      id="modalAudience"
      contentLabel="modalAudience"
      closeTimeoutMS={400}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
    >
      <HeaderImage
        title={currentAudience ? currentAudience.displayName : ''}
        image={currentAudience ? currentAudience.banner : ''}
      />
      <button className="btn-close" onClick={closeModal}>
        X
      </button>

      <Container>{currentAudience ? currentAudience.copy : ''}</Container>
    </Modal>
  );
};
