import styled from 'styled-components';
import { theme, mixins } from 'Styles';

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: ${theme.layout.base};
  margin: 0;
  @media (min-width: ${theme.breakpoints.s}) {
  }

  @media (min-width: ${theme.breakpoints.m}) {
  }

  @media (min-width: ${theme.breakpoints.l}) {
    margin: 0 auto;
  }
`;

export default Wrapper;
