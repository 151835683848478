import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  Content,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  Title,
  Subtitle,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
} from 'Styles';

import {
  FormAttend,
  BackgroundImage,
  BackgroundVideo,
  EventCalendarCard,
  EventCalendarList,
  Hero,
  HeroBig,
  HeroVideo,
  Logo,
  Modal,
  PostList,
  SectionBackground,
  SectionCallout,
  FormShareEvent,
  FormSubscribe,
} from 'Components';

import Config from 'Data';
import { Link } from 'gatsby';

const { colors, fontSize } = theme;

const Wrapper = styled.section`
  background: #fff;
  border-radius: ${theme.borderRadius.ui};
  overflow: hidden;
  box-shadow: ${theme.shadow.feature.small.default};
  z-index: 3;
  display: grid;
  min-height: 260px;
  align-items: center;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: 30% 1fr;
    margin: -5rem auto 3rem;
  }
  @media (min-width: ${theme.breakpoints.l}) {
    grid-template-columns: 30% 1fr;
  }
  max-width: ${theme.layout.base};
`;

class FeaturedSection extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {}

  render() {
    const {
      dark,
      title,
      copy,
      image,
      cta_text,
      cta_link,
      cta_text_1,
      cta_link_1,
    } = this.props;

    return (
      <FullWrapper>
        <Wrapper>
          <BackgroundImage title={title} image={image} dark={dark} />
          <Content layout="rtl" style={{ padding: '2rem' }}>
            <Title>{title}</Title>
            <Subtitle>{copy}</Subtitle>
            {cta_text && cta_link && (
              <ActionButtonAlt href={cta_link}>{cta_text}</ActionButtonAlt>
            )}
            {cta_text_1 && cta_link_1 && (
              <ActionButtonAlt href={cta_link_1}>{cta_text_1}</ActionButtonAlt>
            )}
          </Content>
        </Wrapper>
      </FullWrapper>
    );
  }
}

export default FeaturedSection;
