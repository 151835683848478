export const colors = {
  white: {
    base: '#fff',
    light: '#f0f0f0',
    grey: '#cecece',
    dark: '#a0afd7',
  },
  red: {
    base: '#c10709',
  },
  black: {
    base: '#333438',
    light: '#4b4e57',
    blue: '#2e3246',
  },
  primary: {
    base: '#00A0EE',
    light: '#3e5fbc',
    dark: '#9f000d',
  },
  background: {
    light: '#f4f5f6',
    dark: '#000000',
    black: '#000000',
  },
  gradient: {
    light: 'rgba(0, 0, 0, 0.3)',
    dark: 'rgba(0, 0, 0, 0.7)',
  },
};

// 0 12px 44px rgba(0,0,0,0.2)
export const shadow = {
  card: '0 12px 44px rgba(0,0,0,0.15)',
  image: '0 12px 44px rgba(0,0,0,0.15)',
  suggestion: '0 -5px 30px rgba(0,0,0,0.1)',
  footer: '0 -3px 26px rgba(0,0,0,0.5)',
  feature: {
    big: {
      default: '0 40px 40px rgba(0, 0, 0, 0.1)',
      hover: '0 50px 50px rgba(0, 0, 0, 0.1)',
    },
    small: {
      default: '0 12px 44px rgba(0,0,0,0.15)',
      hover: '0 40px 45px rgba(0, 0, 0, 0.1)',
    },
  },
  text: {
    small: '0 5px 10px rgba(0, 0, 0, 0.25)',
    big: '0 15px 20px rgba(0, 0, 0, 0.13)',
  },
};

export const gradient = {
  // eslint-disable-next-line
  leftToRight: `linear-gradient(-45deg, ${colors.gradient.light} 0%, ${colors.gradient.dark} 100%)`,
  // eslint-disable-next-line
  rightToLeft: `linear-gradient(45deg, ${colors.gradient.light} 0%, ${colors.gradient.dark} 100%)`,
  // eslint-disable-next-line
  topToBottom: `linear-gradient(180deg, ${colors.gradient.light} 70%, ${colors.gradient.dark} 100%)`,
  // eslint-disable-next-line
  bottomToTop: `linear-gradient(0deg, ${colors.gradient.light} 0%, ${colors.gradient.dark} 100%)`,
};

export const transition = {
  easeInOutCubic: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  easeOutBack: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
  duration: '0.25s',
};

const theme = {
  colors,
  gradient,
  shadow,
  fontSize: {
    xsmall: '10px',
    smallish: '12px',
    small: '14px',
    medium: '16px',
    large: '18px',
    xlarge: '20px',
    xxlarge: '22px',
    h6: '1em',
    h5: '1.6em',
    h4: '1.8em',
    h3: '2em',
    h2: '2.4em',
    h1: '2.8em',
    hero: '3em',
  },

  easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',

  elementRadius: '999px',
  headerHeight: 80,
  headerScrollHeight: '72px',
  margin: '1rem',
  padding: '1rem',
  tabHeight: 60,
  tabWidth: 120,

  hamburgerWidth: 30,
  hamBefore: `top 0.1s ease-in 0.25s, opacity 0.1s ease-in`,
  hamBeforeActive: `top 0.1s ease-out, opacity 0.1s ease-out 0.12s`,
  hamAfter: `bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)`,
  hamAfterActive: `bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s`,

  breakpoints: {
    xs: '400px',
    s: '600px',
    m: '900px',
    l: '1140px',
  },
  fontFamily: {
    // eslint-disable-next-line
    body: `Open Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    // eslint-disable-next-line
    heading: `Open Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  },
  layout: {
    blog: '800px',
    base: '1140px',
    big: '1280px',
  },
  borderRadius: {
    elements: '999px',
    ui: '8px',
    round: '999px',
  },
  transitions: {
    default: {
      duration: transition.duration,
      timing: transition.easeInOutCubic,
      transition: `all ${transition.duration} ${transition.easeInOutCubic}`,
    },
    boom: {
      duration: transition.duration,
      timing: transition.easeOutBack,
      transition: `all ${transition.duration} ${transition.easeOutBack}`,
    },
    headroom: {
      transition: 'all 0.25s ease-in-out',
    },
  },
};

export default theme;
