import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import { BackgroundImage, BackgroundVideo } from 'Components';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
  BrandedColor,
  Speakers,
} from 'Styles';

// const Wrapper = styled.div`
//   color: ${props =>
//     props.dark ? theme.colors.black.base : theme.colors.white.base};
//   min-height: ${props => (props.big ? '400px;' : '100px;')};
//   background: none;
//   position: relative;
//   overflow: hidden;
//   display: flex;
//   flex-direction: column-reverse;

//   align-items: center;
//   margin: 0 auto;
//   @media (min-width: ${theme.breakpoints.s}) {
//   }
//   @media (min-width: ${theme.breakpoints.m}) {
//     flex-direction: row;
//     flex-direction: ${props => (props.layout == 'ltr' ? `row` : `row-reverse`)};
//     margin: 0 auto 0;
//   }
//   @media (min-width: ${theme.breakpoints.l}) {
//   }
//   .gatsby-image-wrapper {
//     position: initial !important;
//   }
// `;

const Wrapper = styled.section`
  background: #fff;
  border-radius: ${theme.borderRadius.ui};
  box-shadow: ${theme.shadow.feature.small.default};
  border: 1px solid #ddd;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1rem;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    padding: 2rem;
    flex-direction: column;
  }
  @media (min-width: ${theme.breakpoints.l}) {
    margin: 3rem auto;
  }
  max-width: ${theme.layout.base};
`;

const Content = styled.div`
  display: block;
  margin: 0 auto;
  text-align: left;
  width: 100%;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const Grid = styled.div`
  text-align: left;
  margin: 1rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
  @media (min-width: ${theme.breakpoints.s}) {
    grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
  }
  @media (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
  }
  @media (min-width: ${theme.breakpoints.l}) {
    // grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
  gap: 1rem 12px;
  p {
    margin: 0;
  }
`;

const Title = styled.h2`
  color: inherit;
  font-weight: bold;
  max-width: ${theme.layout.base};
  margin-left: auto;
  margin-right: auto;
  text-align: left;
`;
const Subtitle = styled.p`
  color: inherit;
  max-width: ${theme.layout.base};
  margin: 0 auto;
  & + a {
    margin-top: 1rem;
  }
  text-align: left;
`;

const Faq = ({
  big,
  dark,
  title,
  speakers,
  layout,
  image,
  subtitle,
  name,
  cta_action,
  cta_action_text,
  cta_url,
  cta_text,
  cta_url_1,
  cta_text_1,
  children,
}) => (
  <>
    <Wrapper>
      <Content>
        <Zoom duration={600} delay={500}>
          <>
            <Title>
              <BrandedColor>{title}</BrandedColor>
            </Title>
            {/* <Subtitle>{subtitle}</Subtitle> */}
          </>
        </Zoom>
      </Content>
      <Grid>
        <div>
          <h5>How can I get to the venue?</h5>
          <p>FREE Uber passes are on us or take Valet at NO cost.</p>
        </div>
        <div>
          <h5>Is there a FEE?</h5>
          <p>
            Being a closed-door event of 100 leaders that were specifically
            invited there is NO fee to attend.
          </p>
        </div>
        <div>
          <h5>Are there any speaking opportunities?</h5>
          <p>
            If you would like to be considered for seat on one of the panels
            CLICK HERE to let us know and we'll be in touch.
          </p>
        </div>
        <div>
          <h5>Can I refer a colleague?</h5>
          <p>
            All referrals must be approved by our event producer. Simply CLICK
            HERE to submit your referral for approval.
          </p>
        </div>
      </Grid>
    </Wrapper>
  </>
);

export default Faq;
