import styled from 'styled-components';
import { theme, mixins } from 'Styles';

const FeaturedSection = styled.section`
  background: #fff;
  border-radius: ${theme.borderRadius.ui};
  box-shadow: ${theme.shadow.feature.small.default};
  border: 1px solid #ddd;
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: -2rem auto 0;
  padding: 1rem 2rem;
  margin-right: 0rem;
  flex-direction: column-reverse;

  @media (min-width: ${theme.breakpoints.s}) {
    padding: 2rem;
    margin-right: 0;
  }

  @media (min-width: ${theme.breakpoints.m}) {
    flex-direction: row;
  }

  @media (min-width: ${theme.breakpoints.l}) {
    margin: -80px auto 3rem;
  }

  max-width: ${theme.layout.base};
  // ${mixins.hairLineUnder};
`;

export default FeaturedSection;
