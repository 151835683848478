import React, { Fragment } from 'react';
import { ThemeProvider } from 'emotion-theming';
import PropTypes from 'prop-types';
import 'typeface-open-sans';
import 'typeface-candal';
import { SEO, SocialShare, FooterSimple, OnePageHeader } from 'Components';
import { GlobalStyle, theme } from 'Styles';
import { css, Global } from 'styled-components';
import { onePageNav } from 'Data';

import { Location } from '@reach/router';

class OnePageLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isHide: false };
    this.hideBar = this.hideBar.bind(this);
  }

  hideBar() {
    let { isHide } = this.state;
    window.scrollY < this.prev
      ? !isHide && this.setState({ isHide: true })
      : isHide && this.setState({ isHide: false });

    this.prev = window.scrollY;
  }
  componentDidMount() {
    window.addEventListener('scroll', this.hideBar);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.hideBar);
  }

  render() {
    const { children, sticky } = this.props;
    let classHide = this.state.isHide ? 'bottomBarHide' : '';

    return (
      <ThemeProvider theme={theme}>
        <Location>
          {({ navigate, location }) => (
            <Fragment>
              <GlobalStyle />

              {onePageNav && (
                <OnePageHeader
                  navLinks={onePageNav}
                  location={location}
                  sticky={sticky}
                />
              )}
              {children}
              <FooterSimple />
            </Fragment>
          )}
        </Location>
      </ThemeProvider>
    );
  }
}

export default OnePageLayout;

OnePageLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};
