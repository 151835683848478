import React from 'react';
import Modal from 'react-modal';
import Button from 'Components/Button';
import styled, { css } from 'styled-components';

const containerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  height: 375px;
  margin: 0 auto;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  position: absolute;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  padding: 30px;
  background-color: #fff;

  &:focus {
    outline: none;
  }
`;

export default function CalendarModal({ children, isOpen, onRequestClose }) {
  return (
    <Modal
      className={'calendarModal'}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
    >
      <h4 style={{ padding: '12px', background: '#ddd' }}>Add to Calendar</h4>
      <div style={{ padding: '1rem' }}>{children}</div>
      <Button onClick={onRequestClose}>Cancel</Button>
    </Modal>
  );
}
