import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Collapsible from 'react-collapsible';

import {
  BackgroundImage,
  BackgroundVideo,
  EventCalendarCard,
  EventCalendarList,
} from 'Components';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  Title,
  Subtitle,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
  Wrapper,
} from 'Styles';

// const Wrapper = styled.div`
//   color: ${props =>
//     props.dark ? theme.colors.black.base : theme.colors.white.base};
//   min-height: ${props => (props.big ? '400px;' : '100px;')};
//   background: none;
//   position: relative;
//   overflow: hidden;
//   display: flex;
//   flex-direction: column-reverse;

//   align-items: center;
//   margin: 0 auto;
//   @media (min-width: ${theme.breakpoints.s}) {
//   }
//   @media (min-width: ${theme.breakpoints.m}) {
//     flex-direction: row;
//     flex-direction: ${props => (props.layout == 'ltr' ? `row` : `row-reverse`)};
//     margin: 0 auto 0;
//   }
//   @media (min-width: ${theme.breakpoints.l}) {
//   }
//   .gatsby-image-wrapper {
//     position: initial !important;
//   }
// `;

const Content = styled.div`
  width: 100%;
  padding: 1rem 0 0;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  .Collapsible {
    overflow: hidden;
    .Collapsible__trigger {
      color: ${theme.colors.black.base};
      font-weight: bold;
      font-size: ${theme.fontSize.p};
      width: 100%;
      max-width: ${theme.layout.base};
      padding: 1rem 0;
      margin: 0;
      cursor: pointer;
      position: relative;
      &:hover {
        color: ${theme.colors.primary.base};
        &:after {
          content: '';
          display: inline-block;
          height: 0.5em;
          vertical-align: bottom;
          width: 100%;
          margin-right: -100%;
          margin-left: 10px;
          border-top: 1px solid ${theme.colors.primary.base};
        }
      }
      &:before {
        // content: 'Expand';
        // background: ${theme.colors.black.base};
        color: ${theme.colors.primary.base};
        // font-size: 10px;
        // border-radius: ${theme.borderRadius.elements};
        // display: inline-block;
        // padding: 4px 6px;
        // position: absolute;
        // right: 0;
        // left: auto;
        background-image:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDE4IDEyIj4KICA8cGF0aCBmaWxsPSIjMGUxZTI1IiBkPSJNMy4xNC42NjdsNS4wMSA0LjU5Yy40Ny40MyAxLjIzLjQzIDEuNyAwbDUuMDEtNC41OUwxOCAzLjU0NCA5Ljg1IDExLjAxYy0uNDcuNDMtMS4yMy40My0xLjcgMEwwIDMuNTQ1IDMuMTQuNjY3eiIvPgo8L3N2Zz4K);
        background-position:center;
        background-repeat:no-repeat;
        background-size:.8em;
        content:"";
        height:2rem;
        position:absolute;
        right:0;
        top:8px;
        transition:transform .1s cubic-bezier(.07,.49,0,1.05);
        width:2rem;
        background-color: ${theme.colors.white.grey};
        border-radius: ${theme.borderRadius.elements};
      }
      &:after {
        content: '';
        display: inline-block;
        height: 0.5em;
        vertical-align: bottom;
        width: 100%;
        margin-right: -100%;
        margin-left: 10px;
        border-top: 1px solid black;
      }
    }
    &.collapsible-open {
      .Collapsible__contentOuter {
        margin: 1rem 0;
      }
      .Collapsible__trigger.is-open {
        &:before {
          transform: rotate(-180deg);
        }

      }
    }
  }
`;

const Grid = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${theme.layout.base};
`;

const EventsListGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
  grid-gap: 1rem;
  .option-card {
    padding: 1rem;
    box-shadow: ${theme.shadow.feature.small.default};
  }
`;

const EventsTitle = styled.h4`
  color: ${theme.colors.black.base};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
  padding: 1rem 0;
  overflow: hidden;
  &:after {
    content: '';
    display: inline-block;
    height: 0.5em;
    vertical-align: bottom;
    width: 100%;
    margin-right: -100%;
    margin-left: 10px;
    border-top: 1px solid black;
  }
`;

const OptionTitle = styled.h4`
  color: ${theme.colors.black.base};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
`;

const OptionLocation = styled.div`
  display: flex;
  color: ${theme.colors.black.base};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
  margin: 0;
  img {
    height: 16px;
    width: auto;
    margin: 0 6px 0 0;
  }
`;

const OptionType = styled.h5`
  color: ${theme.colors.black.base};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
  margin: 0;
`;

const OptionAddress = styled.h5`
  color: ${theme.colors.black.base};
  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
  margin: 0;
`;

const EventsList = ({
  big,
  dark,
  layout,
  image,
  title,
  subtitle,
  copy,
  cta_action,
  cta_action_text,
  cta_url,
  cta_text,
  cta_url_1,
  cta_text_1,
  children,
  data,
  region,
}) => (
  <Wrapper layout={layout} dark={dark} big={big}>
    <Content>
      <Collapsible
        transitionTime={100}
        triggerTagName={'h4'}
        trigger={`Events in ${region}`}
        className={'collapsible-default'}
        openedClassName={'collapsible-open'}
      >
        <Grid>
          <EventsListGrid>
            {data.map((event, i) => (
              <EventCard key={i} className={`aaa`}>
                <Fade duration={1000}>
                  <EventCalendarList
                    full={true}
                    featured={false}
                    photo={''}
                    key={event.node.event.id}
                    event={event.node.event}
                    // share={this.toggleModal(
                    //   MODAL_SHARE_EVENT,
                    //   data.node.event
                    // )}
                    // calendar={this.toggleModal(
                    //   MODAL_ADD_CALENDAR,
                    //   data.node.event
                    // )}
                  />
                </Fade>
              </EventCard>
            ))}
          </EventsListGrid>
        </Grid>
      </Collapsible>
    </Content>
  </Wrapper>
);

export default EventsList;
