import styled from 'styled-components';
import theme from 'Theme';
const { colors, fontSize } = theme;

const EventsGrid = styled.div`
  margin: 2rem auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
  @media (min-width: ${theme.breakpoints.l}) {
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  }
  grid-gap: 16px;
`;

export default EventsGrid;
