import React from 'react';
import Link from 'gatsby-link';
import './styles.scss';
import { graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';

import classnames from 'classnames';
import CategoryTagList from '../CategoryTagList';
import DateTag from '../DateTag';
import Countdown from 'Components/Countdown';
import moment from 'moment';
import BackgroundImage from 'gatsby-background-image';

class EventListInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    let {
      featured,
      countdown,
      photo,
      eventCity,
      category,
      eventURL,
      date,
      eventStart,
      eventEnd,
      eventStartDate,
      eventEndDate,
    } = this.props;
    return (
      <a href={`event/${eventURL}`} target="_blank" className="">
        <BackgroundImage
          Tag="section"
          className="event-list-link"
          fluid={photo}
        >
          <div className="event-list-contents">
            <DateTag date={date} />
            <div className="event-list-featured">
              <div className="event-list-info">
                <Fade top delay={500}>
                  <CategoryTagList category={category} />
                </Fade>
                <Fade bottom delay={500}>
                  <div className="event-card-city">{eventCity}</div>
                </Fade>
              </div>
              {countdown && (
                <Countdown
                  className="event-list-countdown"
                  timeTillDate={moment(eventStartDate)}
                  timeFormat="ddd, DD MMM YYYY HH:mm"
                />
              )}
            </div>
          </div>
        </BackgroundImage>
      </a>
    );
  }
}

export default EventListInfo;
