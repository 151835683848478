import styled from 'styled-components';
import { theme, mixins } from 'Styles';

export const Content = styled.div`
  display: block;
  padding: 4rem 1rem;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  background-color: ${props =>
    props.dark ? theme.colors.black.base : theme.colors.background.light};
  color: ${props =>
    props.dark ? theme.colors.white.base : theme.colors.black.base};
  border-color: ${props =>
    props.dark ? theme.colors.white.base : theme.colors.black.base};
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;
export const Title = styled.h2`
  color: inherit;
  font-weight: bold;
  max-width: ${theme.layout.base};
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;
export const Subtitle = styled.p`
  color: inherit;
  font-weight: bold;
  max-width: ${theme.layout.blog};
  margin: 0 auto;
  & + a {
    margin-top: 1rem;
  }
  text-align: center;
`;
