import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import YouTube from '@u-wave/react-youtube';
import Vimeo from '@u-wave/react-vimeo';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  Title,
  Subtitle,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
} from 'Styles';

const TextBlock = styled.div`
  text-align: left;
  width: 100%;
`;

const Wrapper = styled.div`
  color: ${props =>
    props.image
      ? props.dark
        ? theme.colors.white.base
        : theme.colors.black.base
      : theme.colors.black.base};
  background: ${props =>
    props.vimeoId || props.youtubeId ? theme.colors.white.base : ''};

  ${props =>
    props.image && (!props.vimeoId || !props.youtubeId)
      ? mixins.backgroundBefore
      : ''};

  min-height: ${props => (props.big ? '300px;' : '100px;')};
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  .gatsby-image-wrapper {
    position: initial !important;
  }
`;

const Content = styled.div`
  color: inherit;
  width: 100%;
  position: relative;
  z-index: 3;


  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // align-items: flex-start;
  // font-size: ${theme.fontSize.large};
  // padding: 2rem 1rem;
  // margin: 0 auto;
  // max-width: ${theme.layout.base};
  // @media (min-width: ${theme.breakpoints.s}) {
  //   padding: 4rem 2rem;
  // }
  // @media (min-width: ${theme.breakpoints.m}) {
  // }
  // @media (min-width: ${theme.breakpoints.l}) {
  // }

  margin: 1rem auto;
  padding: 2rem 1rem;
  max-width: ${theme.layout.base};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  @media (min-width: ${theme.breakpoints.s}) {
    padding: 4rem 2rem;
  }
  @media (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: repeat(auto-fill, minmax(${props =>
      props.grid ? '100%' : '48%'}, 1fr));
  }
  @media (min-width: ${theme.breakpoints.l}) {
    grid-template-columns: repeat(auto-fill, minmax(${props =>
      props.grid ? '100%' : '48%'}, 1fr));
  }
  grid-gap: 16px;
`;

const Video = styled.div`
  color: inherit;
  position: absolute;
  // height: 380px;
  width: 100%;
  top: -100%;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  max-width: ${theme.layout.base};
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  .video-foreground {
    box-shadow: ${theme.shadow.feature.small.default};
  }
`;

const SectionCallout = ({
  big,
  dark,
  grid,
  image,
  video,
  title,
  subtitle,
  cta_action,
  cta_action_text,
  cta_url,
  cta_text,
  cta_url_1,
  cta_text_1,
  youtubeId,
  vimeoId,
  children,
}) => (
  <>
    <Wrapper
      dark={dark}
      big={big}
      image={image}
      youtubeId={youtubeId}
      vimeoId={vimeoId}
    >
      {image && (
        <Img
          alt={title}
          title={title}
          objectFit="contain"
          objectPosition="50% 50%"
          fluid={image.childImageSharp.fluid || {} || [] || ''}
        />
      )}
      <Content grid={grid}>
        <>
          <div>
            {title && <Title>{title}</Title>}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
            {children && children}
            <div>
              {children && <br />}
              {cta_text && cta_url && (
                <ActionButtonAlt href={cta_url}>{cta_text}</ActionButtonAlt>
              )}
              {cta_text_1 && cta_url_1 && (
                <ActionButtonAlt href={cta_url_1}>{cta_text_1}</ActionButtonAlt>
              )}
              {cta_action && cta_action_text && (
                <ActionButtonAlt onClick={cta_action}>
                  {cta_action_text}
                </ActionButtonAlt>
              )}
            </div>
          </div>
          {(vimeoId || youtubeId) && (
            <div style={{ position: 'relative' }}>
              <Video>
                {vimeoId && !youtubeId && (
                  <div className="">
                    <Vimeo
                      video={vimeoId}
                      width={640}
                      height={480}
                      autoplay={false}
                      volume={1}
                      loop={true}
                      background={false}
                      controls={true}
                      className="video-iframe"
                    />
                  </div>
                )}

                {!vimeoId && youtubeId && (
                  <div className="">
                    <YouTube
                      video={youtubeId}
                      width={640}
                      height={480}
                      autoplay={false}
                      volume={1}
                      loop={0}
                      background={false}
                      controls={true}
                      className="video-iframe"
                      showRelatedVideos={false}
                    />
                  </div>
                )}
              </Video>
            </div>
          )}
        </>
      </Content>
    </Wrapper>
    {(vimeoId || youtubeId) && <Gap />}
  </>
);

export default SectionCallout;
