import styled from 'styled-components';
import { theme } from 'Styles';
import mixins from './mixins';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 2rem 1rem;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    padding: 2rem;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  max-width: ${theme.layout.base};
  & + section {
    ${mixins.hairLineTop};
    padding-top: 2rem;
  }
`;

export default Section;
