import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

import { BackgroundImage, BackgroundVideo } from 'Components';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  Title,
  Subtitle,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
} from 'Styles';

const Wrapper = styled.div`
  color: ${props =>
    props.dark ? theme.colors.black.base : theme.colors.white.base};
  min-height: ${props => (props.big ? '400px;' : '100px;')};
  background: none;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  max-width: ${props => (props.image ? theme.layout.base : 'auto')};

  align-items: center;
  margin: 0 auto;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    flex-direction: row;
    flex-direction: ${props => (props.layout == 'ltr' ? `row` : `row-reverse`)};
    margin: 0 auto 0;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  .gatsby-image-wrapper {
    position: initial !important;
  }
`;

export const Content = styled.div`
  // ${mixins.backgroundBefore};
  display: block;
  padding: 4rem 1rem;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  background-color: ${props =>
    props.dark ? theme.colors.black.base : theme.colors.background.light};
  color: ${props =>
    props.dark ? theme.colors.white.base : theme.colors.black.base};
  border-color: ${props =>
    props.dark ? theme.colors.white.base : theme.colors.black.base};
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const FooterCTA = ({
  big,
  dark,
  layout,
  image,
  title,
  subtitle,
  copy,
  cta_action,
  cta_action_text,
  cta_url,
  cta_text,
  cta_url_1,
  cta_text_1,
  children,
}) => (
  <>
    <Wrapper layout={layout} dark={dark} big={big} image={image}>
      <Content dark={dark}>
        <CenterSection.Title>{title}</CenterSection.Title>
        <CenterSection.Subtitle>{subtitle}</CenterSection.Subtitle>
        {cta_url && cta_text && (
          <ActionButton style={{ zIndex: 1, marginTop: '1rem' }} href={cta_url}>
            {cta_text}
          </ActionButton>
        )}
        {cta_action && cta_action_text && (
          <ActionButton
            style={{ zIndex: 1, marginTop: '1rem' }}
            href={cta_action}
          >
            {cta_action_text}
          </ActionButton>
        )}
      </Content>
    </Wrapper>
    {image && (
      <Wrapper layout={layout} dark={dark} big={true} image={image}>
        <Img
          alt={title}
          title={title}
          objectFit="contain"
          objectPosition="50% 50%"
          fluid={image || {} || [] || ''}
        />
      </Wrapper>
    )}
  </>
);

export default FooterCTA;
