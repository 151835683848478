const { GA_TRACKING_ID } = process.env;

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'DatumFlow', // Navigation and Site Title
  titleAlt: 'DatumFlow', // Title for JSONLD
  logo: 'static/logo/logo.png', // Used for SEO
  favicon: 'static/logo/favicon.png',
  banner: 'static/logo/logo.png',
  // JSONLD / Manifest
  author: 'DatumFlow', // Author for schemaORGJSONLD
  themeColor: '#3e7bf2',
  backgroundColor: '#d3e0ff',
  twitter: '@datumflow', // Twitter Username

  slogan: 'A Mevno collaboration platform',
  brand: 'DatumFlow',
  company: 'DatumFlow',
  audience: '',
  brandBusiness: 'DatumFlow',
  siteTitle: 'DatumFlow',
  description:
    'DatumFlow | Thought leading collaboration for the global CIO and IT leader',
  siteLanguage: 'en', // Language Tag on <html> element
  siteKeywords: '',
  url: 'https://www.datumflow.com', // Domain of your site. No trailing slash!
  siteUrl: 'https://www.datumflow.com',
  adminEmail: 'abuirshaid@me.com',
  noreplayEmail: 'no-replay@datumflow.com',
  founder: 'Adi Hazeem',
  foundingDate: '2019',
  googleVerification: '',

  brands: [],

  contact: {
    name: 'DatumFlow',
    legalName: 'DatumFlow LLC',
    location: 'Schaumburg, IL',
    email: 'info@datumflow.com',
    phone: '+1 (847) 758-4811',
    address: {
      street: '1204 E Algonquin Rd',
      city: 'Schuamburg',
      state: 'IL',
      country: 'United States',
      zipCode: '60173',
    },
  },

  twitterHandle: 'DatumFlow',
  social: {
    facebook: '@',
    twitter: '@datumflow',
    instagram: '',
    linkedin: '',
  },
  socialMedia: [
    {
      name: 'Linkedin',
      url: '',
    },
    {
      name: 'Instagram',
      url: '',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/datumflow',
    },
  ],

  nav: [
    // {
    //   name: 'Home',
    //   url: '/',
    // },
    // {
    //   name: 'Events',
    //   url: '/events',
    // },
    // {
    //   name: 'Blog',
    //   url: '/',
    // },
    // {
    //   name: 'Media',
    //   url: '/media',
    // },
    // {
    //   name: 'Sponsors',
    //   url: '/sponsors',
    // },
    // {
    //   name: 'Testimonials',
    //   url: '/testimonials',
    // },
    // {
    //   name: 'Partner',
    //   url: '/partner',
    // },
    // {
    //   name: 'About',
    //   url: '/about',
    // },
  ],

  onePageNav: [
    // {
    //   name: 'Speakers',
    //   url: '#speakers',
    // },
    // {
    //   name: 'Schedule',
    //   url: '#schedule',
    // },
    // {
    //   name: 'Venue',
    //   url: '#venue',
    // },
    // {
    //   name: 'Sponsors',
    //   url: '#sponsors',
    // },
    // {
    //   name: 'Help?',
    //   url: '#faq',
    // },
  ],

  brandsColors: {
    ciosynergy: '#149cea',
    dinners: '#9254FF',
    secureciso: '#ff0000',
    cxosports: '#00ff00',
    eventpopulation: '#ff0000',
    panels: '#149cea',
    cfo: '#47b946',
  },

  headerHeight: 80,
  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',
  googleAnalyticsID: GA_TRACKING_ID,
};
