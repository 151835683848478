import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme, mixins, ActionButton } from 'Styles';

const BackgroundContent = styled.div`
  color: ${theme.colors.white.base};
  font-size: ${theme.fontSize.h1};
  font-weight: bold;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  flex-direction: ${props => (props.layout == 'ltr' ? `row` : `row-reverse`)};

  display: flex;
  flex-direction: column;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    flex-direction: ${props => (props.layout == 'ltr' ? `row` : `row-reverse`)};
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  justify-content: space-between;
  margin: 0 auto;
  padding: 4rem 0;
  max-width: ${theme.layout.base};
  text-align: ${props => (props.layout == 'ltr' ? `right` : `left`)};
  width: 100%;
  max-width: ${theme.layout.base};
  padding: 0 2rem;
  align-items: center;
  font-size: ${theme.fontSize.large};

  // @media (min-width: ${theme.breakpoints.s}) {
  // }
  // @media (min-width: ${theme.breakpoints.m}) {
  //   flex-direction: ${props =>
    props.layout == 'ltr' ? `row` : `row-reverse`};
  // }
  // @media (min-width: ${theme.breakpoints.l}) {
  // }
  // justify-content: space-between;
  // margin: 0 auto;
  // padding: 4rem 0;
  // max-width: ${theme.layout.base};
`;

const TextBlock = styled.div`
  margin-bottom: 1rem;
  text-align: left;
  @media (min-width: ${theme.breakpoints.m}) {
    margin: ${props => (props.layout == 'ltr' ? `0 4rem 0 0` : `0 0 0 4rem`)};
  }
`;

const Title = styled.h3`
  color: ${theme.colors.white.base};
  font-size: ${theme.fontSize.h3};
  font-weight: bold;
  max-width: ${theme.layout.base};
`;

const Subtitle = styled.p`
  color: ${theme.colors.white.base};
  font-size: ${theme.fontSize.medium};
  margin-bottom: 0;
  & + a {
    margin-top: 1rem;
  }
`;

const ActionAlt = styled.a`
  color: ${theme.colors.white.base};
  font-size: ${theme.fontSize.small};
  margin-top: 1rem;
  white-space: nowrap;
  border-radius: ${theme.borderRadius.elements};
  padding: 6px 18px;
  background: #cc5100;
  border: 1px solid #cc5100;
  display: block;
  & + button,
  & + a {
    margin-top: 8px;
  }
  @media (min-width: ${theme.breakpoints.s}) {
    & + button,
    & + a {
      margin: 0 0 0 8px;
    }
  }
  @media (min-width: ${theme.breakpoints.m}) {
    display: inline-block;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const Wrapper = styled.div`
  background: none;
  min-height: 400px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  ${mixins.backgroundBefore};
  align-items: center;
  padding: 4rem 2rem;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    padding: 2rem 2rem;
    width: 100%;
  }
  @media (min-width: ${theme.breakpoints.l}) {
    padding: 2rem 0;
  }
  .gatsby-image-wrapper {
    position: initial !important;
  }
`;

const Content = styled.div`
  color: ${theme.colors.white.base};
  max-width: ${theme.layout.base};
  width: 100%;
  max-width: ${theme.layout.base};
  position: relative;
  z-index: 1;
  // margin: 4rem 0;
  // @media (max-width: ${theme.breakpoints.s}) {
  //   margin: 2rem 0;
  // }
  // @media (max-width: ${theme.breakpoints.m}) {
  //   margin: 2rem 0;
  // }
  // @media (max-width: ${theme.breakpoints.l}) {
  //   margin: 2rem 0;
  // }

  display: flex;
  flex-direction: row;
  flex-direction: ${props => (props.layout == 'ltr' ? `row` : `row-reverse`)};

  display: flex;
  flex-direction: column;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    flex-direction: ${props => (props.layout == 'ltr' ? `row` : `row-reverse`)};
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${theme.layout.base};
  text-align: ${props => (props.layout == 'ltr' ? `right` : `left`)};
  max-width: ${theme.layout.base};
  align-items: center;
  font-size: ${theme.fontSize.large};
`;

const SectionBackground = ({
  layout,
  image,
  title,
  subtitle,
  cta_text,
  cta_url,
  cta_text_1,
  cta_url_1,
  children,
}) => (
  <Wrapper>
    {image && (
      <Img
        alt={title}
        title={title}
        objectFit="contain"
        objectPosition="50% 50%"
        fluid={image.childImageSharp.fluid || {} || [] || ''}
        // fixed={image.childImageSharp.fixed}
      />
    )}
    <Content layout={layout}>
      <TextBlock layout={layout}>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        {cta_text && <ActionButton href={cta_url}>{cta_text}</ActionButton>}
        {cta_text_1 && (
          <ActionButton href={cta_url_1}>{cta_text_1}</ActionButton>
        )}
      </TextBlock>
      {children && children}
    </Content>
  </Wrapper>
);

export default SectionBackground;

SectionBackground.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

SectionBackground.defaultProps = {
  children: false,
  image: false,
  title: false,
};
