import styled from 'styled-components';
import theme from 'Theme';
const { colors, fontSize } = theme;

export const Grid = styled.div`
  margin: 1rem auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  @media (min-width: ${theme.breakpoints.s}) {
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  }
  @media (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  }
  @media (min-width: ${theme.breakpoints.l}) {
    grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));
  }
  grid-gap: 16px;
`;

export const Card = styled.div`
  background: #fff;
  border-radius: ${theme.borderRadius.elements};
  box-shadow: ${theme.shadow.feature.small.default};
  border: 1px solid #ddd;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  @media (min-width: ${theme.breakpoints.s}) {
    align-items: center;
  }
`;

export const Logo = styled.div`
  display: flex;
  position: relative;
  color: ${theme.colors.black.base};
  font-size: ${theme.fontSize.medium};
  font-weight: bold;
  padding: 1rem;

  picture {
    img {
      filter: grayscale(10);
      opacity: 0.6 !important;
      &:hover {
        filter: none;
        opacity: 1 !important;
      }
    }
  }
`;
