import styled, { createGlobalStyle, css } from 'styled-components';
import { theme, colors, fontSize } from 'Styles';
import media from './media';
import mixins from './mixins';
import Shybar from './Shybar';
import Carousel from './Carousel';
import AddToCalendarStyles from './AddToCalendarStyles';

const Container = styled.div`
  padding: 2rem;
`;

const btn = (bg, text) => css`
  white-space: nowrap;
  display: inline-block;
  border-radius: ${theme.borderRadius.elements};

  padding: 6px 18px;
  font-size: 14px;
  font-weight: bold;
  &:visited {
    color: white;
  }
  background: ${bg};
  color: ${text};
  border: 1px solid ${text};
  &:hover {
    background: ${text};
    &[disabled] {
      background: ${text};
    }
  }
  &:visited {
    color: black;
  }
  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const btnDefault = css`
  ${btn('#ffffff', '#888888')} color: #212121;
  // btn('#fff', '#888')
`;

const btnPrimary = btn(
  '${theme.colors.primary.base}',
  '${theme.colors.white.base}'
);

const GlobalStyle = createGlobalStyle`

  .hide {
    display: none !important;
  }

  html {
    position: relative;
    min-height: 100%;
  }

  body {
    font-size: 14px;
    @media (min-width: ${theme.breakpoints.s}) {
    }
    @media (min-width: ${theme.breakpoints.m}) {
      font-size:16px;
    }
    margin: 0;
    margin-bottom: 0;
    @media (min-width: ${theme.breakpoints.s}) {
    }
    @media (min-width: ${theme.breakpoints.m}) {
    }
    @media (min-width: ${theme.breakpoints.l}) {
      margin-bottom: 85px;
    }
  }
  header {
    .gatsby-image-wrapper {
      position: initial !important;
      overflow: visible !important;
    }
  }

  .btn-close {
    position: fixed;
    top: 10px;
    right: 10px;
    background: #000;
    color: #fff;
    border-radius: ${theme.borderRadius.elements};
    width: 40px;
    height: 40px;
    z-index: 9;
  }
  pre {
    border: 1px solid #ccc;
    background: rgba(0, 0, 0, 0.1);
    padding: 20px;
    overflow: scroll;
  }

  // .gatsby-image-wrapper {
  //   overflow: visible !important;
  // }

  // .onePageHeroWrapper {
  //   .gatsby-image-wrapper {
  //     display:none;
  //   }
  // }

  .video-background {
    position: absolute;
    background: #000;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    display:none;
    @media (min-width: ${theme.breakpoints.s}) {
    }
    @media (min-width: ${theme.breakpoints.m}) {
      display:block;
    }
    @media (min-width: ${theme.breakpoints.l}) {
    }
  }
  .video-foreground,
  .video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  @media (min-aspect-ratio: 16/9) {
    .video-foreground { height: 300%; top: -100%; }
  }
  @media (max-aspect-ratio: 16/9) {
    .video-foreground { width: 400%; height: 300%; top: -100%; left: -100%; }
  }

  iframe {
    margin: 0;
  }

  // VIMEO
  .video-section-foreground,
  .video-section-background iframe {
    width: 100%;
    position: relative;
    height: 300px;
  }
  // @media (min-aspect-ratio: 16/9) {
  //   .video-section-foreground { height: 300%; top: -100%; }
  // }
  // @media (max-aspect-ratio: 16/9) {
  //   .video-section-foreground { width: 300%; left: -100%; }
  // }



  // YOUTUBE
  .video-section-youtube-foreground,
  .video-section-youtube-background {
    width: 100%;
    iframe {
      width: 100%;
      position: relative;
      min-height: 300px;
    }
  }
  // @media (min-aspect-ratio: 16/9) {
  //   .video-section-youtube-foreground { height: 300%; top: -100%; }
  // }
  // @media (max-aspect-ratio: 16/9) {
  //   .video-section-youtube-foreground { width: 300%; left: -100%; }
  // }

  // *,
  // *:before,
  // *:after {
  //   box-sizing: inherit;
  // }
  // html {
  //   text-rendering: optimizeLegibility;
  //   overflow-x: hidden;
  //   box-sizing: border-box;
  //   -ms-overflow-style: scrollbar;
  //   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  //   -webkit-font-smoothing: antialiased;
  //   -moz-osx-font-smoothing: grayscale;
  // }
  // html,
  // body {
  //   width: 100%;
  //   height: 100%;
  //   margin: 0;
  //   padding: 0;
  //   background-color:#ff0000;
  // }

  // body {
  //   display: flex;
  //   flex-direction: column;
  //   min-height: 100vh;
  // }
  // a {
  //   color: ${theme.colors.link};
  //   transition: color 0.5s;
  //   text-decoration: none;
  // }
  // a:hover {
  //   text-decoration: none;
  //   color: ${theme.colors.linkHover};
  // }

  html {
    box-sizing: border-box;
    width: 100%;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: ${theme.colors.background.light};
    color: ${theme.colors.black.base};
    &.hidden {
      overflow: hidden;
    }
    &.blur {
      overflow: hidden;
      #root > .container > * {
        filter: blur(5px) brightness(0.7);
        transition: ${theme.transition};
        pointer-events: none;
        user-select: none;
      }
    }
  }

  ::selection {
    background-color: ${theme.colors.highlight};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;
    color: ${theme.colors.black.base};
    margin: 0 0 10px 0;
  }

  #root {
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 100%;
  }


  img {
    width: 100%;
    max-width: 100%;
    vertical-align: middle;
  }

  svg {
    width: 100;
    height: 100%;
    fill: currentColor;
    vertical-align: middle;
  }

  svg.brand-logo {
    width: 48px;
    height: 100%;
    fill: currentColor;
    vertical-align: middle;
  }

  a {
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    color: inherit;
    position: relative;
    transition: ${theme.transition};
    cursor: pointer;
    transition: all ${theme.transitions.default.duration};
    &:hover,
    &:focus {
      color: ${theme.colors.primary.base};
      outline: 0;
    }
  }

  button {
    cursor: pointer;
    border: 0;
    border-radius: ${theme.borderRadius.elements};
    color: inherit;
    transition: all ${theme.transitions.default.duration};
    &:hover,
    &:focus {
      color: #fff;
      background: ${theme.colors.primary.base};
      // outline: 0;
      outline-color: ${theme.colors.primary.base};
    }
  }

  input, textarea {
    border-radius: ${theme.borderRadius.elements};
    outline: 0;

    &:focus {
      outline: 0;
    }
    &::placeholder {
    }
    &:focus,
    &:active {
      &::placeholder {
        opacity: 0.5;
      }
    }
  }

  p {
    margin: 0 0 15px 0;
  }

  ul, ol {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .gatsby-image-outer-wrapper {
    height: 100%;
  }

  .fadeup-enter {
    opacity: 0.01;
    transform: translateY(20px);
    transition: opacity 300ms ${theme.easing}, transform 300ms ${theme.easing};
  }

  .fadeup-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms ${theme.easing}, transform 300ms ${theme.easing};
  }

  .fadedown-enter {
    opacity: 0.01;
    transform: translateY(-20px);
    transition: opacity 300ms ${theme.easing}, transform 300ms ${theme.easing};
  }

  .fadedown-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms ${theme.easing}, transform 300ms ${theme.easing};
  }

  .fade-enter {
    opacity: 0.01;
    transition: opacity 1000ms ${theme.easing};
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms ${theme.easing};
  }

  .share {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:1rem;
  }
  .links {
    display: flex;
    flex-direction: row;
  }
  .SocialMediaShareButton {
    cursor: pointer;
  }
  .label {
    font-size: 1.2em;
    margin: 0 1em 1em;
  }
  @from-width tablet {
    .share {
      flex-direction: row;
      margin: 20px;
    }
    .label {
      margin: 20px;
    }
  }

  .ReactModalPortal .headroom-wrapper {
    display:none;
  }

  .ReactModalPortal footer {
    display:none;
  }

  .ReactModal__Content {
    border-radius: 0 !important;
    padding: 0 !important;
    border: none !important;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    transition: all 400ms ease-in-out;
    transform: translateY(30px);
    opacity: 0;
    box-shadow: 0 20px 20px rgba(0,0,0,0.3);
    &--after-open {
      opacity: 1;
      transform: translateY(0);
    }

    &--before-close {
      opacity: 0;
      transform: translateY(30px);
    }

    section {
      @media (min-width: ${theme.breakpoints.s}) {
        margin: 1rem;
      }
      @media (min-width: ${theme.breakpoints.m}) {
        margin: 2rem;
      }
      @media (min-width: ${theme.breakpoints.l}) {
        margin: 4rem 2rem;
      }
    }

    max-width: ${theme.breakpoints.s};

    .hidden-sm {
      display: none;
      @media (min-width: ${theme.breakpoints.s}) {
      }
      @media (min-width: ${theme.breakpoints.m}) {
        display: block;
      }
      @media (min-width: ${theme.breakpoints.l}) {
      }
    }

    @media (min-width: ${theme.breakpoints.s}) {
      border-radius: ${theme.borderRadius.ui} !important;
      width: 80%;
      height: 80%;
    }
    @media (min-width: ${theme.breakpoints.m}) {
      width: 70%;
      height: 70%;
      // top: 20px !important;
      // left: 20px !important;
      // right: 20px !important;
      // bottom: 20px !important;
    }
    @media (min-width: ${theme.breakpoints.l}) {
    }
  }

  .ReactModal__Overlay {
    z-index:99998;
    opacity: 0;
    transition: opacity 400ms ease-in-out;
    background-color: rgba(130, 130, 130, 0.7) !important;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
    // transform: translateY(0);
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
    // transform: translateY(0);
  }


  .react-tabs {
    padding: 0;
    background: none;
    border-radius: ${theme.borderRadius.ui};
    .react-tabs__tab {
      border-radius: ${theme.borderRadius.elements};
      padding: 0;
    }
    .react-tabs__tab-list {
      border:none;
      border-radius: ${theme.borderRadius.ui};
      margin:0;
      padding: 1rem;
      background: #ffffff;
    }
    .react-tabs__tab {
      border:none;
      &:focus {
        box-shadow: none;
        &:after {
          content: "";
          position: absolute;
          height: 0px;
          left: -4px;
          right: -4px;
          bottom: -5px;
          background: #fff;
      }
      }
    }

    .react-tabs__tab--selected {
      background: none;
      border-radius: ${theme.borderRadius.elements};

    }
  }
  .grid-container {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4,280px);
  }

  // [aria-current='page'] {
  //   display: none;
  // }

  select {
    display: block;
    width: 100%;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: ${theme.borderRadius.default};
  }
  .btn {
    &:focus {
      // outline: none;
    }
  }
  ${Shybar}
  ${Carousel}
  ${AddToCalendarStyles}

  .countdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px 0;
    min-height: 48px;
    @media (min-width: ${theme.breakpoints.s}) {
    }
    @media (min-width: ${theme.breakpoints.m}) {
      margin: 0;
    }
    @media (min-width: ${theme.breakpoints.m}) {
    }
}

.countdown-item {
    color: ${theme.colors.primary.base};
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 4px;
    padding: 0 4px;
    position: relative;
    background-color: #fff;
    border-radius: ${theme.borderRadius.ui};
    font-size: 20px;
    font-weight:bold;
    z-index: 2;
    box-shadow: 0px 6px 30px 6px rgba(0, 0, 0, 0.2);
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    box-sizing: content-box;
}

.countdown-item span {
    color: #333;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
}
.react-reveal {
  width:100%;
}

.event-list-link {
  border-radius: ${theme.borderRadius.elements};

  overflow: hidden;
  z-index:1;
  &::before {
    border-radius: ${theme.borderRadius.elements};
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: opacity 0.5s ease 500ms;
    background-position: center;
    background-repeat: no-repeat;
    background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ) !important;
    z-index: 1 !important;
  }
  .event-list-contents {
    margin-bottom: 6px;
    display: grid;
    grid-template-columns: 64px 1fr;
    align-items: center;
    width: 100%;
    min-height: 70px;
  }
}
.category-tag-list, .date-tag {
  border-radius: ${theme.borderRadius.elements};
}

.event-list-description-box {
  z-index:1;
  box-shadow: ${theme.shadow.card};
  margin: -1rem 1rem 0;
  border-radius: ${theme.borderRadius.elements};

  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    border-radius: ${theme.borderRadius.elements};
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
}
.event-list-countdown {
  z-index:1;
}

.social-network-share-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 9;
  top: 200px;
  right: 10px;
  transform: translateX(60px);
  transition: all 400ms ease-in-out;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
  padding: 4px;
  border-radius: ${theme.borderRadius.elements};


  .social-network-share-button {
    & + .social-network-share-button {
      margin-top: 4px;
    }
  }
}

.social-share-closed {
  .social-network-share-container {
    transform: translateX(0px);
    position: fixed;
    z-index: 9;
    top: 200px;
    right: 10px;
    .social-network-share-button {
      & + .social-network-share-button {
        margin-top: 4px;
      }
    }
  }
}

.bottomBar{
  position: fixed;
  height: 40px;
  left: 0;
  bottom: 0;
  transform: translateY(0px);
  background-color: #fff;
  width: 100%;
  text-align: center;
  font-size: 22px;
  box-shadow: 0px -12px 20px rgba(0,0,0,0.1);
  transition: all ${theme.transitions.default.duration}
  z-index:9;
}

.bottomBarHide{
  transform: translateY(40px);
}

.accordion__item {
  margin-bottom: 4px;
  // box-shadow: ${theme.shadow.card};

}

.accordion__heading {
  background: #e1e1e1;
  border-radius: ${theme.borderRadius.ui};
  &:hover, &:focus {
    color: #fff;
    background: ${theme.colors.primary.base};
    border-radius: ${theme.borderRadius.ui};
    border-color: ${theme.colors.primary.base};
    outline: 0;
    transition: ${theme.transitions.default.transition};
  }
}

.accordion__panel {
  padding: ${theme.padding};
  border-radius: ${theme.borderRadius.ui};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: #d1d1d1;
  text-align: left;
  font-size: ${theme.fontSize.small};
  p {
    margin: 0;
  }
}

// .accordion__button {
//   padding: 4px ${theme.padding};
//   outline: none;
//   cursor: pointer;
//   display: grid;
//   grid-template-columns: 100px 1fr;
//   align-items: center;
//   margin: 0;

//   &[aria-expanded="true"]{
//     color: #fff;
//     background: ${theme.colors.primary.base};
//     border-radius: ${theme.borderRadius.elements};
//     border-color: ${theme.colors.primary.base};
//     outline: 0;
//   }
// }

.hamburger-button {
  border: 1px solid transparent;
}
.hamburger-inner {
}

.menu-open {
  .hamburger-button {
    background: rgba(0,0,0,0.2);
    border: 1px solid #ccc;
  }
  .hamburger-inner {
    background-color: black;
  }
}

.headroom--unpinned, .headroom--pinned {
  .hamburger-button {
    background: rgba(0,0,0,0.2);
    border: 1px solid #ccc;
  }
  .hamburger-inner {
    background-color: black;
  }
}


.infoBox {
  overflow: visible !important;

  .mapBoxStyle {
    display: inline-block;
    padding: 6px 14px;
    border-radius: ${theme.borderRadius.elements};
    background-color: #FFF;
    box-shadow: ${theme.shadow.card};
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    font-family: sans-serif;
    opacity: 0.7;
  }

  .mapBoxStyle:after, .mapBoxStyle:before {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 0;
    height: 0;
    border: solid transparent;
    content: " ";
    pointer-events: none;
  }

  .mapBoxStyle:after {
    margin-left: 0;
    border-width: 10px;
    border-color: rgba(0,0,0,0);
  }

  .mapBoxStyle:before {
    margin-left: 0;
    border-width: 10px;
    border-color: rgba(0,0,0,0);
    border-bottom-color: #fff;
  }
}

// [aria-hidden="true"] {
//   display:none;
// }

.calendarModal {
  position: fixed;
  background: #fff;
  max-width: 200px;
  height: 240px;
  border-radius: ${theme.borderRadius.ui};
  outline: none;
  overflow: hidden;
  a.btn {
    padding: 4px 1rem;
    font-size: 10px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    color: #2c3e50;
    background-color: transparent;
    border: 1px solid #2c3e50;
    border-radius: ${theme.borderRadius.elements};
    -webkit-transition: 0.25s;
    transition: 0.25s;
    width: 100%;
    margin: 4px 0;
  }
}
.subscribe-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    // .form-error {
    //   border: 1px solid #f00;
    // }
    // label {
    //   font-size: 12px;
    //   margin-bottom: 4px;
    //   position: relative;
    //   display: block;
    //   font-weight:bold;
    // }
    // .form-group-header {
    //   margin:1rem auto;
    // }
  }

  .Toaster {
    z-index: 99999999;
    position: absolute;
    .Toaster__manager-top {
      top: 20px !important;
      max-width: 600px !important;
      background: #fff;
      border-radius: 6px;
      .Toaster__message {
        padding: 10px;
        height: auto !important;
        .Toaster__message-wrapper {
          width: 100%;
          .Toaster__alert {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .Toaster__alert_text {
              color: #000;
              margin-right:1rem;
              font-size: 14px;
              font-weight: bold;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
`;

export default GlobalStyle;
