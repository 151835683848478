import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BackgroundImage, BackgroundVideo } from 'Components';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  Title,
  Subtitle,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
  Wrapper,
} from 'Styles';

// const Wrapper = styled.div`
//   color: ${props =>
//     props.dark ? theme.colors.black.base : theme.colors.white.base};
//   min-height: ${props => (props.big ? '400px;' : '100px;')};
//   background: none;
//   position: relative;
//   overflow: hidden;
//   display: flex;
//   flex-direction: column-reverse;

//   align-items: center;
//   margin: 0 auto;
//   @media (min-width: ${theme.breakpoints.s}) {
//   }
//   @media (min-width: ${theme.breakpoints.m}) {
//     flex-direction: row;
//     flex-direction: ${props => (props.layout == 'ltr' ? `row` : `row-reverse`)};
//     margin: 0 auto 0;
//   }
//   @media (min-width: ${theme.breakpoints.l}) {
//   }
//   .gatsby-image-wrapper {
//     position: initial !important;
//   }
// `;

const Content = styled.div`
  width: 100%;
  padding: 3rem 0;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const Grid = styled.div`
  padding: 2rem 0 0;
  margin: 0 auto;
  width: 100%;
  max-width: ${theme.layout.base};
`;

const OptionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
  grid-gap: 1rem;
  .option-card {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    box-shadow: ${theme.shadow.feature.small.default};
    border: 1px solid #ccc;
    svg {
      color: ${theme.colors.black.base};
      width: 16px;
      height: 16px;
    }
  }
  .option-popular {
    transform: scale(1.06);
    border: 1px solid ${theme.colors.primary.base};
    color: ${theme.colors.white.base};
    svg {
      color: ${theme.colors.primary.base};
      width: 16px;
      height: 16px;
    }
    .option-type {
      background: ${theme.colors.primary.base};
    }
  }
`;

const OptionType = styled.div`
  background: #ddd;
  padding: 2px 8px;
  border-radius: ${theme.borderRadius.elements};
  display: inline-block;
  width: auto;
  font-size: ${theme.fontSize.smallish};
  font-weight: bold;

  // display: flex;
  // width: 100%;
  max-width: ${theme.layout.base};
  margin: 0 0 6px;
  img {
    height: 16px;
    width: auto;
    margin: 0 6px 0 0;
  }
`;

const OptionPrice = styled.h4`
  color: ${theme.colors.black.base};
  font-size: ${theme.fontSize.smallish};

  font-weight: bold;
  width: 100%;
  max-width: ${theme.layout.base};
`;

const OptionDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const OptionList = styled.h5`
  color: ${theme.colors.black.base};
  font-size: ${theme.fontSize.smallish};
  font-weight: normal;
  width: 100%;
  max-width: ${theme.layout.base};
  margin: 0 0 1rem;
`;

const Options = ({
  big,
  dark,
  layout,
  image,
  title,
  subtitle,
  copy,
  cta_action,
  cta_action_text,
  cta_url,
  cta_text,
  cta_url_1,
  cta_text_1,
  children,
  data,
  region,
}) => (
  <Wrapper layout={layout} dark={dark} big={big}>
    <Content>
      <Title>Options for {region}</Title>
      <hr />
      <Grid>
        <OptionsGrid>
          {data.map((option, i) => (
            <div
              key={i}
              className={`option-card ${
                option.popular ? 'option-popular' : ''
              }`}
            >
              <div>
                <OptionType className="option-type">{option.type}</OptionType>
                <OptionPrice>{option.price}</OptionPrice>
                <hr />
              </div>
              <OptionDetails>
                <div>
                  {option.details.map((det, i) => (
                    <div key={i}>
                      <OptionList>
                        <svg viewBox="0 0 16 16">
                          <path
                            fill="inherit"
                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm-1.765-4.29a1 1 0 0 0 1.41.005l.664-.655L11.8 6.568a.765.765 0 0 0 0-1.081l-.288-.289a.765.765 0 0 0-1.08.001L6.948 8.682 5.581 7.314a.765.765 0 0 0-1.081 0l-.29.29a.765.765 0 0 0 0 1.08l1.36 1.36.665.666z"
                          />
                        </svg>{' '}
                        {det}
                      </OptionList>
                    </div>
                  ))}
                </div>

                {/* {option.popular && (
                  <div>
                    <br />
                    <ActionButton href={'#'}>Contact sales</ActionButton>
                  </div>
                )}
                {!option.popular && (
                  <div>
                    <br />
                    <ActionButtonAlt href={'#'}>Contact sales</ActionButtonAlt>
                  </div>
                )} */}
              </OptionDetails>
            </div>
          ))}
        </OptionsGrid>
      </Grid>
    </Content>
  </Wrapper>
);

export default Options;
