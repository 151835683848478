import styled from 'styled-components';
import { theme } from 'Styles';

const ActionHref = styled.a`
  color: ${theme.colors.white.base};
  font-size: ${theme.fontSize.small};
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  display: block;
  border-radius: ${theme.borderRadius.elements};
  padding: 6px 18px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid ${theme.colors.white.base};
  & + button,
  & + a {
    margin: 1rem 0 0 0;
  }
  @media (min-width: ${theme.breakpoints.s}) {
    & + button,
    & + a {
      margin: 1rem 0 0 0;
    }
  }
  @media (min-width: ${theme.breakpoints.m}) {
    display: inline-block;
    text-align: left;
    & + button,
    & + a {
      margin: 0 0 0 8px;
    }
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

export default ActionHref;
