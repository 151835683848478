import styled, { css } from 'styled-components';
import theme from 'Theme';
const { colors, fontSize, fontFamily } = theme;

const btn = (bg, text) => css`
  white-space: nowrap;
  display: inline-block;
  border-radius: ${theme.borderRadius.elements};

  padding: 6px 18px;
  font-size: 14px;
  font-weight: bold;
  &:visited {
    color: white;
  }
  background: ${bg};
  color: ${text};
  border: 1px solid ${text};
  &:hover {
    background: ${text};
    &[disabled] {
      background: ${text};
    }
  }
  &:visited {
    color: black;
  }
  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const btnDefault = css`
  ${btn('#ffffff', '#888888')} color: #212121;
  // btn('#fff', '#888')
`;

const btnPrimary = btn(
  '${theme.colors.primary.base}',
  '${theme.colors.white.base}'
);

const StyledForm = styled.form`
  background-color: #fff;
  color: #444;
  margin: 0 auto;
  & > div {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    & > label {
      color: #333;
      font-size: 12px;
      line-height: 16px;
      font-weight: bold;
      padding: 0 4px;
      margin-bottom: 4px;
    }
    & > input,
    & > select {
      flex: 1;
      padding: 6px;
      font-size: ${theme.fontSize.small};
      line-height: initial;
      border: 1px solid #ccc;
      border-radius: ${theme.borderRadius.elements};
      @media (min-width: ${theme.breakpoints.s}) {
      }
      @media (min-width: ${theme.breakpoints.m}) {
        max-width: 100%;
      }
      @media (min-width: ${theme.breakpoints.l}) {
      }
    }
    & > textarea {
      flex: 1;
      padding: 6px;
      font-size: ${theme.fontSize.small};
      line-height: initial;
      border: 1px solid #ccc;
      border-radius: ${theme.borderRadius.elements};
      @media (min-width: ${theme.breakpoints.s}) {
      }
      @media (min-width: ${theme.breakpoints.m}) {
        max-width: 100%;
      }
      @media (min-width: ${theme.breakpoints.l}) {
      }
    }
    & > input[type='checkbox'] {
      margin-top: 7px;
    }
    & > div {
      & > label {
        display: block;
        & > input {
          margin-right: 3px;
        }
      }
    }
    & > span {
      margin-left: 10px;
      // display: none;
      color: #800;
      font-weight: bold;
      font-size: 0.8rem;
    }
  }
  .headingWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
    button {
      line-height: initial;
      height: 36px;
      justify-content: flex-end;

      & + button {
        margin-left: 8px;
      }
      &[type='submit'] {
        ${btnPrimary};
      }
      &[type='button'] {
        ${btnDefault};
      }
    }
  }
  .buttonsWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
    button {
      line-height: initial;
      height: 36px;
      justify-content: flex-end;

      & + button {
        margin-left: 8px;
      }
      &[type='submit'] {
        ${btnPrimary};
      }
      &[type='button'] {
        ${btnDefault};
      }
    }
  }
  .footerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border-top: 1px solid #ccc;
    button {
      line-height: initial;
      height: 36px;
      justify-content: center;
      & + button {
        margin-left: 8px;
      }
      &[type='submit'] {
        ${btnPrimary};
      }
      &[type='button'] {
        ${btnDefault};
      }
    }
  }
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  .firstNameElement {
    grid-column: 1/3;
    // grid-row: 1;
  }
  .lastNameElement {
    grid-column: 1/3;
    // grid-row: 2;
  }
  .emailElement {
    grid-column: 1/3;
    // grid-row: 3;
  }
  .phoneElement {
    grid-column: 1/3;
    // grid-row: 3;
  }
  .titleElement {
    grid-column: 1/3;
    // grid-row: 4;
  }
  .companyElement {
    grid-column: 1/3;
    // grid-row: 4;
  }
  .messageElement {
    grid-column: 1/3;
    // grid-row: 4;
  }
  .headingWrapper {
    grid-column: 1/3;
    // grid-row: 4;
  }
  .buttonsWrapper {
    grid-column: 1/3;
    // grid-row: 4;
  }
  .footerWrapper {
    grid-column: 1/3;
    // grid-row: 4;
  }
  .firsthalfWidthElement {
    grid-column: 1/3;
  }
  .lasthalfWidthElement {
    grid-column: 1/3;
  }
  .fullWidthElement {
    grid-column: 1/3;
  }
  @media (min-width: ${theme.breakpoints.s}) {
    grid-template-columns: 1fr 1fr;
    .firstNameElement {
      grid-column: 1;
    }
    .lastNameElement {
      grid-column: 2;
    }
    .emailElement {
      grid-column: 1;
    }
    .phoneElement {
      grid-column: 2;
    }
    .titleElement {
      grid-column: 1;
    }
    .companyElement {
      grid-column: 2/3;
    }
    .messageElement {
      grid-column: 1/3;
    }
    .firsthalfWidthElement {
      grid-column: 1;
    }
    .lasthalfWidthElement {
      grid-column: 2;
    }
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

export default StyledForm;
