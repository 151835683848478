import React from 'react';

import './styles.scss';

import classnames from 'classnames';

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { className } = this.props;
    return (
      <div className={classnames('list', className)}>{this.props.children}</div>
    );
  }
}

export default List;
