import React from 'react';
import moment from 'moment';

class Countdown extends React.Component {
  state = {
    days: undefined,
    hours: undefined,
    minutes: undefined,
  };

  componentDidMount() {
    this.interval = setInterval(() => {
      const { timeTillDate, timeFormat } = this.props;

      const now = moment(new Date());
      console.log('now: ', now);
      const then = moment(timeTillDate).format('YYYYMMDDTHHmmss');
      console.log('then: ', then);
      var duration = moment.duration(now.diff(then));
      console.log(duration.asDays());

      // console.log('toDate: ', moment(then - now));
      // var a = moment(timeTillDate);
      // var b = moment();
      // console.log('now: ', now);
      // console.log('now.diff(then): ', now.diff(then));
      // console.log('a.diff(b, "days"): ', a.diff(b, 'days'));
      // console.log('a.diff(b, "hours"): ', a.diff(b, 'hours'));

      const countdown = moment(now.diff(then));
      const days = countdown.format('D');
      const hours = countdown.format('HH');
      const minutes = countdown.format('mm');
      console.log('countdown.format(D): ', countdown.format('D'));

      this.setState({ days, hours, minutes });
    }, 1000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { days, hours, minutes } = this.state;

    return (
      <div>
        <div className="countdown-wrapper">
          {days && (
            <div className="countdown-item">
              {days}
              <span>&nbsp;{days > 1 ? 'days' : 'day'}</span>
            </div>
          )}
          {hours && (
            <div className="countdown-item">
              {hours}
              <span>&nbsp;{hours > 1 ? 'hours' : 'hour'}</span>
            </div>
          )}
          {minutes && (
            <div className="countdown-item">
              {minutes}
              <span>&nbsp;{minutes > 1 ? 'minutes' : 'minute'}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Countdown;

const SVGCircle = ({ radius }) => (
  <svg className="countdown-svg">
    <path
      fill="none"
      stroke="#333"
      stroke-width="4"
      d={describeArc(50, 50, 48, 0, radius)}
    />
  </svg>
);

// From StackOverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(x, y, radius, startAngle, endAngle) {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  var d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(' ');

  return d;
}

// From StackOverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
function mapNumber(number, in_min, in_max, out_min, out_max) {
  return (
    ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
  );
}
