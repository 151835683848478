import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { theme, colors, fontSize } from 'Styles';

import {
  FacebookShareCount,
  PinterestShareCount,
  VKShareCount,
  OKShareCount,
  RedditShareCount,
  TumblrShareCount,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  RedditShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  PocketShareButton,
  InstapaperShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
} from 'react-share';

const SocialShare = ({ title, description, banner, pathname, event }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleAlt,
          brand,
          author,
          siteLanguage,
          logo,
          siteUrl,
          pathPrefix,
          defaultDescription,
          defaultBanner,
          twitter,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${banner || defaultBanner}`,
        url: `${siteUrl}${pathname
          .toString()
          .replace(/\s+/g, '-')
          .toLowerCase() || '/'}`,
      };
      return (
        <div className="social-network-share-container">
          {/* <FacebookShareButton
            url={seo.url}
            quote={seo.description}
            className="social-network-share-button"
          >
            <FacebookIcon size={32} borderRadius={4} round />
          </FacebookShareButton> */}

          <TwitterShareButton
            url={seo.url}
            title={seo.title}
            className="social-network-share-button"
          >
            <TwitterIcon size={32} borderRadius={4} round />
          </TwitterShareButton>

          <LinkedinShareButton
            url={seo.url}
            windowWidth={750}
            windowHeight={600}
            className="social-network-share-button"
          >
            <LinkedinIcon size={32} borderRadius={4} round />
          </LinkedinShareButton>

          {/* <WhatsappShareButton
            url={seo.url}
            title={seo.title}
            separator=":: "
            className="social-network-share-button"
          >
            <WhatsappIcon size={32} borderRadius={4} round />
          </WhatsappShareButton>

          <WorkplaceShareButton
            url={seo.url}
            quote={seo.description}
            className="social-network-share-button"
          >
            <WorkplaceIcon size={32} borderRadius={4} round />
          </WorkplaceShareButton> */}

          {/* <EmailShareButton
            url={seo.url}
            subject={seo.title}
            body={seo.title}
            className="social-network-share-button"
          >
            <EmailIcon size={32} borderRadius={4} round />
          </EmailShareButton> */}

          {/* <span className="social-network-share-button">
            <p
              className=""
              style={{
                lineHeight: '1rem',
                margin: '22px 0',
                transform: 'rotate(-90deg)',
              }}
            >
              <b>Share:</b>
            </p>
          </span> */}

          {/* {seo.title}
            {seo.description}
            {seo.image} */}
        </div>
      );
    }}
  />
);
//   }
// }

export default SocialShare;

SocialShare.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  event: PropTypes.bool,
};

SocialShare.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  event: false,
};

const query = graphql`
  query SocialShare {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        defaultTitle: title
        titleAlt
        brand
        author
        siteLanguage
        logo
        siteUrl: url
        pathPrefix
        defaultDescription: description
        defaultBanner: banner
        twitter
      }
    }
  }
`;
