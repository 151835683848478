import styled from 'styled-components';
import { theme, mixins } from 'Styles';

export const TestimonialCard = styled.section`
  display: flex;
  flex-direction: column;
  text-align: left;
  // padding: 1rem 2rem;
  @media (min-width: ${theme.breakpoints.s}) {
    // padding: 1rem;
  }
  @media (min-width: ${theme.breakpoints.m}) {
    flex-direction: column;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  justify-content: space-between;
  margin: 0 auto;
  // padding-bottom: 1rem;
  // ${mixins.hairLineUnder};
`;
export const TestimonialText = styled.div`
  display: block;
  background: #fff;
  // margin: 0 1rem;
  // max-width: 500px;
  // max-width: ${theme.layout.blog};

  padding: 1rem;
  border-radius: ${theme.borderRadius.ui};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    padding: 1rem;
    border-radius: ${theme.borderRadius.ui} 0 0
      ${theme.borderRadius.ui};
    flex-direction: row;
    align-self: center;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  .slider-frame {
    overflow: visible !important;
  }
`;
export const TestimonialCopy = styled.p`
  display: block;
  font-size: ${theme.fontSize.small};
  margin: 0;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    align-self: center;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;
export const TestimonialAuthor = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    align-self: center;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;
export const TestimonialAuthorLogo = styled.div`
  position: relative;
  display: block;
  width: 100%;
  max-width: 160px;
  // border-radius: ${theme.borderRadius.default};
  overflow: hidden;
  font-size: ${theme.fontSize.small};
  font-weight: bold;
  margin: 0 0 8px;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    align-self: start;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;
export const TestimonialAuthorDetails = styled.div`
  display: block;
  font-size: ${theme.fontSize.small};
  font-weight: bold;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    align-self: start;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;
export const TestimonialAuthorName = styled.p`
  display: block;
  font-size: ${theme.fontSize.small};
  font-weight: bold;
  margin: 0 0 4px;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    align-self: start;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;
export const TestimonialAuthorTitle = styled.p`
  display: block;
  font-size: ${theme.fontSize.xsmall};
  font-weight: normal;
  margin: 0;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    align-self: start;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;
