import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FeaturedSection,
  FirstSection,
  FlatSection,
  FooterSection,
  Wrapper,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  Title,
  Subtitle,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
} from 'Styles';

import {
  FormAttend,
  BackgroundImage,
  BackgroundVideo,
  EventCalendarCard,
  EventCalendarList,
  Hero,
  HeroBig,
  HeroVideo,
  Logo,
  Modal,
  PostList,
  SectionBackground,
  SectionCallout,
  FormShareEvent,
  FormSubscribe,
} from 'Components';

import Config from 'Data';
import { Link } from 'gatsby';

const { colors, fontSize } = theme;
const MODAL_SHARE_EVENT = 'modal_share_event';

const Grid = styled.div`
  margin: 1rem auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
  @media (min-width: ${theme.breakpoints.l}) {
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  }
  grid-gap: 16px;
`;

class EventsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentModal: null,
      shareEvent: null,
    };
  }

  componentWillMount() {
    this.setState({
      currentModal: null,
      shareEvent: null,
    });
  }

  toggleModal = (key, event) => e => {
    e.preventDefault();
    if (this.state.currentModal) {
      this.handleModalCloseRequest();
      return;
    }

    this.setState({
      ...this.state,
      currentModal: key,
      shareEvent: event,
    });
  };

  handleOnAfterOpenModal = () => {
    // when ready, we can access the available refs.
  };

  handleModalCloseRequest = () => {
    // opportunity to validate something and keep the modal open even if it
    // requested to be closed
    this.setState({
      ...this.state,
      currentModal: null,
    });
  };

  render() {
    const { currentModal } = this.state;
    const { title, events } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query {
            banners: bannersJson {
              banner {
                attend {
                  childImageSharp {
                    fluid(maxWidth: 2880, quality: 90) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
                sponsor {
                  childImageSharp {
                    fluid(maxWidth: 2880, quality: 90) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
                sharing {
                  childImageSharp {
                    fluid(maxWidth: 2880, quality: 90) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const { banner } = data.banners;
          return (
            <FullWrapper>
              <ActionSection>
                <SectionHeading>
                  <Title>{title}</Title>
                  <SectionHeadingButton>
                    <ActionButton className="" href={'/events'}>
                      View all events
                    </ActionButton>
                  </SectionHeadingButton>
                </SectionHeading>
                {/* <Fade duration={600} fraction={1}> */}
                <Grid>
                  {events.edges.map((data, i) => {
                    return (
                      <EventCard key={i}>
                        <EventCalendarList
                          key={data.node.id}
                          event={data.node.event}
                          share={this.toggleModal(
                            MODAL_SHARE_EVENT,
                            data.node.event
                          )}
                        />
                        <FormShareEvent
                          event={this.state.shareEvent}
                          title={
                            this.state.shareEvent
                              ? this.state.shareEvent.city
                              : null
                          }
                          subtitle=""
                          banner={banner.sharing}
                          isOpen={currentModal == MODAL_SHARE_EVENT}
                          onAfterOpen={this.handleOnAfterOpenModal}
                          onRequestClose={this.handleModalCloseRequest}
                          closeModal={this.toggleModal(MODAL_SHARE_EVENT)}
                        />
                      </EventCard>
                    );
                  })}
                </Grid>
                {/* </Fade> */}
              </ActionSection>
            </FullWrapper>
          );
        }}
      />
    );
  }
}

export default EventsGrid;
