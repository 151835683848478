import React from 'react';

import List from '../List';
import EventListDescription from './EventListDescription';
import EventListInfo from './EventListInfo';
import DateTag from './DateTag';
import Config from 'Data';
import moment from 'moment';

import './styles.scss';

class EventList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      featured,
      countdown,
      photo,
      banner,
      category,
      date,
      eventCountry,
      eventState,
      eventCity,
      eventType,
      eventName,
      rating,
      event,
      eventURL,
      eventStart,
      eventEnd,
      eventStartDate,
      eventEndDate,
      action,
      share,
      className,
      full,
    } = this.props;

    return (
      <List className={`event-list ${className}`}>
        <EventListInfo
          featured={featured}
          countdown={countdown}
          photo={photo}
          banner={banner}
          category={category}
          eventCity={eventCity}
          eventURL={eventURL}
          date={date}
          eventStart={eventStart}
          eventEnd={eventEnd}
          eventStartDate={eventStartDate}
          eventEndDate={eventEndDate}
        />
        {!full && (
          <EventListDescription
            eventCountry={eventCountry}
            eventState={eventState}
            eventCity={eventCity}
            eventType={eventType}
            eventDate={date}
            eventName={eventName}
            description={`100 InfoSec Leaders walk into a collaboration attended by the largest enterprises and brands within ${eventCity}; a day of thought-leading exchange as we examine directions from within the Office of the ${
              Config.audience
            }; all under the umbrella of riveting keynotes, revealing panels, engaging round-tables and unparalleled networking. ${
              Config.siteUrl
            }${eventURL}`}
            rating={rating}
            eventURL={eventURL}
            date={date}
            eventStart={eventStart}
            eventEnd={eventEnd}
            eventStartDate={eventStartDate}
            eventEndDate={eventEndDate}
            share={share}
            event={event}
          />
        )}
      </List>
    );
  }
}

export default EventList;
