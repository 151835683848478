import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Helmet from 'react-helmet';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  Title,
  Subtitle,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
} from 'Styles';

import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from 'react-twitter-embed';

const Grid = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${theme.layout.base};
`;

const TweetsGrid = styled.div`
  margin: 2rem auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
  @media (min-width: ${theme.breakpoints.l}) {
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  }
  grid-gap: 32px;
`;

const TextBlock = styled.div`
  text-align: left;
  width: 100%;
`;

const Wrapper = styled.div`
  color: ${props =>
    props.image
      ? props.dark
        ? theme.colors.white.base
        : theme.colors.black.base
      : theme.colors.black.base};
  min-height: ${props => (props.big ? '300px;' : '100px;')};
  background: none;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  .gatsby-image-wrapper {
    position: initial !important;
  }
  // ${props => (props.dark ? mixins.backgroundBefore : '')};
  ${props => (props.image ? mixins.backgroundBefore : '')};
`;

const Content = styled.div`
  color: inherit;
  width: 100%;
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: ${theme.fontSize.large};
  padding: 0 1rem;
  margin: 0 auto;
  max-width: ${theme.layout.base};
  @media (min-width: ${theme.breakpoints.s}) {
    padding: 0 2rem;
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const SectionTweets = ({
  big,
  dark,
  image,
  title,
  subtitle,
  cta_action,
  cta_action_text,
  cta_url,
  cta_text,
  cta_url_1,
  cta_text_1,
  children,
}) => (
  <Wrapper dark={dark} big={big}>
    {image && (
      <Img
        alt={title}
        title={title}
        objectFit="contain"
        objectPosition="50% 50%"
        fluid={image.childImageSharp.fluid || {} || [] || ''}
      />
    )}
    <Content>
      <Fade left>
        <>
          <Helmet>
            <meta name="twitter:widgets:border-color" content="#ff0000" />
            {/* <meta
              name="twitter:widgets:box-shadow"
              content="0 5px 10px rgba(0, 0, 0, 0.2)"
            /> */}
          </Helmet>
          <Grid>
            <TweetsGrid>
              <TwitterTweetEmbed
                tweetId={'1126472334406291456'}
                options={{
                  cards: 'hidden',
                  hideCard: true,
                  hideThread: false,
                  conversation: 'none',
                  theme: 'light',
                  linkColor: '#ff0000',
                  width: 'auto',
                }}
              />
              <TwitterTweetEmbed
                tweetId={'1106126183580737536'}
                options={{
                  cards: 'hidden',
                  hideCard: true,
                  hideThread: false,
                  conversation: 'none',
                  theme: 'light',
                  linkColor: '#ff0000',
                  width: 'auto',
                }}
              />
              <TwitterTweetEmbed
                tweetId={'1130885387902173184'}
                options={{
                  cards: 'hidden',
                  hideCard: true,
                  hideThread: false,
                  conversation: 'none',
                  theme: 'light',
                  linkColor: '#ff0000',
                  width: 'auto',
                }}
              />
            </TweetsGrid>
          </Grid>
        </>
      </Fade>
    </Content>
  </Wrapper>
);

export default SectionTweets;
