import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { BackgroundImage, BackgroundVideo, Team } from 'Components';
import LinkedInIcon from 'Static/icons/linkedin.svg';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
  BrandedColor,
  Speakers,
  ActionLinkedin,
} from 'Styles';

const Wrapper = styled.section`
  background: #fff;
  border-radius: ${theme.borderRadius.ui};
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1rem;

  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    padding: 4rem 2rem;
    flex-direction: column;
  }
  @media (min-width: ${theme.breakpoints.l}) {
    margin: 0 auto;
  }
  max-width: ${theme.layout.base};
`;

const Content = styled.div`
  display: block;
  margin: 0 auto;
  text-align: left;
  width: 100%;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const Grid = styled.div`
  text-align: left;
  margin: 1rem auto 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
  @media (min-width: ${theme.breakpoints.s}) {
    grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
  }
  @media (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
  }
  @media (min-width: ${theme.breakpoints.l}) {
    // grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
  gap: 1rem 12px;
  p {
    margin: 0;
  }
`;

const Title = styled.h2`
  color: inherit;
  font-weight: bold;
  max-width: ${theme.layout.base};
  margin-left: auto;
  margin-right: auto;
  text-align: left;
`;
const Subtitle = styled.p`
  color: inherit;
  max-width: ${theme.layout.base};
  margin: 0 auto;
  & + a {
    margin-top: 1rem;
  }
  text-align: left;
`;

const SectionExpect = ({
  event,
  big,
  title,
  speakers,
  layout,
  image,
  subtitle,
  name,
  cta_action,
  cta_action_text,
  cta_url,
  cta_text,
  cta_url_1,
  cta_text_1,
  children,
}) => (
  <>
    <Wrapper>
      <Content>
        <Title>
          <BrandedColor>{title}</BrandedColor>
        </Title>
        <Subtitle>{subtitle}</Subtitle>
        <Grid>
          <>
            <div>
              <h5>A day of intimate collaboration</h5>
              <p>
                We’ve gathered 100 leading CISOs and InfoSec strategists from
                the biggest brands and largest enterprises under one roof for
                one day - this is true #CISOcollaboration.
              </p>
            </div>
            <div>
              <h5>Located in the heart of {`${event.city}`}</h5>
              <p>
                {`${event.city}`} is home to some of the countries top Infosec
                leaders so we picked this beautiful city to house our
                #CISOcollaboration.
              </p>
            </div>
            <div>
              <h5>Epicurean Lunch & World Cocktails</h5>
              <p>
                Today's conversations will be over a Michelin lunch and
                exquisite cocktails.
              </p>
            </div>
            <div>
              <h5>By invitation-only</h5>
              <p>
                By hand picking our invitees we were able to engineer an
                audience of top Infosec leaders from a diverse range of
                industries - we've created a true melting pot of insight.
              </p>
            </div>
          </>
        </Grid>
      </Content>
    </Wrapper>
  </>
);

export default SectionExpect;
