import styled from 'styled-components';
import { theme, mixins } from 'Styles';
const FullWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem 0;
  padding: 0 1rem;
  max-width: ${theme.layout.base};
  @media (min-width: ${theme.breakpoints.s}) {
    padding: 0 2rem;
  }

  @media (min-width: ${theme.breakpoints.m}) {
  }

  @media (min-width: ${theme.breakpoints.l}) {
    margin: 2rem auto;
    width: 100%;
  }
`;

export default FullWrapper;
