import styled from 'styled-components';
import theme from 'Theme';
const { colors, fontSize } = theme;
import { Link } from 'gatsby';

const SectionHeadingButton = styled.div`
  margin: 0;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  align-self: flex-start;
  display: inline-block;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    margin: 0;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

export default SectionHeadingButton;
