import { css } from 'styled-components';
import { theme } from 'Styles';

const Shybar = css`
  .headroom-wrapper {
    position: fixed;
    width: 100%;
    z-index: 2000;
    top: 0;
    margin: 0 auto;
  }
  .headroom {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    svg {
      height: 2.5rem;
      g {
        fill: ${theme.colors.white.base};
      }
    }
    header {
      box-shadow: none;
    }
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
    transition: ${theme.transitions.headroom.transition};
    background-color: transparent;
    box-shadow: none;
    header > nav .nav-logo {
        color: ${theme.colors.white.base};
        &:hover, &:focus {
          color: ${theme.colors.white.base};
        }
    }
    header > nav .nav-links {
      a {
        color: ${theme.colors.white.base};
        &:hover {
          color: ${theme.colors.primary.base};
        }
        &:focus {
          color: ${theme.colors.white.base};
        }
      }
    }
  }
  .headroom--scrolled {
  }
  .headroom--unpinned {
    position: fixed;
    background-color: ${theme.colors.white.base};
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
    header > nav {
      // a {
      //   color: ${theme.colors.black.base};
      //   &:hover {
      //     border-color: ${theme.colors.black.base};
      //     color: ${theme.colors.primary.base};
      //   }
      //   &:focus {
      //     color: ${theme.colors.black.base};
      //   }
      // }
    }
  }
  .headroom--pinned, .headroom--unpinned {
    position: fixed;
    transform: translateY(0);
    transition: ${theme.transitions.headroom.transition};
    background-color: ${theme.colors.white.base};
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
    header > nav {
      // a {
      //   color: ${theme.colors.black.base};
      //   &:hover,
      //   &:focus {
      //     border-color: ${theme.colors.black.base};
      //     color: ${theme.colors.primary.base};
      //   }
      // }
    }
    // padding-top: 1rem;
    // padding-bottom: 1rem;
    min-height: ${theme.headerScrollHeight};
    svg {
      height: 2.5rem;
      g {
        fill: ${theme.colors.black.base};
      }
    }
    span {
      color: ${theme.colors.black.base};
    }
  }
`;

export default Shybar;
